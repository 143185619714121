export class MarkdownUtility {

    public static parseLinkMarkdown(markdown: string) {
        //eslint-disable-next-line
        markdown = markdown.replace(/[\[]{1}([^\]]+)[\]]{1}[\(]{1}([^\)\"]+)(\"(.+)\")?[\)]{1}/g, '<a href="$2" target="_blank" title="$4">$1</a>');
        let hasParsingableLink = markdown.toLowerCase().indexOf('<a href="');
        let hasHttp = markdown.toLowerCase().indexOf('http');
        return hasParsingableLink == -1 ? markdown : hasHttp  != -1 ?
            markdown : markdown.substring(0, hasParsingableLink + 9) + 'https://' + markdown.substring(hasParsingableLink + 9);
    }
}