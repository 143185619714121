import React from "react";
import { PanelBar, PanelBarItem } from '@progress/kendo-react-layout';
import { MultipleEventChoiceItem } from "./MultipleEventChoiceItem";
import { FieldGroupHeader } from "../Common/FieldGroupHeader";
import { excludeFromMultipleEvent } from "../../constants/Constants";
import { FieldGroup } from "../../models/fields/field-group";

export interface MultipleEventChoiceProps {
    groups: FieldGroup[];
    checkedFields: { [id: string]: boolean };
    onCheck: (id: string) => void;
}

export interface MultipleEventChoiceState {
    expanded: boolean;
}

export class MultipleEventChoice extends React.Component<MultipleEventChoiceProps, MultipleEventChoiceState> {

    constructor(props: MultipleEventChoiceProps) {
        super(props);
        this.state = { expanded: true };
        this.onToggle = this.onToggle.bind(this);
        this.onCheck = this.onCheck.bind(this);
    }

    onToggle() {
        this.setState(prev => { return { expanded: !prev.expanded } });
    }

    onCheck(id: string) {
        const { onCheck } = this.props;
        onCheck(id);
    }

    render() {
        const { groups, checkedFields } = this.props;
        const { expanded } = this.state;
        const filteredGroups = groups.filter(g => !excludeFromMultipleEvent.some(n => n === g.nameId));
        if (filteredGroups.length === 0) {
            return <React.Fragment></React.Fragment>;
        }
        return (
            <React.Fragment>
                {filteredGroups.map(g => {
                    return (
                        <React.Fragment key={g.id}>
                            <FieldGroupHeader>{g.name}</FieldGroupHeader>
                            {expanded && <PanelBar>
                                <PanelBarItem title={'Choose your events'}>
                                    {g.fields.map(f => {
                                        return <MultipleEventChoiceItem key={f.id} id={f.id} name={f.name} checked={!!checkedFields[f.id]} disabled={f.required } onCheck={this.onCheck} />
                                    })}
                                </PanelBarItem>
                            </PanelBar>}
                        </React.Fragment>
                    )
                })}
            </React.Fragment>
        )
    }
}
