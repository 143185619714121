import React, { PureComponent } from 'react';
import { InstitutionItemProps } from './InstitutionItem';
import { OtherInstitutionInput } from './OtherInstitutionInput';
import { InstitutionSearch } from './InstitutionSearch';


export class InstitutionInputWrap extends PureComponent<InstitutionItemProps> {

    constructor(props: InstitutionItemProps) {
        super(props)
    }

    render() {
        const { useCumtomInstitutionInput } = this.props.value;
        return (
            <React.Fragment>
                {
                    useCumtomInstitutionInput ? <OtherInstitutionInput {...this.props} /> : <InstitutionSearch {...this.props} />
                }
            </React.Fragment>
        )
    }

}