import React from "react";
import MaskedInput from "react-text-mask";
import { MetricUtility } from "../../../utilities/MetricUtility";
import { FieldProps } from "../../../models/fields/field-props";
import { FieldHeader } from "../../Common/FieldHeader";

export class RecruitWeightField extends React.PureComponent<FieldProps<string>> {

    constructor(props: FieldProps<string>) {
        super(props);
        this.onBlur = this.onBlur.bind(this);
        this.onChange = this.onChange.bind(this);
    }

    onBlur(event: React.FocusEvent<HTMLInputElement>) {
        const { onBlur, id, value } = this.props;
        onBlur(id, MetricUtility.weightToNumber(value));
    }

    onChange(event: React.ChangeEvent<HTMLInputElement>) {
        const { onChange, id } = this.props;
        onChange(id, MetricUtility.weightToNumber(event.target.value));
    }

    render() {
        const { name, value, valid, required, description } = this.props;
        const className = 'w-100 k-textbox' + (valid ? '' : ' k-state-invalid');
        return <React.Fragment>
            <FieldHeader required={required} name={name} description={description}/>
            <MaskedInput
                mask={MetricUtility.maskWeight}
                className={className}
                guide={true}
                onBlur={this.onBlur}
                onChange={this.onChange}
                value={value}
            />
        </React.Fragment>;
    }
}