import { connect } from 'react-redux';
import { StoreState } from "../../../store/configureStore";
import React from "react";
import { DropdownField } from "../DropdownField";
import { FieldProps } from "../../../models/fields/field-props";


const states = [
    { id: "AL", title: "Alabama" },
    { id: "AK", title: "Alaska" },
    { id: "AZ", title: "Arizona" },
    { id: "AR", title: "Arkansas" },
    { id: "CA", title: "California" },
    { id: "CO", title: "Colorado" },
    { id: "CT", title: "Connecticut" },
    { id: "DE", title: "Delaware" },
    { id: "DC", title: "District of Columbia" },
    { id: "FL", title: "Florida" },
    { id: "GA", title: "Georgia" },
    { id: "HI", title: "Hawaii" },
    { id: "ID", title: "Idaho" },
    { id: "IL", title: "Illinois" },
    { id: "IN", title: "Indiana" },
    { id: "IA", title: "Iowa" },
    { id: "KS", title: "Kansas" },
    { id: "KY", title: "Kentucky" },
    { id: "LA", title: "Louisiana" },
    { id: "ME", title: "Maine" },
    { id: "MD", title: "Maryland" },
    { id: "MA", title: "Massachusetts" },
    { id: "MI", title: "Michigan" },
    { id: "MN", title: "Minnesota" },
    { id: "MS", title: "Mississippi" },
    { id: "MO", title: "Missouri" },
    { id: "MT", title: "Montana" },
    { id: "NE", title: "Nebraska" },
    { id: "NV", title: "Nevada" },
    { id: "NH", title: "New Hampshire" },
    { id: "NJ", title: "New Jersey" },
    { id: "NM", title: "New Mexico" },
    { id: "NY", title: "New York" },
    { id: "NC", title: "North Carolina" },
    { id: "ND", title: "North Dakota" },
    { id: "OH", title: "Ohio" },
    { id: "OK", title: "Oklahoma" },
    { id: "OR", title: "Oregon" },
    { id: "PA", title: "Pennsylvania" },
    { id: "PR", title: "Puerto Rico" },
    { id: "RI", title: "Rhode Island" },
    { id: "SC", title: "South Carolina" },
    { id: "SD", title: "South Dakota" },
    { id: "TN", title: "Tennessee" },
    { id: "TX", title: "Texas" },
    { id: "UT", title: "Utah" },
    { id: "VT", title: "Vermont" },
    { id: "VA", title: "Virginia" },
    { id: "WA", title: "Washington" },
    { id: "WV", title: "West Virginia" },
    { id: "WI", title: "Wisconsin" },
    { id: "WY", title: "Wyoming" },
];

const provinces = [
    { id: "AB", title: "Alberta" },
    { id: "BC", title: "British Columbia" },
    { id: "MB", title: "Manitoba" },
    { id: "NB", title: "New Brunswick" },
    { id: "NL", title: "Newfoundland and Labrador" },
    { id: "NT", title: "Northwest Territories" },
    { id: "NS", title: "Nova Scotia" },
    { id: "NU", title: "Nunavut" },
    { id: "ON", title: "Ontario" },
    { id: "PE", title: "Prince Edward Island" },
    { id: "QC", title: "Quebec" },
    { id: "SK", title: "Saskatchewan" },
    { id: "YT", title: "Yukon" }];

interface StateFieldProps {
    recruitCountry: string;
    recruitSchoolCountry: string;
    fieldProps: FieldProps<string>;
}

const mapStateToProps = (state: StoreState, ownProps: any) => {
    return {
        fieldProps: {...ownProps },
        recruitCountry: state.bioFields.recruitCountry,
        recruitSchoolCountry: state.bioFields.recruitSchoolCountry,
    }
}

 class RecruitStateFieldComponent extends React.PureComponent<StateFieldProps> {

     getStates(country: string) {
         return country === "Canada" ? provinces : states;
     }

     render() {
         let country = 'US';
        const { recruitCountry, recruitSchoolCountry } = this.props;
        if (this.props.fieldProps.nameId &&
        (this.props.fieldProps.nameId === 'state' && recruitCountry === "Canada")) {
            country = "Canada";
        }
        return <DropdownField {...this.props.fieldProps} options={this.getStates(country)} />;
    }
}

const RecruitStateField = connect(mapStateToProps)(RecruitStateFieldComponent);

export { RecruitStateField }