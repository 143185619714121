import * as React from "react";
import { QuestionnaireItem } from "../models/api-models/questionnaire/questionnaire-item";
import { QuestionnaireItemType } from "../models/api-models/questionnaire/questionnaire-item-type";
import FileUploadContainer from "../containers/FileUpload";
import FootgeUploadContainer from "../containers/FootageUpload";
import { Tab } from "../models/tab/tab";
import SocialListContainer from "../containers/SocialList/index";
import ContactContainer from  "../containers/Contacts"
import { FieldGroupHeader } from "./Common/FieldGroupHeader";
import QuestionnaireBioContainer from "../containers/QuestionnaireBio";
import AvatarUploadContainer from "../containers/AvatarUpload";
import InstitutionListContainer from "../containers/InstitutionList";
import QuestionnaireSportStatsContainer from "../containers/QuestionnaireSportStats";
import { FieldHeader } from "./Common/FieldHeader";
export interface QuestionnaireTabProps {
    tab: Tab;
}

export class QuestionnaireTab extends React.PureComponent<QuestionnaireTabProps> {


    renderItem = (item: QuestionnaireItem) => {
        switch (item.type) {
            case QuestionnaireItemType.BioField:
                return <QuestionnaireBioContainer  fieldId={item.fieldInfo.id}/>;
            case QuestionnaireItemType.Avatar:
                return(
                 <div className="avatar-container">
                      <AvatarUploadContainer />
                 </div>);
            case QuestionnaireItemType.Institution:
                return  <InstitutionListContainer categoryId={item.fieldInfo.id} />;
            case QuestionnaireItemType.StatsField:
                return <QuestionnaireSportStatsContainer  groupId={item.sportStatsInfo.groupId}/>;
            case QuestionnaireItemType.SocialProfile:
                return <SocialListContainer {...item} />;
            case QuestionnaireItemType.Contact:
                return <ContactContainer {...item} /> ;
            case QuestionnaireItemType.File:
                return  <FileUploadContainer id={item.id} />;
            case QuestionnaireItemType.Video:
                return <FootgeUploadContainer id={item.id} />;
            case QuestionnaireItemType.SectionHeader:
                return <React.Fragment>
                    <FieldGroupHeader>{item.label}</FieldGroupHeader>
                    {item.description && <div dangerouslySetInnerHTML={{ __html: item.description}} /> }
                    </React.Fragment>;
            case QuestionnaireItemType.TextArea:
                return <React.Fragment>
                    <FieldHeader  name={item.label}/>
                    <div dangerouslySetInnerHTML={{ __html: item.description}} />
                </React.Fragment>;
            default:
                return <div>Unexpected Component</div>;
        }
    }

    render() {
        const { tab } = this.props;
        return (
            <React.Fragment>
                { tab.items.filter(x => x.type !== QuestionnaireItemType.Tab).map(x => this.renderItem(x)) }
            </React.Fragment>);
    }
}