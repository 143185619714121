import { connect } from 'react-redux';
import { SocialList } from '../../components/Info/SocialList';
import { StoreState } from "../../store/configureStore";


const mapStateToProps = (state: StoreState, ownProps: any) => {
    return {
        ...ownProps,
        socials: state.socials.socials,
        groupName: ownProps.label,
        groupDescription: ownProps.description,
    }
}

const SocialListContainer = connect(mapStateToProps)(SocialList);

export default SocialListContainer;