import { QuestionnaireStatus } from "../constants/Constants";
import { QuestionnaireInitialInfo } from "../models/initializeQuestionnaire/questionnaire-initial-info";

export const getQuestionnaireInitialInfoByPath = (path: string) : QuestionnaireInitialInfo => {
    const symbolsToRemove = /#!/gm;
    const url = path.replace(symbolsToRemove, '');
    const urlParts = url.split('/');

    let status = QuestionnaireStatus.Form;
    let recruitId = null;

    let idAndParameters = urlParts.pop();
    if(!idAndParameters) {
         idAndParameters = urlParts.pop();
    }
    const tokens = idAndParameters!.split('?');
    if(tokens.length === 2 && tokens[1].startsWith('id=', 0)) {
        const terms = tokens[1].split('=');
        recruitId  = terms[1].split('&')[0]; 
    }

    const previewOrMnemonic = urlParts.pop();

    if (previewOrMnemonic!.startsWith(QuestionnaireStatus.Preview, 0)) {
        status = QuestionnaireStatus.Preview;
    }

    return {
      formId: tokens[0],
      status,
      teamMnemonic: status === QuestionnaireStatus.Preview ? '' : previewOrMnemonic,
      recruitId,
      url, 
    } as QuestionnaireInitialInfo
}