import { SocialAction } from "./actions";
import { ON_SOCIAL_CHANGE, ON_SOCIAL_BLUR, SET_SOCIAL_VALIDATORS, SET_SOCIAL_VALUES, SET_SOCIAL_SETTINGS, SET_SOCIAL_ERRORS } from "./constants";
import { Validator } from "../../utilities/ValidatorUtility";
import { Social } from "../../models/socials/social";
import { validate } from "../selectors";

export interface SocialState {
    socialValues: { [id: string]: any };
    socialErrors: { [id: string]: boolean };
    socialValidators: { [id: string]: Validator[] };
    socials: Social[]
}

const initialState: SocialState = {
    socialValues: {},
    socialErrors: {},
    socialValidators: {},
    socials: []
}


export const socialReducer = (state: SocialState = initialState, action: SocialAction) => {
    switch (action.type) {
        case ON_SOCIAL_CHANGE: {
            return {
                ...state,
                socialValues: {
                    ...state.socialValues,
                    [action.payload.id]: action.payload.value
                }
            }
        }

        case ON_SOCIAL_BLUR: {
            return {
                ...state,
                socialValues: {
                    ...state.socialValues,
                    [action.payload.id]: action.payload.value
                },
                socialErrors: {
                    ...state.socialErrors,
                    [action.payload.id]: validate(state.socialValidators[action.payload.id], action.payload.value)
                }
            }
        }

        case SET_SOCIAL_VALIDATORS: {
            return {
                ...state,
                socialValidators: action.payload.validators
            }
        }

        case SET_SOCIAL_VALUES: {
            return {
                ...state,
                socialValues: action.payload.values
            }
        }

        case SET_SOCIAL_SETTINGS: {
            return {
                ...state,
                socials: action.payload.socials
            }
        }

        case SET_SOCIAL_ERRORS: {
            return {
                ...state,
                socialErrors: action.payload.errors
            }
        }

        default: {
            return state;
        }
    }
}