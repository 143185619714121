import React from "react";
import { Upload, UploadFileRestrictions, UploadOnStatusChangeEvent } from '@progress/kendo-react-upload';
import { QuestionnaireService } from "../../services/QuestionnaireService";
import { Attachment } from "./Attachment";
import { UploadInfo } from "../../models/api-models/media/upload-info";
import { FileAttachment } from "../../models/files/file-attachment";

export interface FileUploadProps {
    id: string;
    title: string;
    description: string;
    value: FileAttachment[];
    required: boolean;
    valid: boolean;
    onAddFile: (id: string, file: FileAttachment) => void;
    onRemoveFile: (id: string, index: number) => void;
}

export class FileUpload extends React.PureComponent<FileUploadProps> {

    constructor(props: FileUploadProps) {
        super(props);
        this.onStatusChange = this.onStatusChange.bind(this);
        this.onRemove = this.onRemove.bind(this);
        
    }

    uploadUrl = QuestionnaireService.getUploadUrl();
    removeUrl = QuestionnaireService.getRemoveUrl();
    filesRestrictions: UploadFileRestrictions = {
        allowedExtensions: ['pdf', 'txt', 'jpg', 'jpeg', 'png', 'doc', 'docx']
    };

    onStatusChange(e: UploadOnStatusChangeEvent): void {
        const { value, title, onAddFile, id } = this.props;
        let newTitle = '';
        const order = value && value.length > 0
            ? value[value.length - 1].order + 1
            : 0;
        if (order > 0) {
            newTitle = title + " (" + order + ")";
        } else {
            newTitle = title;
        }
        const uploadInfo = e.response.response as UploadInfo;
        let file = {
            uid: uploadInfo.id,
            name: newTitle,
            size: uploadInfo.size,
            title: newTitle,
            mediaId: uploadInfo.id,
            url: uploadInfo.link,
            order: order,
        };
        onAddFile(id, file);
    }

    onRemove(uid: string) {
        const { onRemoveFile, id, value } = this.props;
        const index = value.findIndex(x => x.uid === uid);
        if (index !== -1) {
            onRemoveFile(id, index);
        }
    }

    render() {
        const { value, title, required, valid, description } = this.props;
        const className = 'field-header' + (required ? ' require' : '');
        const validClassName = valid ? '' : 'k-state-invalid';
        return <div className="form-group">
            <label className={className}>{title}</label><br/>
            { description && description.length && <label className="field-description">{description}</label> }
            <Upload saveUrl={this.uploadUrl}
                removeUrl={this.removeUrl}
                showFileList={false}
                restrictions={this.filesRestrictions}
                onStatusChange={this.onStatusChange}
                className={validClassName}                
            />
            {value && value.length !== 0 && <div className="upload-container p-2">
                {value.map(x => {
                    return <Attachment key={x.uid} attachment={x} onRemove={this.onRemove} />;
                })}
            </div>}
        </div>
    }
}