import React from "react";
import { UnsubscribeService } from "../../services/UnsubscribeService";
import '../../styles/components/Unsubscribe.css'

interface UnsubscribeInfo {
    contactId: string ,
    messageCode: string,
    isUnsubscribed: boolean,
    email: string,
    teamName: string,
    isSubscribedNow: boolean
}

export class Unsubscribe extends React.PureComponent<{}, UnsubscribeInfo> {

    constructor() {
        document.title = "Unsubscribe - ARI"
        super(undefined);
        const [,urlParts] = window.location.href.split('?');
        const urlParams = new URLSearchParams(urlParts);
        let teamName = urlParams.get("team");
        let messageCode = urlParams.get("msg");
        let contactId = urlParams.get("id");
        let email = '';
        let isUnsubscribed = false;
        let isSubscribedNow = false;
        if (contactId !== 'unsubscribe_page_test') {
            UnsubscribeService.getContactInfoById(contactId).then(x => {
                email = x.email;
                isUnsubscribed = x.unsubscribed
                this.state = { 
                    contactId: contactId ,
                    messageCode: messageCode,
                    isUnsubscribed: isUnsubscribed,
                    email: email,
                    teamName: teamName,
                    isSubscribedNow: false
                };
                this.setState(this.state);
            });
        }

        this.state = { 
            contactId: contactId ,
            messageCode: messageCode,
            isUnsubscribed: isUnsubscribed,
            email: email,
            teamName: teamName,
            isSubscribedNow: isSubscribedNow
        };

        
    }

    unsubscribe() {
        if (this.state.contactId !== 'unsubscribe_page_test') {
            UnsubscribeService.unsubscribeByIdAndCode(this.state.contactId, this.state.messageCode).then(x => {
                let teamName = this.state.teamName;
                let messageCode = this.state.messageCode;
                let contactId = this.state.contactId;
                let email = this.state.email;
                let isUnsubscribed = true;
                let isSubscribedNow = false;
                this.setState({
                    contactId: contactId,
                    email: email,
                    messageCode: messageCode,
                    teamName: teamName,
                    isUnsubscribed: isUnsubscribed,
                    isSubscribedNow: isSubscribedNow
                });
            });
        }
    }

    subscribe() {
        if (this.state.contactId !== 'unsubscribe_page_test') {
            UnsubscribeService.subscribeByIdAndCode(this.state.contactId, this.state.messageCode).then(x => {
                let teamName = this.state.teamName;
                let messageCode = this.state.messageCode;
                let contactId = this.state.contactId;
                let email = this.state.email;
                let isUnsubscribed = false;
                let isSubscribedNow = true;
                this.setState({
                    contactId: contactId,
                    email: email,
                    messageCode: messageCode,
                    teamName: teamName,
                    isUnsubscribed: isUnsubscribed,
                    isSubscribedNow: isSubscribedNow
                });
            });
        }
    }
    
    render() {
        return <React.Fragment > 
            <div className="unsubscribe-content">
                <div className="unsubscribe-header" >{this.state.teamName}</div>
                <div className="unsubscribe-body" >
                    <div className="body-content" >
                        { this.state.isUnsubscribed ? 
                            <div className="body-header" >Unsubscribe Successful</div> : 
                            this.state.isSubscribedNow ? 
                                <div className="body-header green-body-header" >Unsubscribe Reversed</div> : 
                                <div className="body-header" >Unsubscribe</div>}
                        {
                            this.state.isSubscribedNow ? 
                            <div className="body-email" >
                                Your email is <span className="bold" >{this.state.email}</span>
                            </div>
                            : <div className="body-email" >
                                Your email address
                                <br />
                                <div className="bold" >{this.state.email}</div>
                            </div>
                        }
                        { this.state.isUnsubscribed ? 
                            <div className="body-info" >
                                <div className="comb-line" >You will no longer receive emails from<div className="bold" >{this.state.teamName}</div>.</div>
                                <img className="unsubscribed-icon" alt="ARI Recruiting" src={require('../../images/done-icon.png')} />
                            </div> 
                            : this.state.isSubscribedNow ? 
                                <div className="body-info" >
                                    <div className="comb-line" >You will continue receiving emails from<div className="bold" >{this.state.teamName}</div>.</div>
                                    <img className="unsubscribed-icon" alt="ARI Recruiting" src={require('../../images/well-done-icon.png')} />
                                </div> 
                            : 
                            <div>
                                <div className="body-info" >
                                    Please confirm you would like to unsubscribe from emails
                                    <div className="comb-line" >from<div className="bold" >{this.state.teamName}</div></div>
                                    by selecting the button below.
                                </div>
                                <div className="body-bottom" >
                                    <button className="k-button main-button" onClick={this.unsubscribe.bind(this)} >Unsubscribe</button>
                                </div>
                            </div>
                        }
                        {this.state.isSubscribedNow ? null:
                            <div className="body-keep-receiving-link" >
                                <a href="#!" onClick={this.subscribe.bind(this)} >Wait, I want to keep receiving emails!</a>
                            </div>
                        }
                    </div>
                </div>
            </div>
        </React.Fragment>
    }
}