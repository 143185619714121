import axios, { AxiosRequestConfig } from 'axios';

export class Api {

    static async get<T>(url: string, options = {}): Promise<T> {
        return await Api.call<T>(`${url}`, "GET", options);
    }

    static async post<T>(url: string, data: any, options = {}): Promise<T> {
        return await Api.call<T>(`${url}`, "POST", options, data);
    }

    static async put<T>(url: string, data: any, options = {}): Promise<T> {
        return await Api.call<T>(`${url}`, "PUT", options, data);
    }

    static async delete<T>(url: string, options = {}): Promise<T> {
        return await Api.call<T>(`${url}`, "DELETE", options);
    }

    //TODO: add error handling
    private static async call<T>(url: string, method = "GET", options = {}, data = null): Promise<T> {
        let callConfig = Object.assign({
            url: url,
            method: method,
            data: data,
        }, options);
        try {
            let response = await axios.request<T>(callConfig as AxiosRequestConfig);
            if (response.status >= 200 && response.status < 300) {
                return Promise.resolve(response.data);
            } else {
                throw new Error(JSON.stringify(response.data));
            }
        } catch (e) {
            console.error(e);
            return Promise.reject(e);
        }
    }
}