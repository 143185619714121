import React from "react";
import { Field } from "../../models/fields/field"
import BioFieldContainer from "../../containers/BioField";

export interface FieldListProps {
    fields: Field[];
}

export class BioFieldList extends React.PureComponent<FieldListProps> {

    render() {
        return <React.Fragment>
            {this.props.fields.filter(x => x).map(x => <BioFieldContainer key={x.id} {...x} />)}
        </React.Fragment>;
    }
}