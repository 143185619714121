import React from "react";
import { Field } from "../../models/fields/field";
import SportStatsFieldContainer from "../../containers/SportStatsField";

export interface FieldListProps {
    fields: Field[];
}

export class SportStatsFieldList extends React.PureComponent<FieldListProps> {

    render() {
        return <React.Fragment>
            {this.props.fields.map(x => <SportStatsFieldContainer key={x.id} {...x} />)}
        </React.Fragment>;
    }
}