import { UnsubscribeApi } from "../api/UnsubscribeApi";


export class UnsubscribeService {

    static async unsubscribeByIdAndCode(contactId: string, messageCode: string): Promise<void> {
        return await UnsubscribeApi.unsubscribe(contactId, messageCode);
    }

    static async subscribeByIdAndCode(contactId: string, messageCode: string): Promise<void> {
        return await UnsubscribeApi.subscribe(contactId, messageCode);
    }

    static async getContactInfoById(contactId: string): Promise<any> {
        return await UnsubscribeApi.getContactInfoById(contactId);
    }

}