import { HttpUtility } from "../utilities/HttpUtility";
import { ApiConfigs } from "../configs/ApiConfigs";
import { Api } from "./Api";
import { QuestionnaireView } from "../models/api-models/questionnaire/questionnaire-view";
import { TeamInfo } from "../models/api-models/team/team-info";
import { QuestionnaireRecruitCreate } from "../models/api-models/questionnaire/questionnaire-recruit-create";
import { InstitutionSearchItem } from '../models/institution/institution-search-item';
import { GenderListItem } from "../models/api-models/questionnaire/gender-list-item";
import { PositionListItem } from "../models/api-models/questionnaire/position-list-item";
import { FieldGroupViewModel } from "../models/api-models/fields/field-group-view-model";
import { InstitutionCategory } from "../models/institution/institution-category";
import { QuestionnaireRecruitAvatarUpdate } from "../models/api-models/questionnaire/partial-update/questionnaire-recruit-avatar-update";
import { QuestionnaireRecruitContactFieldUpdate } from "../models/api-models/questionnaire/partial-update/questionnaire-recruit-contact-update";
import { QuestionnaireRecruitFieldUpdate } from "../models/api-models/questionnaire/partial-update/questionnaire-recruit-field-upddate";
import { QuestionnaireRecruitFilesUpdate } from "../models/api-models/questionnaire/partial-update/questionnaire-recruit-files-update";
import { QuestionnaireRecruitFootagesUpdate } from "../models/api-models/questionnaire/partial-update/questionnaire-recruit-footages-update";
import { QuestionnaireRecruitInstitutionsUpdate } from "../models/api-models/questionnaire/partial-update/quedtionnaire-recruit-institutions-update";
import { QuestionnaireRecruitPositionsUpdate } from "../models/api-models/questionnaire/partial-update/questionnaire-recruit-positions-update";
import { QuestionnaireRecruitSmsStatusUpdate } from "../models/api-models/questionnaire/partial-update/questionnaire-recruit-sms-status-update";
import { QuestionnaireRecruitSocialsUpdate } from "../models/api-models/questionnaire/partial-update/questionnaire-recruit-socials-update";
import { QuestionnaireRecruitSubmit } from "../models/api-models/questionnaire/partial-update/questionnaire-recruit-submit";
import { QuestionnaireRecruitInfo } from "../models/api-models/questionaire-recruit/questionnaire-recruit-info";

export class QuestionnaireApi {

    private static get apiUrl(): string {
        return HttpUtility.combine(ApiConfigs.recruitingApiUrl, 'v1/questionnaires');
    }

    private static get teamUrl(): string {
        return HttpUtility.combine(ApiConfigs.organizationApiUrl, 'v1/teams');
    }

    static async getQuestionnaireByTeamMnemonic(teamMnemonic: string, formUrl: string): Promise<QuestionnaireView> {
        let url = HttpUtility.combine(this.apiUrl, `by-team-mnemonic/${encodeURIComponent(teamMnemonic)}`, '');
        url = HttpUtility.addParameter(url, 'formUrl', formUrl);
        return await Api.get<QuestionnaireView>(url);       
    }

    static async getQuestionnairePreviewById(id: string): Promise<QuestionnaireView> {
        return await Api.get<QuestionnaireView>(HttpUtility.combine(this.apiUrl, `preview/${id}`, ''));
    }

    static async getQuestionnaireIsAvailable(teamMnemonic: string, formUrl: string): Promise<boolean> {
        let url = HttpUtility.combine(this.apiUrl, `is-available/${encodeURIComponent(teamMnemonic)}`, '');
        url = HttpUtility.addParameter(url, 'formUrl', formUrl);
        return await Api.get<boolean>(url);
    }

    static async initializeQuestionnaireRecruit(questionnaireId: string, questionnaireRecruitId: string | null): Promise<string> {
        let url = HttpUtility.combine(this.apiUrl, questionnaireId, 'initialize');
        if(questionnaireRecruitId) {
            url = HttpUtility.addParameter(url, 'questionnaireRecruitId', questionnaireRecruitId)
        }
        return await Api.get<string>(url);
    }

    static async getGenders(teamId: string): Promise<GenderListItem[]> {
        let url = HttpUtility.combine(this.apiUrl, `genders`);
        url = HttpUtility.addParameter(url, 'teamId', teamId);
        return await Api.get<GenderListItem[]>(url);
    }

    static async getPositions(teamId: string): Promise<PositionListItem[]> {
        let url = HttpUtility.combine(this.apiUrl, `positions`);
        url = HttpUtility.addParameter(url, 'teamId', teamId);
        return await Api.get<PositionListItem[]>(url);
    }

    static async getBioFields(teamId: string): Promise<FieldGroupViewModel[]> {
        let url = HttpUtility.combine(this.apiUrl, `fields`, 'bio');
        url = HttpUtility.addParameter(url, 'teamId', teamId);
        return await Api.get<FieldGroupViewModel[]>(url);
    }

    static async getStatsFields(teamId: string): Promise<FieldGroupViewModel[]> {
        let url = HttpUtility.combine(this.apiUrl, `fields`, 'stats');
        url = HttpUtility.addParameter(url, 'teamId', teamId);
        return await Api.get<FieldGroupViewModel[]>(url);
    }

    static async getSocialFields(teamId: string): Promise<FieldGroupViewModel[]> {
        let url = HttpUtility.combine(this.apiUrl, `fields`, 'social');
        url = HttpUtility.addParameter(url, 'teamId', teamId);
        return await Api.get<FieldGroupViewModel[]>(url);
    }

    static async getOrgCategories(teamId: string): Promise<InstitutionCategory[]> {
        let url = HttpUtility.combine(this.apiUrl, `org-categories`);
        url = HttpUtility.addParameter(url, 'teamId', teamId);
        return await Api.get<InstitutionCategory[]>(url);
    }

    static async getUpdateOnlyQuestionnaireRecruitIsAvailable(teamMnemonic: string, recruitId: string, formUrl: string): Promise<boolean> {
        let url = HttpUtility.combine(this.apiUrl, 'is-available', teamMnemonic, 'update-only', recruitId);
        url = HttpUtility.addParameter(url, 'formUrl', formUrl);
        return await Api.get<boolean>(url);
    }

    static async getUpdateOnlyQuestionnaireRecruit(questionnaireId: string, recruitId: string): Promise<QuestionnaireRecruitInfo> {
        let url = HttpUtility.combine(this.apiUrl, questionnaireId, 'update-only', recruitId);
        return await Api.get<QuestionnaireRecruitInfo>(url);
    }

    static async searchInstitutions(term: string, categoryId: string, teamId: string, page: number, pageSize: number) {
        let url = HttpUtility.combine(this.apiUrl, teamId, 'institutions');
        url = HttpUtility.addParameter(url, 'term', term);
        url = HttpUtility.addParameter(url, 'categoryId', categoryId);
        url = HttpUtility.addParameter(url, 'page', page.toString());
        url = HttpUtility.addParameter(url, 'pageSize', pageSize.toString());
        return Api.get<InstitutionSearchItem[]>(url);
    }

    static async createQuestionnaireRecruit(questionnaireId: string, questionnaireRecruit: QuestionnaireRecruitCreate): Promise<string> {
        let url = HttpUtility.combine(this.apiUrl, 'recruit');
        url = HttpUtility.addParameter(url, 'questionnaireId', questionnaireId);
        return await Api.post<string>(url, questionnaireRecruit);
    }

    static async getTeamInfo(teamId: string): Promise<TeamInfo> {
        return await Api.get<TeamInfo>(HttpUtility.combine(this.teamUrl, teamId, 'public')); 
    }

    // partial update

    static async updateAvatar(questionnaireId: string, data: QuestionnaireRecruitAvatarUpdate) {
        let url = HttpUtility.combine(this.apiUrl, 'partial-update', 'avatar');
        url = HttpUtility.addParameter(url, 'questionnaireId', questionnaireId);
        return await Api.post<string>(url, data, {});
    }

    static async updateSmsStatus(questionnaireId: string, data: QuestionnaireRecruitSmsStatusUpdate) {
        let url = HttpUtility.combine(this.apiUrl, 'partial-update', 'sms');
        url = HttpUtility.addParameter(url, 'questionnaireId', questionnaireId);
        return await Api.post<string>(url, data, {});
    }

    static async updateContactField(questionnaireId: string, data: QuestionnaireRecruitContactFieldUpdate) {
        let url = HttpUtility.combine(this.apiUrl, 'partial-update', 'contact', 'field');
        url = HttpUtility.addParameter(url, 'questionnaireId', questionnaireId);
        return await Api.post<string>(url, data, {});
    }

    static async updateField(questionnaireId: string, data: QuestionnaireRecruitFieldUpdate) {

        let url = HttpUtility.combine(this.apiUrl, 'partial-update', 'field');
        url = HttpUtility.addParameter(url, 'questionnaireId', questionnaireId);
        return await Api.post<string>(url, data, {});
    }

    static async updateFiles(questionnaireId: string, data: QuestionnaireRecruitFilesUpdate) {
        let url = HttpUtility.combine(this.apiUrl, 'partial-update', 'files');
        url = HttpUtility.addParameter(url, 'questionnaireId', questionnaireId);
        return await Api.post<string>(url, data, {});
    }

    static async updateFootages(questionnaireId: string, data: QuestionnaireRecruitFootagesUpdate) {
        let url = HttpUtility.combine(this.apiUrl, 'partial-update', 'footages')
        url = HttpUtility.addParameter(url, 'questionnaireId', questionnaireId);
        return await Api.post<string>(url, data, {});
    }

    static async updateInstitutions(questionnaireId: string, data: QuestionnaireRecruitInstitutionsUpdate) {
        let url = HttpUtility.combine(this.apiUrl, 'partial-update', 'institutions')
        url = HttpUtility.addParameter(url, 'questionnaireId', questionnaireId);
        return await Api.post<string>(url, data, {});
    }

    static async updatePositions(questionnaireId: string, data: QuestionnaireRecruitPositionsUpdate) {
        let url = HttpUtility.combine(this.apiUrl, 'partial-update', 'positions');
        url = HttpUtility.addParameter(url, 'questionnaireId', questionnaireId);
        return await Api.post<string>(url, data, {});
    }

    static async updateSocialProfiles(questionnaireId: string, data: QuestionnaireRecruitSocialsUpdate) {
        let url = HttpUtility.combine(this.apiUrl, 'partial-update', 'socials');
        url = HttpUtility.addParameter(url, 'questionnaireId', questionnaireId);
        return await Api.post<string>(url, data, {});
    }

    static async submitQuestionnaire (questionnaireId: string, data: QuestionnaireRecruitSubmit) {
        let url = HttpUtility.combine(this.apiUrl, 'submit-full');
        url = HttpUtility.addParameter(url, 'questionnaireId', questionnaireId);
        return await Api.post<string>(url, data, {});
    }

    static async submitUpdateOnlyQuestionnaire (questionnaireId: string, data: QuestionnaireRecruitSubmit) {
        let url = HttpUtility.combine(this.apiUrl, 'submit');
        url = HttpUtility.addParameter(url, 'questionnaireId', questionnaireId);
        return await Api.post<string>(url, data, {});
    }

}