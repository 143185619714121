import React from "react";
import { ShortStringField } from "../Fields/ShortStringField";
import { FieldWrapper } from "../Common/FieldWrapper";


export interface SocialProps {
    id: string;
    title: string;
    value: string;
    valid: boolean;
    required: boolean;
    onChange: (id: string, answer: string) => void;
    onBlur: (id: string, answer: string) => void;
    isNicknameRequired: boolean;
}

export class Social extends React.Component<SocialProps> {

    render() {
        const { title } = this.props;
        return <FieldWrapper><ShortStringField  {...this.props} name={title} /></FieldWrapper>;
    }
}