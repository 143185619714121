import { connect } from 'react-redux';
import { AnyAction } from "redux";
import { StoreState } from "../../store/configureStore";
import { AvatarUpload } from '../../components/Bio/AvatarUpload';
import { ThunkDispatch } from 'redux-thunk';
import { uploadAvatarActionCreator, deleteAvatarActionCreator } from '../actionCreators';

const mapStateToProps = (state: StoreState, ownProps: any) => {
    return {
        ...ownProps,
        avatarId: state.avatar.avatarId
    }
}

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>) => {
    return {
        onAvatarUpdate: (file: File) => dispatch(uploadAvatarActionCreator(file)),
        onAvatarDelete: () => dispatch(deleteAvatarActionCreator())
    }
}

const AvatarUploadContainer = connect(mapStateToProps, mapDispatchToProps)(AvatarUpload);

export default AvatarUploadContainer;