import { connect } from 'react-redux';
import { StoreState } from "../../store/configureStore";
import { Contact } from '../../components/Info/Contact';


const mapStateToProps = (state: StoreState, ownProps: any) => {
    const id = ownProps.contactInfo.relationId;
    return {
        ...ownProps,
        ...state.contacts.contacts.find(x => x.id === id) || {},
    }
}

const ContactsContainer = connect(mapStateToProps)(Contact);

export default ContactsContainer;