import React from "react";
import { FieldProps } from "../../models/fields/field-props";
import { FieldHeader } from "../Common/FieldHeader";

export class BoolField extends React.PureComponent<FieldProps<boolean>> {

    constructor(props: FieldProps<boolean>) {
        super(props);
        this.onClick = this.onClick.bind(this);
    }

    onClick(event: React.ChangeEvent<HTMLInputElement>) {
        const { onBlur, id, value } = this.props;
        onBlur(id, !value);
    }

    render() {
        const { id, name, required, value, description } = this.props;
        const handledValue = value === null || value === undefined ? false : value;
        return <div className="bool-field">
            <input className="k-checkbox" type="checkbox" id={id} name={id} checked={handledValue} onChange={this.onClick} />
            <label className="k-checkbox-label" htmlFor={id}></label>
            <FieldHeader required={required} for={id} name={name} description={description}/>
        </div>;
    }
}
