import React from 'react';
import ReactDOM from 'react-dom';
import '@progress/kendo-theme-bootstrap/dist/all.css'
import 'bootstrap/dist/css/bootstrap.css';
import '@fortawesome/fontawesome-free/css/all.css'
import './styles/common.css';
import './styles/theme.css';
import './styles/responsive.css'
import App from './App';
import UnsubscribeApp from './UnsubscribeApp';
import * as serviceWorker from './serviceWorker';
import { BrowserRouter, Route, Switch } from 'react-router-dom' 
import PageNotFound from './components/Layout/PageNotFound';
import UploadApp from './UploadApp';

ReactDOM.render(<BrowserRouter>
    <Switch>
        <Route path='/unsubscribe-page' component={UnsubscribeApp} />
        <Route path='/forms/:mnemonic/:type' component={App} />
        <Route path='/:mnemonic/:id' component={App} />
        <Route path='/upload' component={UploadApp} />
        <Route path="*" component={PageNotFound} />
    </Switch>
</BrowserRouter>, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
