import { ON_AVATAR_UPLOAD, SET_AVATAR, ON_AVATAR_DELETE } from './constants';

export interface onAvatarUpload {
    type: typeof ON_AVATAR_UPLOAD;
    payload: {
        avatarId: string;
    }
}

export interface onAvatarDelete {
    type: typeof ON_AVATAR_DELETE;
    payload: {
        avatarId: string;
    }
}

export interface setAvatar {
    type: typeof SET_AVATAR
    payload: {
        avatarId: string;
    }
}

export type AvatarUploadAction = onAvatarUpload | setAvatar | onAvatarDelete;

export class AvatarUploadActions {

    public static onAvatarUpload(avatarId: string): onAvatarUpload {
        return {
            type: ON_AVATAR_UPLOAD,
            payload: {
                avatarId
            }
        }
    }

    public static onAvatarDelete(avatarId: string): onAvatarDelete {
        return {
            type: ON_AVATAR_DELETE,
            payload: {
                avatarId
            }
        }
    }

    public static setAvatar(avatarId: string) {
        return {
            type: SET_AVATAR,
            payload: {
                avatarId
            }
        }
    }   
}

