export class HttpUtility {
    private static combineInternal(basePath: string, subPath: string): string {
        return basePath.replace(/\/$/g, '') + '/' + subPath.replace(/^\//g, '');
    }

    static combine(...path: string[]): string {
        if (path.length === 0) {
            return "";
        }
        let result = path[0];
        for (let i = 1, l = path.length; i < l; i++) {
            result = HttpUtility.combineInternal(result, path[i]);
        }
        return result;
    }

    static addParameter(url: string, key: string, value: string): string {
        if (!url) {
            return url;
        }
        return url.indexOf('?') >= 0
            ? `${url}&${encodeURIComponent(key)}=${encodeURIComponent(value)}`
            : `${url}?${encodeURIComponent(key)}=${encodeURIComponent(value)}`;
    }
}