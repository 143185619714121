import { MediaApi } from "../api/MediaApi";
import { OrganizationApi } from "../api/OrganizationApi";
import { UploadInfo } from "../models/api-models/media/upload-info";

export class UploadService {
  static async uploadFile(data: FormData, name: string): Promise<UploadInfo> {
    return await MediaApi.upload(data, name, true);
  }

  static async uploadForm(mediaId: string, name: string, email: string, fileName: string): Promise<void> {
    return await OrganizationApi.upload(mediaId, name, email, fileName);
  }
}

