import React, { Component, ChangeEvent } from 'react';
import { Observable, Subject, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, takeUntil } from 'rxjs/operators';
import { Input } from "@progress/kendo-react-inputs";
import { InstitutionItemProps } from './InstitutionItem';
import { InstitutionSearchResult } from './InstitutionSearchResult';

export class InstitutionSearch extends Component<InstitutionItemProps, { inputValue: string, active: boolean }> {

    destroy$ = new Subject();

    private searchResult;
    private closeTag;
    private searchInput;

    private _isMounted;

    private defaultPage = 1;

    constructor(props: InstitutionItemProps) {
        super(props);
        this.state = {
            inputValue: '',
            active: false,
        }
        this.searchResult = React.createRef();
        this.closeTag = React.createRef();
        this.searchInput = React.createRef();
        this.inputValueChange = this.inputValueChange.bind(this);
        this.onFocusActive = this.onFocusActive.bind(this);
        this.onBlurActive = this.onBlurActive.bind(this);
        this.clearInstitutionTag = this.clearInstitutionTag.bind(this);
        this.selectItem = this.selectItem.bind(this);
        this._isMounted = false;
    }

    searchTerm$ = new Subject<string>();

    componentDidMount() {
        this._isMounted = true;
        const { searchInstitutionsByTerm, id, setNextSearchResultValue } = this.props;
        const { pageSize } = this.props.value;
        this.waitForInput(this.searchTerm$).pipe(takeUntil(this.destroy$)).subscribe(term => {
            if (term.trim().length) {
                setNextSearchResultValue(id, this.defaultPage);
                searchInstitutionsByTerm(id, term, id, this.defaultPage, pageSize || 20);
            } else {
                const { setLoadingSearchResults, id } = this.props;
                setLoadingSearchResults(id, false);
            }
        });
    }

    componentWillUnmount() {
        this.destroy$.next();
        this.destroy$.complete();
        this.searchTerm$.complete();
        this._isMounted = false;
    }

    inputValueChange(e: React.ChangeEvent<HTMLInputElement>) {
        const target = e.target;
        const { setLoadingSearchResults, id, clearSearchResults, setUpInstitutionSearchName } = this.props;
        const { showRadioInstitutionOptions } = this.props.value;
        if (!!target.value) {
            setLoadingSearchResults(id, true);
        } else {
            setLoadingSearchResults(id, false);
            clearSearchResults(id);

        }
        this.setState({ inputValue: target.value });
        setUpInstitutionSearchName(id, target.value);

        this.searchTerm$.next(target.value);
    }

    private waitForInput(terms: Observable<string>): Observable<string> {
        return terms.pipe(
            debounceTime(600),
            distinctUntilChanged(),
            map(filter => filter.trim())
        );
    }

    onFocusActive() {
        this.setState({ active: true });
    }

    clearInstitutionTag() {
        const { setCategory, id, clearSearchResults } = this.props;
        setCategory(id, '', '');
        this.setState({ inputValue: '' });
        clearSearchResults(id);
        setTimeout(x => { this.searchInput.current.props.onFocus() });
        this.searchTerm$.next('');
    }

    onBlurActive() {
        setTimeout(_ => {
            if (this._isMounted)
                this.setState({ active: false })
        }, 250);
    }

    selectItem() {
        this.setState({ active: false, inputValue: '' });
        const { id, clearSearchResults } = this.props
        clearSearchResults(id);
    }

    render() {
        const { inputValue, active } = this.state;
        const { institutionName, valid } = this.props.value;
        return (
            <div className="search-wrap">
                {!!institutionName && <div ref={this.closeTag} onClick={this.clearInstitutionTag} className='institution-tag'>{institutionName}<i className='institution-tag-close fas fa-times'></i></div>}
                <Input ref={this.searchInput} type="text" className='k-textbox w-100' valid={valid} value={inputValue} disabled={!!institutionName}
                    onBlur={this.onBlurActive} onFocus={this.onFocusActive} onChange={this.inputValueChange} />
                {active && <div ref={this.searchResult}><InstitutionSearchResult {...this.props} selectItem={this.selectItem} active={active} inputValue={inputValue} /></div>}
            </div>
        )
    }

}