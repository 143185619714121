import * as React from 'react';
import Spinner from './Spinner';

const InitializingSpinner: React.FC<{}> = (props: {}) => {
    return (
        <div className="initializing">
            <Spinner/>
        </div>
    )
} 

export default InitializingSpinner;