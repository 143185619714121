import { BioFieldState, bioFieldReducer } from "../containers/BioField/reducers";
import { AvatarUploadState, avatarUploadReducer } from "../containers/AvatarUpload/reducers";
import { SportStatsFieldState, sportStatsFieldReducer } from "../containers/SportStatsField/reducers";
import { SocialState, socialReducer } from "../containers/Social/reducers";
import { FileState, fileReducer } from "../containers/FileUpload/reducers";
import { FootageState, footageReducer } from "../containers/FootageUpload/reducers";
import { ContactState, contactReducer } from "../containers/Contact/reducers";
import { combineReducers, createStore, applyMiddleware } from "redux";
import thunkMiddleware from 'redux-thunk'
import { QuestionnaireState, questionnaireReducer } from "../containers/Questionnaire/reducers";
import {InstitutionsState, InstitutionsReducer} from '../containers/Institutions/reducers';

export interface StoreState {
    bioFields: BioFieldState,
    sportStatsFields: SportStatsFieldState,
    avatar: AvatarUploadState,
    socials: SocialState,
    files: FileState,
    footage: FootageState,
    contacts: ContactState,
    questionnaire: QuestionnaireState,
    institutions: InstitutionsState,
}

const reducers = combineReducers<StoreState>({
    bioFields: bioFieldReducer,
    sportStatsFields: sportStatsFieldReducer,
    avatar: avatarUploadReducer,
    socials: socialReducer,
    files: fileReducer,
    footage: footageReducer,
    contacts: contactReducer,
    questionnaire: questionnaireReducer,
    institutions: InstitutionsReducer,
});

export function configureStore() {
    return createStore(
        reducers,
        applyMiddleware(thunkMiddleware));
}

