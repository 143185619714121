import moment from 'moment';
import { FieldValueType } from "../models/api-models/fields/field-value-type";
import { FieldValue } from "../models/api-models/fields/field-value";

export class FieldUtility {
    public static toRawValue(v: FieldValue, valueType: FieldValueType): any {
        switch (valueType) {
            case FieldValueType.Bool:
                return !!v.boolValue;
            case FieldValueType.Number:
                return v.shortValue || (v.decimalValue ? v.decimalValue.toString() : "");
            case FieldValueType.ShortString:
                return v.shortValue;
            case FieldValueType.LongString:
                return v.stringValue;
            case FieldValueType.DateTimeOffset:
                return new Date(v.dateTimeOffsetValue);
            case FieldValueType.Dropdown:
                return v.shortValue;
            case FieldValueType.Multiselect: {
                return this.safeParse(v.stringValue, []);
            }
            case FieldValueType.TimeResult: {
                return this.convertTimeToString(v.decimalValue);
            }
            case FieldValueType.Events: {
                let events = this.safeParse(v.stringValue, [] as Array<{title: string, date: Date}>);
                return events.map(val => ({...val, date: this.convertToDate(val.date)}));
            }
            case FieldValueType.TimeDate: {
                return {
                    time: this.convertTimeToString(v.decimalValue),
                    date: this.convertToDate(v.dateTimeOffsetValue),
                };
            }
            case FieldValueType.ResultDate: {
                return {
                    result: v.shortValue,
                    date: this.convertToDate(v.dateTimeOffsetValue),
                };
            }
            case FieldValueType.Url: {
                return v.shortValue
            }
            default:
                return undefined;
        }
    }

    public static convertTimeToString(time: number | undefined): string {
        if (!time) {
            return '';
        }
        let result = "";
        let minutes = time ? ((Math.floor(time / 6000)).toString() === '0' ? '' : Math.floor(time / 6000).toString()) : '';
        let seconds = time ? ((Math.floor(time / 100) % 60).toString() === '0' ? '' : (Math.floor(time / 100) % 60).toString()) : '';
        let hundreds = time ? ((time % 100).toString() === '0' ? '' : (time % 100).toString()) : '';
        if (seconds.length === 1) {
            seconds = this.padZeros(seconds, 2);
        }
        if (hundreds.length === 1) {
            hundreds = this.padZeros(hundreds, 2);
        }
        if (minutes.length > 0) {
            result = minutes;
            if (seconds.length > 0) {
                result = result + ":" + seconds;
                if (hundreds.length > 0) {
                    result = result + "." + hundreds;
                }
            }
        } else {
            if (seconds.length > 0 && hundreds.length > 0) {
                result = seconds + "." + hundreds;
            }
        }
        return result;
    }

    public static padZeros(value: string, length: number): string {
        while (value.length < length) {
            value = '0' + value;
        }
        return value;
    }

    public static safeParse<T>(valueToParse: string | undefined, defaultValue: T) {
        if (!valueToParse) {
            return defaultValue;
        }
        try {
            return JSON.parse(valueToParse) as T;
        } catch (e) {
            console.error(e);
            return defaultValue;
        }
    }

    static convertTimeFromString(time: string): number | undefined {
        if (time) {
            let minutes = "";
            let seconds = "";
            let hundreds = "";
            if (time.indexOf('.') === -1 && time.indexOf(':') === -1) {
                //examples: 9, 09, 100
                minutes = time;
            } else {
                if (time.indexOf('.') !== -1) {
                    let parts: string[] = time.split('.');

                    if (parts[0].indexOf(':') === -1) {
                        //examples: 09.45
                        minutes = "";
                        seconds = parts[0];
                        hundreds = parts[1];
                    } else {
                        //examples: 59:34.12, 23:59.12
                        let minSec: string[] = parts[0].split(':');
                        minutes = minSec[0];
                        seconds = minSec[1];
                        hundreds = parts[1];
                    }
                } else {
                    //examples: 59:34
                    let parts: string[] = time.split(':');
                    minutes = parts[0];
                    seconds = parts[1];
                    hundreds = "";
                }
            }
            return ((minutes ? Number(minutes) * 6000 : 0) +
                (seconds ? Number(seconds) * 100 : 0) +
                (hundreds ? Number(hundreds) : 0));
        }
        return undefined;
    }

    static setSpecifiedValue(id: string, valueType: FieldValueType, value: any): FieldValue {
        let valObject: FieldValue = { fieldId: id };
        switch (valueType) {
            case FieldValueType.Bool: {
                valObject.boolValue = !!value;
                break;
            }
            case FieldValueType.Number: {
                valObject.decimalValue = Number.parseFloat(value);
                valObject.shortValue = `${value}`;
                break;
            }
            case FieldValueType.ShortString: {
                valObject.shortValue = value;
                break;
            }
            case FieldValueType.LongString: {
                valObject.stringValue = value;
                break;
            }
            case FieldValueType.DateTimeOffset: {
                valObject.dateTimeOffsetValue = this.convertToDateTimeOffset(value);
                break;
            }
            case FieldValueType.Dropdown: {
                valObject.shortValue = value;
                break;
            }
            case FieldValueType.Multiselect: {
                valObject.stringValue = !!value ? JSON.stringify(value) : undefined;
                break;
            }
            case FieldValueType.TimeResult: {
                //parse time string format 000:00.00
                valObject.decimalValue = this.convertTimeFromString(value);
                break;
            }
            case FieldValueType.Events: {
                let res = value;
                if (!!res) {
                    res = (res as Array<{title: string, date: Date}>).map(v => ({...v, date: this.convertToDateTimeOffset(v.date)}));
                }
                valObject.stringValue = !!res ? JSON.stringify(res) : undefined;
                break;
            }
            case FieldValueType.TimeDate: {
                valObject.decimalValue = this.convertTimeFromString(value.time);
                valObject.dateTimeOffsetValue = this.convertToDateTimeOffset(value.date);
                break;
            }
            case FieldValueType.ResultDate: {
                valObject.shortValue = value.result;
                valObject.dateTimeOffsetValue = this.convertToDateTimeOffset(value.date);
                break;
            }
            case FieldValueType.Url: {
                valObject.shortValue = value;
                break;
            }
            default:
                throw new Error("Invalid value type");
        }
        return valObject;
    }

    private static convertToDate(date: Date | null) {
        let value = date;
        if (!!value) {
            value = moment(value, 'YYYY-MM-DD').toDate();
        }
        return value;
    }

    private static convertToDateTimeOffset(value: Date | null): any {
        if (!value) return value;
        return moment.utc(moment(value).format('YYYY-MM-DD')).format();
    }

    public static difference(array1: string[], array2: string[]): string[] {
        return array1.filter(item => array2.indexOf(item) < 0);
    }

    public static differenceFilter(array1: { id: string, name: string, checked: boolean }[], array2: { id: string, name: string, checked: boolean }[]): { id: string, name: string, checked: boolean }[] {
        return array1.filter(item => array2.map(a => a.name).indexOf(item.name) < 0);
    }

    public static testTimeResult(value: string): boolean {
        return /^((?:([0-9][0-9][0-9])|([0-9][0-9])|[0-9])((:[0-5][0-9](?:[.]\d{2})?)?)|(((?:([0-5][0-9])|[0-9])(?:[.]\d{2})?)?))$/.test(value);
    }

    public static testNumber(value: string): boolean {
        return /^\s*[-+]?\d+(\.\d+)?\s*$/.test(value) && Number.isFinite(Number(value));
    }

    public static testInteger(value: string): boolean {
        return /^\s*[-+]?\d+\s*$/.test(value) && Number.isFinite(Number(value));
    }

    public static testEmail(value: string): boolean {
        if (!value || value === '') {
            return true;
        }
        return /^(?=.{1,254}$)(?=.{1,64}@)[-!#$%&'*+/0-9=?A-Z^_`a-z{|}~]+(\.[-!#$%&'*+/0-9=?A-Z^_`a-z{|}~]+)*@[A-Za-z0-9]([A-Za-z0-9-]{0,61}[A-Za-z0-9])?(\.[A-Za-z0-9]([A-Za-z0-9-]{0,61}[A-Za-z0-9])?)+$/.test(String(value).toLowerCase())
    }

}
