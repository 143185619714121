export const SET_INSTITUTION_FIELDS = '@ARI/INSTITUTIONS/SET_INSTITUTION_FIELDS';
export const SET_INSTITUTION_SEARCH_RESULT = '@ARI/INSTITUTIONS/SET_INSTITUTION_SEARCH_RESULT';
export const SET_INSTITUTION_ITEM = '@ARI/INSTITUTIONS/SET_INSTITUTION_ITEM';
export const SHOW_INSTITUTION_CUSTOM = '@ARI/INSTITUTIONS/SHOW_INSTITUTION_CUSTOM';
export const SWITCH_INSTITUTION_INPUT = '@ARI/INSTITUTIONS/SWITCH_INSTITUTION_INPUT';
export const CHANGE_CUSTOM_INSTITUTION_INPUT = '@ARI/INSTITUTIONS/CHANGE_CUSTOM_INSTITUTION_INPUT';
export const SET_INSTITUTIONS_VALUES = "@ARI/INSTITUTIONS/SET_INSTITUTIONS_VALUES";
export const SET_UP_SEARCH_RESULT_LOADING = '@ARI/INSTITUTIONS/SET_UP_SEARCH_RESULT_LOADING';
export const SET_UP_INSTITUTION_SEARCH_NAME = '@ARI/INSTITUTIONS/SET_UP_INSTITUTION_SEARCH_NAME';
export const SET_INSTITUTION_VALIDATORS = '@ARI/INSTITUTIONS/SET_INSTITUTIONS_VALIDATORS';
export const SET_INSTITUTIONS_ERRORS = '@ARI/INSTITUTIONS/SET_INSTITUTIONS_ERRORS';
export const SET_NEXT_SEARCH_RESULT_PAGE = '@ARI/INSTITUTIONS/SET_NEXT_SEARCH_RESULT_PAGE';
export const CONCAT_INSTITUTION_SEARCH_RESULT = '@ARI/CONCAT_INSTITUTION_SEARCH_RESULT';