
export class ApiConfigs {
    static get recruitingApiUrl(): string {
        return process.env.REACT_APP_RECRUITING_API_URL!;
    }

    static get organizationApiUrl(): string {
        return process.env.REACT_APP_ORGANIZATION_API_URL!;
    }

    static get mediaApiUrl(): string {
        return process.env.REACT_APP_MEDIA_API_URL!;
    }

    static get trackingApiUrl(): string {
        return process.env.REACT_APP_TRACKING_API_URL!;
    }

    static get messagingApiUrl(): string {
        return process.env.REACT_APP_MESSAGING_API_URL!;
    }
}