import { HttpUtility } from "../utilities/HttpUtility";
import { ApiConfigs } from "../configs/ApiConfigs";
import { Api } from "./Api";


export class UnsubscribeApi {

    private static get messagingApiUrl(): string {
        return HttpUtility.combine(ApiConfigs.messagingApiUrl, 'v1/subscriptionStatus');
    }

    private static get recruitingApiUrl(): string {
        return HttpUtility.combine(ApiConfigs.recruitingApiUrl, 'v1/recruits');
    }

    static async unsubscribe(contactId: string, messageCode: string): Promise<void> {
        return await Api.get<void>(HttpUtility.combine(this.messagingApiUrl, `${contactId}/${messageCode}/unsubscribe`));
    }

    static async subscribe(contactId: string, messageCode: string): Promise<void> {
        return await Api.get<void>(HttpUtility.combine(this.messagingApiUrl, `${contactId}/${messageCode}/subscribe`));
    }

    static async getContactInfoById(contactId: string): Promise<any> {
        return await Api.get<void>(HttpUtility.combine(this.recruitingApiUrl, `${contactId}/unsubscribed-info`));
    }
}