import { connect } from 'react-redux';
import { AnyAction } from "redux";
import { StoreState } from "../../store/configureStore";
import { MultipleEventChoice } from '../../components/SportStats/MultipleEventChoice';
import { SportStatsFieldActions } from '../SportStatsField/actions';
import { ThunkDispatch } from 'redux-thunk';

const mapStateToProps = (state: StoreState, ownProps: any) => {
    return {
        ...ownProps,
        checkedFields: state.sportStatsFields.multipleEventChecked,
        groups: state.sportStatsFields.sportStatsFields.filter(x => x.id === ownProps.groupId)
    }
}

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>) => {
    return {
        onCheck: (id: string) => dispatch(SportStatsFieldActions.checkMultipleEvent(id)),
    }
}

const MultipleEventChoiceContainer = connect(mapStateToProps, mapDispatchToProps)(MultipleEventChoice);

export default MultipleEventChoiceContainer;
