import { ON_FILE_ADD, ON_FILE_REMOVE, SET_FILE_VALIDATORS, SET_FILE_VALUES, SET_FILE_SETTINGS, SET_FILE_ERRORS } from "./constants";
import { Validator } from "../../utilities/ValidatorUtility";
import { File } from "../../models/files/file";
import { FileAttachment } from "../../models/files/file-attachment";

export interface onFileAdd {
    type: typeof ON_FILE_ADD;
    payload: {
        id: string;
        file: FileAttachment;
    }
}

export interface onFileRemove {
    type: typeof ON_FILE_REMOVE;
    payload: {
        id: string;
        index: number;
    }
}

export interface setFileValidators {
    type: typeof SET_FILE_VALIDATORS;
    payload: {
        validators: { [id: string]: Validator[] }
    }
}

export interface setFileValues {
    type: typeof SET_FILE_VALUES;
    payload: {
        values: { [id: string]: FileAttachment[] }
    }
}

export interface setFileSettings {
    type: typeof SET_FILE_SETTINGS;
    payload: {
        files: File[]
    }
}

export interface setFileErrors {
    type: typeof SET_FILE_ERRORS;
    payload: {
        errors: { [id: string]: boolean }
    }
}

export type FileAction = onFileAdd | onFileRemove | setFileValidators | setFileValues | setFileSettings | setFileErrors;

export class FileActions {

    public static onFileAdd(id: string, file: FileAttachment) {
        return {
            type: ON_FILE_ADD,
            payload: {
                id,
                file
            }
        }
    }

    public static onFileRemove(id: string, index: number) {
        return {
            type: ON_FILE_REMOVE,
            payload: {
                id,
                index
            }
        }
    }

    public static setFileValidators(validators: { [id: string]: Validator[] }) {
        return {
            type: SET_FILE_VALIDATORS,
            payload: {
                validators
            }
        }
    }

    public static setFileValues(values: { [id: string]: FileAttachment[] }) {
        return {
            type: SET_FILE_VALUES,
            payload: {
                values
            }
        }
    }

    public static setFileSettings(files: File[]) {
        return {
            type: SET_FILE_SETTINGS,
            payload: {
                files
            }
        }
    }

    public static setFileErrors(errors: { [id: string]: boolean }) {
        return {
            type: SET_FILE_ERRORS,
            payload: {
                errors
            }
        }
    }

}