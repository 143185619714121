import React from "react";
import { FieldGroup } from "../../models/fields/field-group";
import { FieldGroupHeader } from "../Common/FieldGroupHeader";
import { excludeFromMultipleEvent } from "../../constants/Constants";
import { SportStatsFieldList } from "./SportStatsFieldList";


export interface SportStatsFieldGroupProps extends FieldGroup {
    key: string;
    multipleEvenForm: boolean;
}

export class SportStatsFieldGroup extends React.Component<SportStatsFieldGroupProps> {

    render() {
        const { name, nameId, fields, multipleEvenForm, description } = this.props;
        return <React.Fragment>
              {(!multipleEvenForm || excludeFromMultipleEvent.some(x => x === nameId )) && <FieldGroupHeader>{name}</FieldGroupHeader>}
              { description && description.length && <label className="field-description">{description}</label> }
              <SportStatsFieldList fields={fields} />
        </React.Fragment>;
    }
}