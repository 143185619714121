import { QuestionnaireAction } from "./actions";
import {
    ON_TAB_SELECT,
    ON_LOAD_END,
    SET_QUESTIONNAIRE,
    ON_SUBMIT_END,
    ON_SUBMIT_REJECTED,
    ON_SUBMIT_STARTED,
    POST_NEW,
    SET_TAB_LIST,
    SET_QUESTIONNAIRE_IS_AVAILABLE,
    SHOW_CONFIRMATION_RESET_DIALOG,
    CLOSE_DIALOGS,
    SHOW_DATA_CORRUPT_DIALOG,
    SET_PRTIAL_UPDATE_STATUS,
} from "./constants";
import { Questionnaire } from "../../models/questionnaire/questionnaire";
import { Validator } from "../../utilities/ValidatorUtility";
import { Tab } from "../../models/tab/tab";
import { PartialUpdateTypes } from "../../constants/Constants";

export interface QuestionnaireState {
    questionnaire: Questionnaire;
    IsQuestionnaireAvailable: boolean,
    loaded: boolean,
    submited: boolean,
    submitInProgress: boolean,
    selectedTab: number,
    tabList: Tab[],
    bioFieldValidators: { [id: string]: Validator[] };
    showResetDialog: boolean;
    showDataCorruptDialog: boolean;
    isPartialUpdateInProgress: boolean;
}

const initialState: QuestionnaireState = {
    questionnaire: {
        id: '',
        url: '',
        questionnaireId: '',
        teamId: '',
        teamName: '',
        status: '',
        title: '',
        introduction: '',
        color1: '',
        color2: '',
        color3: '',
        logoId: '',
        submissionMessage: '',
        ConsentToReceiveSms: true,
        isUpdateOnlyForm: false,
    },
    IsQuestionnaireAvailable: false,
    loaded: false,
    submited: false,
    submitInProgress: false,
    showResetDialog: false,
    showDataCorruptDialog: false,
    isPartialUpdateInProgress: false,
    selectedTab: 0,
    tabList: [{ title: 'Page 1', items: [] }],
    bioFieldValidators: {},
}


export const questionnaireReducer = (state: QuestionnaireState = initialState, action: QuestionnaireAction) => {
    switch (action.type) {
        case ON_TAB_SELECT: {
            window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
            return {
                ...state,
                selectedTab: action.payload.index
            }
        } 

        case ON_LOAD_END: {
            return {
                ...state,
                loaded: true,
            }
        } 

        case SET_QUESTIONNAIRE: {
            return {
                ...state,
                questionnaire: action.payload.questionnaire
            }
        } 
        case ON_SUBMIT_STARTED: {
            return {
                ...state,
                submitInProgress: true
            }
        }
        case ON_SUBMIT_REJECTED: {
            return {
                ...state,
                submitInProgress: false
            }
        }
        case ON_SUBMIT_END: {
            return {
                ...state,
                submited: true,
                submitInProgress: false
            }
        } 
        case POST_NEW: {
            return {
                ...state,
                submited: false,
            }
        }
        case SET_TAB_LIST: {
            return {
                ...state,
                tabList: action.payload
            }
        }
        case SET_QUESTIONNAIRE_IS_AVAILABLE: {
            return {
                ...state,
                IsQuestionnaireAvailable: action.payload
            }
        }

        case SHOW_CONFIRMATION_RESET_DIALOG: {
            return {
                ...state,
                showResetDialog: true
            }
        }
        case SHOW_DATA_CORRUPT_DIALOG: {
            return {
                ...state,
                showDataCorruptDialog: true
            }
        }
        case CLOSE_DIALOGS: {
            return {
                ...state,
                showResetDialog: false,
                showDataCorruptDialog: false,
            }
        }
        case SET_PRTIAL_UPDATE_STATUS: {
            return {
                ...state,
                isPartialUpdateInProgress: action.payload,    
            }
        }
        default: {
            return state;
        }
    }
}
