import { connect } from 'react-redux';
import { StoreState } from "../../store/configureStore";
import { InstitutionList } from '../../components/Institutions/InstitutionsList';

const mapStateToProps = (state: StoreState, ownProps: any) => {
    
    const categories = state.institutions.institutionsFields.filter(x => x.id === ownProps.categoryId);
    return {
        institutions: categories
    }
  }
    
const InstitutionListContainer = connect(mapStateToProps)(InstitutionList);

export default InstitutionListContainer;