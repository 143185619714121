import React from "react";
import { Upload, UploadFileRestrictions, UploadOnStatusChangeEvent } from '@progress/kendo-react-upload';
import { QuestionnaireService } from "../../services/QuestionnaireService";
import { Attachment } from "./Attachment";
import { UploadInfo } from "../../models/api-models/media/upload-info";
import { FootageAttachment } from "../../models/footage/footage-attachment";
import { Input } from "@progress/kendo-react-inputs";
import { ValidatorUtility } from "../../utilities/ValidatorUtility";
import { Guid } from "../../utilities/GuidUtility";

export interface FootageUploadProps {
    id: string;
    title: string;
    description: string;
    value: FootageAttachment[];
    required: boolean;
    valid: boolean;
    onAddFootage: (id: string, file: FootageAttachment) => void;
    onRemoveFootage: (id: string, index: number) => void;
    setFootageErrors: (errors: { [id: string]: boolean }) => void;
}

export class FootageUpload extends React.PureComponent<FootageUploadProps> {
    
    constructor(props: FootageUploadProps) {
        super(props);
        this.onStatusChange = this.onStatusChange.bind(this);
        this.onRemove = this.onRemove.bind(this);
        this.onUrlAdd = this.onUrlAdd.bind(this);
        this.onTextChange = this.onTextChange.bind(this);
    }
    url: string = 'test';
    urlValid: boolean = true;
    uploadUrl = QuestionnaireService.getUploadUrl();
    removeUrl = QuestionnaireService.getRemoveUrl();
    filesRestrictions: UploadFileRestrictions = {
        allowedExtensions: ['mp4','mov']
    };

    onStatusChange(e: UploadOnStatusChangeEvent): void {
        const { value, title, onAddFootage, id } = this.props;
        const order = value && value.length > 0
            ? value[value.length - 1].order + 1
            : 0;

        const uploadInfo = e.response.response as UploadInfo;
        let file: FootageAttachment = {
            uid: uploadInfo.id,
            shared: false,
            favorite: false,
            footageDate: new Date(),
            size: uploadInfo.size,
            name: uploadInfo.fileName.slice(0, 255),
            title: title.slice(0, 255),
            mediaId: uploadInfo.id,
            url:uploadInfo.link,
            order: order
        };
        onAddFootage(id, file);
        this.url = '';
    }

    onRemove(uid: string) {
        const { onRemoveFootage, id, value } = this.props;
        const index = value.findIndex(x => x.uid === uid);
        if (index !== -1) {
            onRemoveFootage(id, index);
        }
    }

    onTextChange(event) {
        this.url = event.value;
    }

    onUrlAdd() {
        const { value, onAddFootage, setFootageErrors, id } = this.props;
        const order = value && value.length > 0
            ? value[value.length - 1].order + 1
            : 0;
        
        if(ValidatorUtility.validateFootageUrl(this.url)) {
            this.urlValid = false;
            setFootageErrors({[id]: true});
        } else  {
            this.urlValid = true;
            let footage: FootageAttachment = {
                uid: Guid.newGuid(),
                favorite: false,
                shared: false,
                footageDate: new Date(),
                mediaId: '',
                name: this.url.slice(0, 255),
                size: 0,
                title: this.url.slice(0, 255),
                url: this.url,
                order: order
            };
            this.url = "";
            onAddFootage(id, footage);
        }
    }

    render() {
        const { valid, value, title, required, description } = this.props;
        const className = 'field-header' + (required ? ' require' : '');
        const validClassName = this.urlValid && valid ? '' : 'state-invalid';

        return <div className="form-group">
            <label className={className}>{title}</label><br/>
            { description && description.length && <label className="field-description">{description}</label> }
            <div>
                <div style={{ display: 'flex'}}>
                <Input maxLength={2048}
                        className={validClassName + ' form-control box-shadow-none'}
                        onChange={this.onTextChange}
                        placeholder={'Paste video url'}/>
                    <button className={'border-none common-but'} onClick={this.onUrlAdd}>Add url</button>
                </div>

                <Upload saveUrl={this.uploadUrl}
                    removeUrl={this.removeUrl}
                    showFileList={false}
                    restrictions={this.filesRestrictions}
                    onStatusChange={this.onStatusChange}
                    className={'border-none p-0'}/>    
            </div>        
            {value && value.length !== 0 && <div className="upload-container p-2">
                {value.map(x => {
                    return <Attachment key={x.uid} attachment={x} onRemove={this.onRemove} />;
                })}
            </div>}
        </div>
    }
}
