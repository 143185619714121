import React from "react";
import '../../styles/components/UploadForm/SuccessUpload.css';

const SuccessUpload = () => {
  return (
<div className="success-text-block">
  <div className="success-text">
    <img className="complete-upload-logo" alt="Complete" src={require('../../images/complete.svg')} />
    <br />
      Your form has been submitted
  </div>
</div>
  )
}

export default SuccessUpload;