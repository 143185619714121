import React from 'react';
import 'react-toastify/dist/ReactToastify.css';
import UploadForm from './components/UploadForm/UploadForm';

const UploadApp: React.FC = () => {
    return (
        <React.Fragment>
            <UploadForm />
        </React.Fragment>
    );
}

export default UploadApp;
