import { PureComponent } from "react";
import '../../styles/components/Divider.css';
import React from "react";

export interface DividerProps {
    color: string;
}

export class Divider extends PureComponent<DividerProps> {

    render() {
        const style: React.CSSProperties = { backgroundColor: this.props.color };
        return <div className="divider" style={style}></div>
    }
}