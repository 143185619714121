import React from "react";
import { QuestionnaireHeader } from "./Layout/QuestionnaireHeader";
import { TabSelector } from "./Paging/TabSelector";
import { QuestionnaireContent } from "./Layout/QuestionnaireContent";
import { Pager } from "./Paging/Pager";
import { Questionnaire as QuestionnaireModel } from "../models/questionnaire/questionnaire";
import InitializingSpinner from "./Layout/InitializingSpinner";
import OverlaySpinner from "./Layout/OverlaySpinner";
import FormIsNotAvailable from "./Layout/FormIsNotAvailable";
import { } from "../containers/Questionnaire/index"
import { Redirect } from "react-router-dom";
import { QuestionnaireTab } from './QuestionnaireTab';
import { Tab } from "../models/tab/tab";
import { Dialog } from "./Layout/Dialog";

export interface QuestionnaireProps {
    questionnaire: QuestionnaireModel
    IsQuestionnaireAvailable: boolean,
    IsQuestionnaireExist: boolean,
    loaded: boolean,
    submited: boolean,
    submitInProgress: boolean,
    selectedTab: number,
    showResetDialog: boolean,
    tabList: Tab[],
    showDataCorruptDialog: boolean,
    onInit: () => Promise<void>;
    onTabSelect: (index: number) => void;
    onPostNew: () => void;
    onSubmit: () => Promise<void>;
    onShowResetDialog: () => void;
    onCloseDialogs: () => void;
    onResetForm: () => void;
    setPartialUpdateStatus: (partialUpdateInProgress: boolean) => void;
}

export class Questionnaire extends React.Component<QuestionnaireProps> {

    componentDidMount() {
        const { onInit } = this.props;
        onInit();
    }

    render() {
        document.title = "Questionnaire - ARI"

        const {
            selectedTab,
            tabList,
            submited,
            submitInProgress,
            loaded,
            questionnaire,
            IsQuestionnaireAvailable,
            showResetDialog,
            showDataCorruptDialog,
            onTabSelect,
            onPostNew,
            onSubmit,
            onShowResetDialog,
            onCloseDialogs,
            onResetForm,
            setPartialUpdateStatus,
        } = this.props;

        const resetAttention = 'Are you sure you want to reset the form?';
        const dataCorruptAttention = 'Local data are corrupted. Please fill the form from scratch.';

        let style: React.CSSProperties = { backgroundColor: '#ffffff' };

        if (!loaded) {
            return <InitializingSpinner />
        }
        if (!IsQuestionnaireAvailable) {
            return <FormIsNotAvailable />
        }
        if (!questionnaire) {
                return <Redirect to='/404' />
        }

        style = { backgroundColor: questionnaire.color1 };

        return <React.Fragment>
            <QuestionnaireHeader {...questionnaire} submited={submited} onPostNew={onPostNew}>
                {!submited && <TabSelector
                    selectedTab={selectedTab}
                    color={questionnaire.color2}
                    tabList={tabList}
                    onSelectTab={onTabSelect} />}
            </QuestionnaireHeader>
            <section className="content-section" style={style}>
                <OverlaySpinner display={submitInProgress} color={questionnaire.color2} />
                {!submited &&
                    <QuestionnaireContent>
                        <QuestionnaireTab tab={tabList[selectedTab]} />
                        <Pager
                            tabList={tabList}
                            selectedTab={selectedTab}
                            onSelectTab={onTabSelect}
                            onSubmit={onSubmit}
                            onShowResetDialog={onShowResetDialog}
                            setPartialUpdateStatus={setPartialUpdateStatus}
                            status={questionnaire.status} />
                    </QuestionnaireContent>}
            </section>
            {showResetDialog && <Dialog 
                height={150} 
                text={resetAttention} 
                onAction={onResetForm} 
                onCancel={onCloseDialogs} />}
            {showDataCorruptDialog && <Dialog
                height={150}
                text={dataCorruptAttention}
                actionBtnLabel="Reset"
                onAction={onResetForm}
            />}
        </React.Fragment>
    }
}
