import { ON_SOCIAL_CHANGE, ON_SOCIAL_BLUR, SET_SOCIAL_VALIDATORS, SET_SOCIAL_VALUES, SET_SOCIAL_SETTINGS, SET_SOCIAL_ERRORS } from "./constants";
import { Social } from "../../models/socials/social";
import { Validator } from "../../utilities/ValidatorUtility";

export interface onSocialChange {
    type: typeof ON_SOCIAL_CHANGE;
    payload: {
        id: string;
        value: string;
    }
}

export interface onSocialBlur {
    type: typeof ON_SOCIAL_BLUR;
    payload: {
        id: string;
        value: string;
    }
}

export interface setSocialValidators {
    type: typeof SET_SOCIAL_VALIDATORS;
    payload: {
        validators: { [id: string]: Validator[] }
    }
}

export interface setSocialValues {
    type: typeof SET_SOCIAL_VALUES;
    payload: {
        values: { [id: string]: any }
    }
}

export interface setSocialSettings {
    type: typeof SET_SOCIAL_SETTINGS;
    payload: {
        socials: Social[]
    }
}

export interface setSocialErrors {
    type: typeof SET_SOCIAL_ERRORS;
    payload: {
        errors: { [id: string]: boolean }
    }
}

export type SocialAction = onSocialChange | onSocialBlur | setSocialValidators | setSocialValues | setSocialSettings | setSocialErrors;

export class SocialActions {

    public static onSocialChange(id: string, value: string) {
        return {
            type: ON_SOCIAL_CHANGE,
            payload: {
                id,
                value
            }
        }
    }

    public static onSocialBlur(id: string, value: string) {
        return {
            type: ON_SOCIAL_BLUR,
            payload: {
                id,
                value
            }
        }
    }

    public static setSocialValidators(validators: { [id: string]: Validator[] }) {
        return {
            type: SET_SOCIAL_VALIDATORS,
            payload: {
                validators
            }
        }
    }

    public static setSocialValues(values: { [id: string]: any }) {
        return {
            type: SET_SOCIAL_VALUES,
            payload: {
                values
            }
        }
    }

    public static setSocialSettings(socials: Social[]) {
        return {
            type: SET_SOCIAL_SETTINGS,
            payload: {
                socials
            }
        }
    }

    public static setSocialErrors(errors: { [id: string]: boolean }) {
        return {
            type: SET_SOCIAL_ERRORS,
            payload: {
                errors
            }
        }
    }

}