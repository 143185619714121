import React from "react";
import { keepBlank } from "../../constants/Constants";
import { FieldGroupHeader } from "../Common/FieldGroupHeader";
import ContactFieldContainer from "../../containers/Contact";
import { Contact as ContactProps } from "../../models/contacts/contact"


export class Contact extends React.PureComponent<ContactProps> {

    render() {
        const { id, contactFields, label, description } = this.props;
        return <div className="field-group-area">
            <FieldGroupHeader>{label}</FieldGroupHeader>
            <div className='field-description'>{description}</div>
            {contactFields.map(x => <ContactFieldContainer key={x.fieldId} {...x} contactId={id} />)}
            <ContactFieldContainer key={keepBlank} contactId={id} name={keepBlank} fieldName={keepBlank} required={false} />
        </div>;
    }
}