import { FootageAction } from "./actions";
import { ON_FOOTAGE_ADD, ON_FOOTAGE_REMOVE, SET_FOOTAGE_VALIDATORS, SET_FOOTAGE_VALUES, SET_FOOTAGE_SETTINGS, SET_FOOTAGE_ERRORS } from "./constants";
import { Validator } from "../../utilities/ValidatorUtility";
import { Footage } from "../../models/footage/footage";
import { FootageAttachment } from "../../models/footage/footage-attachment";
import { validate } from "../selectors";

export interface FootageState {
    footageValues: { [id: string]: FootageAttachment[] };
    footageErrors: { [id: string]: boolean };
    footageValidators: { [id: string]: Validator[] };
    footage: Footage[]
}

const initialState: FootageState = {
    footageValues: {},
    footageErrors: {},
    footageValidators: {},
    footage: []
}

export const footageReducer = (state: FootageState = initialState, action: FootageAction) => {
    switch (action.type) {
        case ON_FOOTAGE_ADD: {
            const newValue = [
                ...(state.footageValues[action.payload.id] || []),
                action.payload.footage
            ];
            return {
                ...state,
                footageValues: {
                    ...state.footageValues,
                    [action.payload.id]: newValue
                },
                footageErrors: {
                    ...state.footageErrors,
                    [action.payload.id]: validate(state.footageValidators[action.payload.id], newValue)
                }
            }
        }

        case ON_FOOTAGE_REMOVE: {
            const newValue = state.footageValues[action.payload.id].filter((x, i) => i !== action.payload.index);
            return {
                ...state,
                footageValues: {
                    ...state.footageValues,
                    [action.payload.id]: newValue
                },
                footageErrors: {
                    ...state.footageErrors,
                    [action.payload.id]: validate(state.footageValidators[action.payload.id], newValue)
                }
            }
        }

        case SET_FOOTAGE_VALIDATORS: {
            return {
                ...state,
                footageValidators: action.payload.validators
            }
        }

        case SET_FOOTAGE_VALUES: {
            return {
                ...state,
                footageValues: action.payload.values
            }
        }

        case SET_FOOTAGE_SETTINGS: {
            return {
                ...state,
                footage: action.payload.footage
            }
        }

        case SET_FOOTAGE_ERRORS: {
            return {
                ...state,
                footageErrors: action.payload.errors
            }
        }

        default: {
            return state;
        }
    }
}