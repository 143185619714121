import React from "react";
import { DatePicker, DatePickerChangeEvent } from '@progress/kendo-react-dateinputs';
import { FieldProps } from "../../models/fields/field-props";
import { FieldHeader } from "../Common/FieldHeader";
import { toDate } from '../../utilities/DateUtility';

export class DateTimeOffsetField extends React.PureComponent<FieldProps<Date | null> & { inline?: boolean }> {

    constructor(props: FieldProps<Date | null> & { inline?: boolean }) {
        super(props);
        this.onBlur = this.onBlur.bind(this);
        this.onChange = this.onChange.bind(this);
    }

    onBlur(event: React.FocusEvent<HTMLDivElement | HTMLSpanElement>) {
        setTimeout(() => {
            const { onBlur, id, value } = this.props;
            onBlur(id, toDate(value));
        })
       
    }

    onChange(event: DatePickerChangeEvent) {
        const { onChange, id } = this.props;
        onChange(id, toDate(event.target.value));
    }

    render() {
        const { name, required, value, valid, inline, description } = this.props;
        let dateValue = value ? new Date(value) : null;
        const inputClassName = inline ? 'col-10 col-lg-8' : 'col-md-12';
        return <React.Fragment>
            <FieldHeader required={required} inline={inline} name={name} description={description}/>
            <div className={inputClassName}>
                <div className="row">
                    <DatePicker className={inline ? "" : "w-100"}
                                onChange={this.onChange}
                                onBlur={this.onBlur}
                                value={dateValue}
                                popupSettings={{popupClass: 'disabled-navigation-sm'}}
                                valid={valid}/>
                </div>
            </div>
        </React.Fragment>;
    }
}
