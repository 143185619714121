import { ON_CONTACT_CHANGE, ON_CONTACT_BLUR, SET_CONTACT_VALIDATORS, SET_CONTACT_VALUES, SET_CONTACT_SETTINGS, SET_CONTACT_ERRORS } from "./constants";
import { Validator } from "../../utilities/ValidatorUtility";
import { Contact } from "../../models/contacts/contact";

export interface onContactChange {
    type: typeof ON_CONTACT_CHANGE;
    payload: {
        id: string;
        value: any;
    }
}

export interface onContactBlur {
    type: typeof ON_CONTACT_BLUR;
    payload: {
        id: string;
        value: any;
    }
}

export interface setContactValidators {
    type: typeof SET_CONTACT_VALIDATORS;
    payload: {
        validators: { [id: string]: Validator[] }
    }
}

export interface setContactValues {
    type: typeof SET_CONTACT_VALUES;
    payload: {
        values: { [id: string]: any }
    }
}

export interface setContactSettings {
    type: typeof SET_CONTACT_SETTINGS;
    payload: {
        contacts: Contact[]
    }
}

export interface setContactErrors {
    type: typeof SET_CONTACT_ERRORS;
    payload: {
        errors: { [id: string]: boolean }
    }
}

export type ContactAction = onContactChange | onContactBlur | setContactValidators | setContactValues | setContactSettings | setContactErrors;

export class ContactActions {

    public static onContactChange(id: string, value: any) {
        return {
            type: ON_CONTACT_CHANGE,
            payload: {
                id,
                value
            }
        }
    }

    public static onContactBlur(id: string, value: any) {
        return {
            type: ON_CONTACT_BLUR,
            payload: {
                id,
                value
            }
        }
    }

    public static setContactValidators(validators: { [id: string]: Validator[] }) {
        return {
            type: SET_CONTACT_VALIDATORS,
            payload: {
                validators
            }
        }
    }

    public static setContactValues(values: { [id: string]: any }) {
        return {
            type: SET_CONTACT_VALUES,
            payload: {
                values
            }
        }
    }

    public static setContactSettings(contacts: Contact[]) {
        return {
            type: SET_CONTACT_SETTINGS,
            payload: {
                contacts
            }
        }
    }

    public static setContactErrors(errors: { [id: string]: boolean }) {
        return {
            type: SET_CONTACT_ERRORS,
            payload: {
                errors
            }
        }
    }

}