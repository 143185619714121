// @ts-ignore
import { AvatarImage } from "~core";
import { ToastService } from "../services/ToastService";

const exif = require('exif-js') as any;
const MaxImageSize: number = 2 * 1048576; // 2MB


export enum ReadType {
    BLOB = 'blob',
    DATA_URL = 'dataUrl',
    BASE64 = 'base64'
}

export class ImageUtility {

    public static async uploadImage<T>(file: File, upload: (img: AvatarImage) => Promise<T>) {
        if (file.size > MaxImageSize) {
            return ToastService.error("Maximum size of upload avatar is 2MB.");
        }
        const normalizedImage = await ImageUtility.normalizeRotation(file);
        const formData = new FormData();
        formData.append('file', normalizedImage, file.name);
        return await upload({ file: formData, name: file.name });
    }

    public static async normalizeRotation(image: Blob) {
        const srcImgAsDataURL = await ImageUtility.read(image, ReadType.DATA_URL);
        const imgAsArrayBuffer = await ImageUtility.read(image, ReadType.BLOB);
        let tags = exif.readFromBinaryFile(imgAsArrayBuffer);
        if (!tags || tags.Orientation === null || tags.Orientation === undefined) {
            return image
        }

        const orientation = tags.Orientation;

        let imgElement = await ImageUtility.dataUrlToImage(srcImgAsDataURL);
        let width = imgElement.width;
        let height = imgElement.height;
        let canvas = document.createElement('canvas');
        let ctx = canvas.getContext('2d');

        if (ctx) {
            if (orientation > 4 && orientation < 9) {
                canvas.width = height;
                canvas.height = width;
            } else {
                canvas.width = width;
                canvas.height = height;
            }

            switch (orientation) {
                case 2: {
                    ctx.transform(-1, 0, 0, 1, width, 0);
                    break
                }
                case 3: {
                    ctx.transform(-1, 0, 0, -1, width, height);
                    break
                }
                case 4: {
                    ctx.transform(1, 0, 0, -1, 0, height);
                    break
                }
                case 5: {
                    ctx.transform(0, 1, 1, 0, 0, 0);
                    break
                }
                case 6: {
                    ctx.transform(0, 1, -1, 0, height, 0);
                    break
                }
                case 7: {
                    ctx.transform(0, -1, -1, 0, height, width);
                    break
                }
                case 8: {
                    ctx.transform(0, -1, 1, 0, 0, width);
                    break
                }
                default: break
            }
            ctx.drawImage(imgElement, 0, 0);
            const resultImg = await ImageUtility.canvasToBlob(canvas);
            (resultImg as any).name = (image as any).name;
            return resultImg;
        } else {
            return image;
        }
    };

    private static async canvasToBlob(canvas: HTMLCanvasElement) {
        return new Promise<Blob>((resolve) => {
            canvas.toBlob(result => {
                resolve(result as Blob);
            })
        })
    }

    private static async dataUrlToImage(src: string) {
        return new Promise<HTMLImageElement>((resolve, reject) => {
            const image = new Image();
            image.onload = () => resolve(image);
            image.onerror = reject;
            image.src = src;
        });
    }


    public static async read(file: any, readType: ReadType) {
        return new Promise<any>((resolve, reject) => {
            let reader = new FileReader();
            let preprocess: any = undefined;
            reader.onloadend = () => {
                let result = reader.result;
                if (preprocess) {
                    result = preprocess(result)
                }
                resolve(result)
            };
            reader.onerror = (err) => {
                reject(err)
            };
            switch (readType) {
                case ReadType.BLOB: {
                    reader.readAsArrayBuffer(file);
                    break
                }
                case ReadType.BASE64: {
                    preprocess = (result: string) => {
                        return result.substr(result.indexOf(',') + 1)
                    };
                    reader.readAsDataURL(file);
                    break
                }
                case ReadType.DATA_URL:
                default: {
                    reader.readAsDataURL(file);
                    break
                }
            }
        });
    }
}
