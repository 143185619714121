export class MetricUtility {

    public static maskHeight(value: string): (string | RegExp)[] {
        if (MetricUtility.unmask(value).length === 0) {
            return [/[3-8]/, '\'', ' '];
        }
        if (MetricUtility.unmask(value).length === 1) {
            return [/[3-8]/, '\'', ' '];
        } else if (MetricUtility.unmask(value).startsWith("8")) {
            // maximum height value is 8
            return [/[3-8]/, '\'', ' '];
        } else if (MetricUtility.unmask(value).length === 2) {
            return [/[3-8]/, '\'', ' ', /\d/, '"'];
        } else if (MetricUtility.unmask(value).length === 3) {
            if (parseInt(MetricUtility.unmask(value).substr(1, 1), 10) === 1) {
                return [/\d/, '\'', ' ', /[1]/, /[0-1]/, '"'];
            } else {
                return [/\d/, '\'', ' ', /\d/, '"'];
            }
        } else if (MetricUtility.unmask(value).length === 4) {
            return [/\d/, '\'', ' ', /[1]/, /[0-1]?/, '"'];
        }
        return [];
    }

    public static maskWeight(value: string): (string | RegExp)[] {
        if (MetricUtility.unmask(value).length === 0 || MetricUtility.unmask(value).length === 1) {
            return [/[1-9]/, ' ', 'l', 'b', 's'];
        } else if (MetricUtility.unmask(value).length === 2) {
            return [/[1-9]/, /[0-9]/, ' ', 'l', 'b', 's'];
        } else {
            return [/[1-9]/, /[0-9]/, /[0-9]/, ' ', 'l', 'b', 's'];
        }
    }

    public static unmask(value: string): string {
        return value.replace(new RegExp(/[^\d]/, 'g'), '');
    }

    static weightToNumber(weight: string) {
        if (weight) {
            return parseInt(weight, 10);
        }
        return null;
    }

    static heightToNumber(height: string) {
        if (height) {
            const unmaskedHeight = MetricUtility.unmask(height);
            const feet = parseInt(unmaskedHeight.substr(0, 1), 10);
            const inches = parseInt(unmaskedHeight.substr(1), 10) ? Math.round((parseInt(unmaskedHeight.substr(1), 10) / 12) * 1000) / 1000 : 0;
            return (feet + inches);
        }
        return null;
    }   

    public static convertHeight(value: number): number {
        const feet = Math.floor(value);
        let inch = ((value % 1) * 12.000);
        inch = Math.round(inch);
        if (inch < 10 && inch !== 1 && inch !== 0) {
            inch *= 10;
        } else if (inch === 1) {
            const res = feet * 10 + inch;
            return res;
        } else if (inch === 0) {
            return feet;
        }
        const result = feet * 100 + inch;
        return result;
    }

    public static readonly TIME_FORMAT = "000:00.00";

}