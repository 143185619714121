import * as React from 'react'
import '../../styles/components/FormIsNotAvailable.css'

const PageNotFound = () => {
        return (
                <div className="form-isnt-available">
                        <div className="logo-wrapper">
                                <img className="vector" alt="ARI Recruiting" src={require('../../images/vector.png')} />
                                <img className="logo" alt="ARI Recruiting" src={require('../../images/ari_logo.png')} />
                        </div>
                        <div className="content">
                                <h1>Page not found.</h1>
                                <p>Please contact the sender or owner for a new link.</p>
                        </div>
                </div>
        )
}

export default PageNotFound