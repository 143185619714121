export const ON_TAB_SELECT = '@ARI/Questionnaire/Questionnaire/ON_TAB_SELECT';
export const ON_LOAD_END = '@ARI/Questionnaire/Questionnaire/ON_LOAD_END';
export const SET_QUESTIONNAIRE = '@ARI/Questionnaire/Questionnaire/SET_QUESTIONNAIRE';
export const ON_SUBMIT_END = '@ARI/Questionnaire/Questionnaire/ON_SUBMIT_END';
export const POST_NEW = '@ARI/Questionnaire/Questionnaire/POST_NEW';
export const SET_TAB_LIST = '@ARI/Questionnaire/Questionnaire/SET_TAB_LIST';
export const ON_SUBMIT_STARTED = '@ARI/Questionnaire/Questionnaire/ON_SUBMIT_STARTED';
export const ON_SUBMIT_REJECTED = '@ARI/Questionnaire/Questionnaire/ON_SUBMIT_REJECTED';
export const SET_QUESTIONNAIRE_IS_AVAILABLE = '@ARI/Questionnaire/Questionnaire/SET_QUESTIONNAIRE_IS_AVAILABLE';
export const SET_QUESTIONNAIRE_ERRORS = '@ARI/Questionnaire/Questionnaire/SET_QUESTIONNAIRE_ERRORS';
export const ON_SMSSTATUS_BLUR = '@ARI/Questionnaire/Questionnaire/ON_SMSSTATUS_BLUR';
export const SHOW_CONFIRMATION_RESET_DIALOG = '@ARI/Questionnaire/SHOW_CONFIRMATION_RESET_DIALOG';
export const SHOW_DATA_CORRUPT_DIALOG = '@ARI/SHOW_DATA_CORRUPT_DIALOG';
export const CLOSE_DIALOGS = '@ARI/Questionnaire/CLOSE_DIALOGS';
export const SET_PRTIAL_UPDATE_STATUS = '@ARI/Questionnaire/SET_PARTIAL_UPDATE_STATUS';