import { FieldValue } from "../fields/field-value";
import { QuestionnaireSocial } from "./questionnaire-social";
import { FileAttachment } from "../../files/file-attachment";


export interface QuestionnaireRecruitCreate {
    fullName: string | undefined;
    questionnaireId: string;
    avatarId: string;
    bioFieldValues: FieldValue[];
    sportStatsFieldValues: FieldValue[];
    positions: string[];
    socialProfiles: QuestionnaireSocial[];
    files: FileAttachment[];
    submit: boolean;
    active: boolean;
    ConsentToReceiveSms: SmsSubscriptionStatus;
    recruitId?: string;
}

export enum SmsSubscriptionStatus {
    NotCaptured,
    Agreed,
    NotAgreed
}