import React from "react";
import { TabLink } from "./TabLink";
import { Tab } from "../../models/tab/tab";

export interface TabSelectorProps {
    selectedTab: number;
    color: string;
    tabList: Tab[];
    onSelectTab: (tab: number) => void;
}

export class TabSelector extends React.PureComponent<TabSelectorProps> {

    render() {
        const { onSelectTab, selectedTab, color, tabList } = this.props;
        return <div className="row">
            <div className="col-md-8 offset-md-2">
                <div className="row">
                    {tabList.map((value, index) =>
                        <TabLink
                            color={color}
                            key={value.title}
                            tabIndex={index}
                            onSelectTab={onSelectTab}
                            selected={selectedTab === index}>{value.title}</TabLink>)}
                </div>
            </div>
        </div>;
    }
}
