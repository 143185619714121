import React from "react";
import { Divider } from "../Common/Divider";
import { QuestionnaireService } from "../../services/QuestionnaireService";
import { TeamLogo } from "./TeamLogo";
import { MarkdownUtility } from '../../utilities/MarkdownUtility';

export interface QuestionnaireHeaderProps {
    title: string;
    teamName: string;
    introduction: string;
    submissionMessage: string;
    color2: string;
    color3: string;
    logoId: string;
    submited: boolean;
    onPostNew: () => void;
}

export class QuestionnaireHeader extends React.PureComponent<QuestionnaireHeaderProps> {

    getImageUrl(logoId: string): string | undefined {
        return logoId
            ? QuestionnaireService.getAvatarThumbnailUrl(logoId)
            : undefined;
    }

    render() {
        const {
            title,
            teamName,
            introduction,
            color2,
            color3,
            logoId,
            submited,
            submissionMessage
        } = this.props;
        const imageUrl = this.getImageUrl(logoId);
        const parsedSubmissionMessage = !!submissionMessage ? MarkdownUtility.parseLinkMarkdown(submissionMessage) : undefined;
        const parsedIntroduction = !!introduction ? MarkdownUtility.parseLinkMarkdown(introduction) : '';
        const questionnaireHeader = title && title.length ? title : teamName;
        return <section className="header-section">
            <Divider color={color2} />
            <div className="header-area" style={{ backgroundColor: color3 }}>
              <div className="container">
                 <div className="row header-row">
                     <div className="col-md-8 offset-md-2">
                         <TeamLogo imageUrl={imageUrl} />
                         <div className="header-wrap forms-header">
                             <h1>{questionnaireHeader}</h1>
                             {!submited
                                 ? <div className="textbox" dangerouslySetInnerHTML={{ __html: parsedIntroduction }}></div>
                                 :!!parsedSubmissionMessage ?
                                     <div className="textbox" dangerouslySetInnerHTML={{ __html: parsedSubmissionMessage }} ></div>
                                     : <div className="textbox">Thank you for submitting your questionnaire!</div>
                             }
                         </div>
                     </div>
                 </div>
                 {this.props.children}
              </div>
            </div>
        </section>;
    }
}