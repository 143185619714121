import { connect } from 'react-redux';
import { StoreState } from "../../store/configureStore";
import { Field } from '../../models/fields/field';
import { BioFieldList } from '../../components/Bio/BioFieldList';

const mapStateToProps = (state: StoreState, ownProps: any) => {
    const fields = state.bioFields.bioFields.reduce((prev, current) => [...prev, ...current.fields ] , [] as Field[]);
    const field = fields.find(x => x.id === ownProps.fieldId);

    return {
        ...ownProps,
        fields: [ field ],
        }
    }
    
const QuestionnaireBioContainer = connect(mapStateToProps)(BioFieldList);

export default QuestionnaireBioContainer;