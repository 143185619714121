import { ON_BIO_FIELD_CHANGE, ON_BIO_FIELD_BLUR, SET_BIO_FIELD_VALIDATORS, SET_BIO_FIELD_VALUES, SET_BIO_FIELD_SETTINGS, SET_BIO_FIELD_ERRORS, ON_COUNTRY_FIELD_CHANGE } from "./constants";
import { Validator } from "../../utilities/ValidatorUtility";
import { FieldGroup } from "../../models/fields/field-group";


export interface onBioFieldChange {
    type: typeof ON_BIO_FIELD_CHANGE;
    payload: {
        id: string;
        value: any;
    }
}

export interface onBioFieldBlur {
    type: typeof ON_BIO_FIELD_BLUR;
    payload: {
        id: string;
        value: any;
    }
}

export interface onCountryFieldChange {
    type: typeof ON_COUNTRY_FIELD_CHANGE;
    payload: {
        id: string;
        value: any;
    }
}

export interface setBioFieldValidators {
    type: typeof SET_BIO_FIELD_VALIDATORS;
    payload: {
        validators: { [id: string]: Validator[] }
    }
}

export interface setBioFieldValues {
    type: typeof SET_BIO_FIELD_VALUES;
    payload: {
        values: { [id: string]: any }
    }
}

export interface setBioFieldSettings {
    type: typeof SET_BIO_FIELD_SETTINGS;
    payload: {
        groups: FieldGroup[]
    }
}

export interface setBioFieldErrors {
    type: typeof SET_BIO_FIELD_ERRORS;
    payload: {
        errors: { [id: string]: boolean }
    }
}

export type BioFieldAction = onBioFieldChange | onBioFieldBlur | setBioFieldValidators | setBioFieldValues | setBioFieldSettings | setBioFieldErrors | onCountryFieldChange;

export class BioFieldActions {

    public static onBioFieldChange(id: string, value: any) {
        return {
            type: ON_BIO_FIELD_CHANGE,
            payload: {
                id,
                value
            }
        }
    }

    public static onBioFieldBlur(id: string, value: any) {
        return {
            type: ON_BIO_FIELD_BLUR,
            payload: {
                id,
                value
            }
        }
    }

    public static onCountryFieldChange(id: string, value: any) {
        return {
            type: ON_COUNTRY_FIELD_CHANGE,
            payload: {
                id,
                value
            }
        }
    }

    public static setBioFieldValidators(validators: { [id: string]: Validator[] }): setBioFieldValidators {
        return {
            type: SET_BIO_FIELD_VALIDATORS,
            payload: {
                validators
            }
        }
    }

    public static setBioFieldValues(values: { [id: string]: any }) {
        return {
            type: SET_BIO_FIELD_VALUES,
            payload: {
                values
            }
        }
    }

    public static setBioFieldSettings(groups: FieldGroup[]): setBioFieldSettings {
        return {
            type: SET_BIO_FIELD_SETTINGS,
            payload: {
                groups
            }
        }
    }

    public static setBioFieldErrors(errors: { [id: string]: boolean }) {
        return {
            type: SET_BIO_FIELD_ERRORS,
            payload: {
                errors
            }
        }
    }
}