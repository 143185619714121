import React from "react";
import { CommonFieldSelector } from "../Fields/CommonFieldSelector";
import { FieldProps } from "../../models/fields/field-props";

export class SportStatsFieldSelector extends React.PureComponent<FieldProps<any>> {

    render() {
        const { show } = this.props;
        return <React.Fragment>{show && <CommonFieldSelector {...this.props} />}</React.Fragment>;
    }
}