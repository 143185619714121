import React from "react";
import { FieldGroupHeader } from "../Common/FieldGroupHeader";
import { Social } from "../../models/socials/social";
import SocialContainer from "../../containers/Social";
import { discord, twitter } from "../../constants/Constants";

export interface SocialListProps {
    socials: Social[];
    groupName: string;
    groupDescription: string;
}

export class SocialList extends React.Component<SocialListProps> {

    isNicknameRequired(socialId: string) {
        return twitter === socialId || discord === socialId
    }

    render() {
        const { socials, groupName, groupDescription } = this.props;
        return <div className="field-group-area">
            {socials.length > 0 && <FieldGroupHeader>{groupName}</FieldGroupHeader>}
            { groupDescription && groupDescription.length >0 && <label className="field-description">{groupDescription}</label> }
            {socials.map(s => <SocialContainer key={s.title} {...s} isNicknameRequired={this.isNicknameRequired(s.title)} />)}
        </div>;
    }
}