import React from "react";

export interface TeamLogoProps {
    imageUrl?: string;
}

export class TeamLogo extends React.PureComponent<TeamLogoProps> {

    render() {
        const imageUrl = this.props.imageUrl;
        return <div className="team-logo">{!!imageUrl && <img src={imageUrl} alt="" />}</div>
    }
}