import * as React from 'react';
import '../../styles/components/UploadForm/Layout.css'
import {
  Form,
  FormElement,
  Field,
  FieldRenderProps,
  FormRenderProps,
} from "@progress/kendo-react-form";
import { Input } from "@progress/kendo-react-inputs";
import {
  Error
} from "@progress/kendo-react-labels";
import { UploadService } from '../../services/UploadService';
import SuccessUpload from './SuccessUpload';
import { useRef, useState } from 'react';
import { FormFile } from './FormFile';
import { FieldWrapper } from '../Common/FieldWrapper';

export interface IKendoOnChangeEvent {
  target?: any;
  value?: any;
}

const UploadForm = () => {
    const [submit, setSubmit] = React.useState<boolean>(false);
    const [valid, setValid] = React.useState<boolean>(false);
    const fileRef = useRef<HTMLInputElement | null>(null)
    const [chosenFiles, setChosenFiles] = useState<File[]>([])
    const [preloadedFile, setPreloadedFile] = useState<string>()
    const formRef = useRef<Form>(null)
    const MAX_FILE_SIZE = Math.pow(2, 20) * 25 // 25 mb

    const emailRegex: RegExp = new RegExp(/\S+@\S+\.\S+/);
    const fileRegex: RegExp = new RegExp(/\.(xlsx|xls|csv)/g, "i");

    const nameValidator = (value: string) => {
      return value && value.length ? "" : "The Name field is required"
    }

    const emailValidator = (value: string) =>
      emailRegex.test(value) ? "" : "The Email field is required";

    const TextInput = (fieldRenderProps: FieldRenderProps) => {
      const { validationMessage, visited, ...others } = fieldRenderProps;
      return (
        <div className="k-form-field-wrap">
          <Input {...others} className={"k-form-label"} />
          {visited && formRef.current.errors[fieldRenderProps.name] && 
            <div className="validation-text">
              <Error>
                {formRef.current.errors[fieldRenderProps.name]}
              </Error>
            </div>
            }
        </div>
      );
    }

    function checkExtension(value: File[]){
      const isExcelExt = value.every(el => fileRegex.test(el.name))
      return isExcelExt && value.length ? '' : 'The file must be in XLSX or CSV format.'
    }

    const innerValidateFile = () => {
      if (chosenFiles.length && chosenFiles[0].size) {
        return chosenFiles[0].size <= MAX_FILE_SIZE ? checkExtension(chosenFiles) 
          : 'The file must be in XLSX or CSV format. Size limit is 25 MB';
      }
      let res = chosenFiles[0] === undefined ? 'File is required' : '';
      return res;
    }

    const onFileSelect = () => {
      fileRef.current.click()
    }

    const onFileChange = (event: IKendoOnChangeEvent) => {
      const fileUploaded = event.value
      setChosenFiles([...fileUploaded])
      if (preloadedFile)
        setPreloadedFile('')
    }

    const handleSubmit = async (dataItem: { [name: string]: any }) => {
      const file = chosenFiles[0];
        if (!file) {
          setValid(false);
        } else {
          const formData = new FormData();
          formData.append('file', file.name);
          const res = await prepareData(file, (data, name) => UploadService.uploadFile(data, name));
          if (res) {
            setValid(true);
            UploadService.uploadForm(res.id, dataItem["Name"], dataItem["Email"], res.fileName);
            setSubmit(true);
          }
        }
    }

    async function prepareData<T>(file: File, upload: (data: FormData, name: string) => Promise<T>) {
      const formData = new FormData();
      formData.append('file', chosenFiles[0]);
      return await upload(formData, file.name);
    }

    const checkErrors = (form: any): boolean => {
      if (!form.visited) {
        return true;
      }
      if (Object.values(form.errors).length === 0) {
        return true
      }
      return Object.values(form.errors)[0] === "" && Object.values(form.errors)[1] === "";
    }

    return (
        <div className="layout-upload">
            <div className="wrapper-upload">
              <div className="logo-wrapper-upload">
                <img className="" alt="ARI Recruiting" 
                  src={require('../../images/ari_logo.svg')} />
              </div>
              <div className="form-wrapper">
                {submit ? (
                  <SuccessUpload />
                  ) : (
                  <Form
                    onSubmit={handleSubmit}
                    ref={formRef}
                    render={(formRenderProps: FormRenderProps) => (
                      <FormElement>
                          <div className="content-upload">
                        <fieldset className={"k-form-fieldset form-upload"}>
                          <legend className={"k-form-legend"}>
                              Please complete the form below to submit your import file for review.
                              <p className="legend-text">All fields are required.<br />
                                There is a 25MB size limit on the uploaded file. XLSX or CSV format.</p>
                          </legend>
                          <FieldWrapper>
                            <Field
                              name={"Name"}
                              validator={nameValidator}
                              component={TextInput}
                              className={"k-form-label input-upload"}
                              label={"Name"}
                              placeholder={"Name"}
                            />
                          </FieldWrapper>
                          <FieldWrapper>
                            <Field
                              name={"Email"}
                              type={"email"}
                              validator={emailValidator}
                              component={TextInput}
                              className={"k-form-label input-upload"}
                              label={"Email"}
                              placeholder={"Email"}
                            />
                          </FieldWrapper>
                          <div className="upload-selector">
                            <fieldset className={"k-form-fieldset"}>
                              <div className="upload-selector-text">
                                <Field
                                name="File"
                                component={FormFile}
                                className="upload-component"
                                btnText="Select File..."
                                inputRef={fileRef}
                                onFileSelect={onFileSelect}
                                onChange={onFileChange}
                                validator={innerValidateFile}
                                valid={valid}
                              />
                              </div>
                            </fieldset>
                          </div>
                        </fieldset>
                        </div>
                        <div className="submit-button">
                          <button
                            type={"submit"}
                            className="k-button k-button-md k-rounded-md 
                              k-button-solid k-button-solid-base"
                            disabled={!checkErrors(formRenderProps) || !formRenderProps.allowSubmit}
                          >
                            Submit
                          </button>
                        </div>
                        </FormElement>
                    )}
                  />
                )}
              </div>
            </div>
        </div>
    );
}

export default UploadForm;