import React from "react";
import { DateTimeOffsetField } from "./DateTimeOffsetField";
import { TimeResultField } from "./TimeResultField";
import { FieldProps } from "../../models/fields/field-props";
import { FieldHeader } from "../Common/FieldHeader";

const fieldIds = {
    time: 'time',
    date: 'date'
};

export class TimeDateField extends React.PureComponent<FieldProps<{ time: string, date: Date | null }>> {

    constructor(props: FieldProps<{ time: string, date: Date | null }>) {
        super(props);
        this.onBlur = this.onBlur.bind(this);
        this.onChange = this.onChange.bind(this);
    }

    onBlur(fieldId: string, fieldValue: any) {
        const { onBlur, value, id } = this.props;
        let newValue = {...value, [fieldId]: fieldValue};
        onBlur(id, newValue);
    }

    onChange(fieldId: string, fieldValue: any) {
        const { onChange, value, id } = this.props;
        let newValue = {...value, [fieldId]: fieldValue};
        onChange(id, newValue);
    }

    render() {
        const { name, required, valid } = this.props;
        let value = this.props.value ? this.props.value : { time: '', date: null };
        value.date = value.date ? new Date(value.date) : null;
        return <React.Fragment>
            <FieldHeader required={required} complex={true} name={name} />
            <div className="row">
              <div className="inline-field col-md-12 col-lg-8">
                 <TimeResultField
                    name="Time"
                    id={fieldIds.time}
                    required={false}
                    valid={valid}
                    inline={true}
                    onBlur={this.onBlur}
                    onChange={this.onChange}
                    value={value.time} />
             </div>
             <div className="inline-field col-md-12 col-lg-4">
                <DateTimeOffsetField
                    name="Date"
                    id={fieldIds.date}
                    required={false}
                    inline={true}
                    valid={valid}
                    onBlur={this.onBlur}
                    onChange={this.onChange}
                    value={value.date} />
             </div>
            </div>
        </React.Fragment>;
    }
}
