import React, { PureComponent } from "react";
import { InstitutionSearchItem } from "../../models/institution/institution-search-item";

export interface InstitutionSearchResultEmptyProps {
    searchResults: InstitutionSearchItem[],
    inputValue: string,
    loading: boolean,
    showInstitutionCustom: (id: string) => void;
    id: string;
    selectItem: () => void;
}

export class InstitutionSearchResultEmpty extends PureComponent<InstitutionSearchResultEmptyProps> {

    constructor(props) {
        super(props);
        this.showOtherInstitution = this.showOtherInstitution.bind(this);
    }

    showOtherInstitution() {
        const { showInstitutionCustom, id, selectItem } = this.props;
        showInstitutionCustom(id);
        selectItem();
    }

    render() {
        const { inputValue, searchResults, loading } = this.props;
        return (
            <React.Fragment>
                {inputValue.length === 0 && <div className="no-search-result">Please enter a search term</div>}
                {(inputValue.length > 0 && searchResults.length === 0 && !loading) && <div className="no-search-result">
                    <React.Fragment>
                        <span>Organization Not Found.&nbsp;</span>
                        <span className="add-manually-label" onClick={this.showOtherInstitution}>Click to add manually</span>
                    </React.Fragment>
                </div>
                }
                {inputValue.length > 0 && searchResults.length === 0 && loading && <div className="loading-container w-100 skeleton"></div>}
            </React.Fragment>
        );
    }
}