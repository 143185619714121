import React, { useEffect} from "react";
import { PagerLink } from "./PagerLink";
import { SubmitLink } from "./SubmitLink";
import { Tab } from "../../models/tab/tab";
import { PagerLinkType } from "../../models/tab/pager-link-type";
import { ResetLink } from "./ResetLink";
import { usePromiseTracker } from 'react-promise-tracker';

export interface PagerProps {
    selectedTab: number;
    tabList: Tab[];
    onSelectTab: (tab: number) => void;
    onSubmit: () => void;
    onShowResetDialog: () => void;
    setPartialUpdateStatus: (partialUpdateInProgress: boolean) => void;
    status: string;
}

export const Pager = (props: PagerProps) => { 
        const { selectedTab, tabList, onSelectTab, onSubmit, status, onShowResetDialog, setPartialUpdateStatus } = props;
        const { promiseInProgress } = usePromiseTracker();

        useEffect(() => {
          setPartialUpdateStatus(promiseInProgress);
        }, [setPartialUpdateStatus, promiseInProgress])

        return <div className="nav-link-wrap">
            {selectedTab === tabList.length - 1 && 
                <div className="d-flex">
                    <ResetLink status={status} onShowResetDialog={onShowResetDialog} />
                    <SubmitLink onSubmit={onSubmit} status={status} />
                </div>
            }
            {selectedTab !== tabList.length - 1 && tabList.length !== 1 &&
                <PagerLink
                    onSelectTab={onSelectTab}
                    selectedTab={selectedTab}
                    type={PagerLinkType.Next}>
                    <span>Next page</span><span className="fa fa-arrow-right"></span>
                </PagerLink>}
            {selectedTab !== 0 &&
                <PagerLink
                    onSelectTab={onSelectTab}
                    selectedTab={selectedTab}
                    type={PagerLinkType.Prev}>
                    <span className="fa fa-arrow-left"></span><span>Previous page</span>
                </PagerLink>}
        </div>
    };