import React from "react";


export interface AvatarMenuProps {
    avatarId: string;
    onDelete: (e: React.MouseEvent<HTMLInputElement>) => void;
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

export class AvatarMenu extends React.Component<AvatarMenuProps> {

    render() {
        return <div className="avatar-menu-container">
            <div className="avatar-menu">
                <label htmlFor="file" className="pl-2  pr-2 pb-2 m-0 avatar-menu-item w-100">
                    <i className="fas fa-upload mr-1"></i>
                    <span>Upload photo</span>
                </label >
                <input onChange={this.props.onChange} hidden type="file" id="file" />
                {!!this.props.avatarId &&
                    <div onClick={this.props.onDelete} className="pl-2 pr-2 avatar-menu-item" ><i className="fas fa-times mr-1"></i> <span>Remove</span></div >
                }
            </div >
        </div >
    }
}