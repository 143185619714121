import * as React from 'react';
import Overlay from './Overlay';
import Spinner, {Props as SpinnerProps} from './Spinner';

export interface Props extends React.Props<Props>, SpinnerProps {
    display: boolean
}

const OverlaySpinner: React.FC<Props> = (props: Props) => {
    const {display} = props;
    const style: React.CSSProperties = {
        width: '100%',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    }
    return <Overlay display={display}>
        <div style={style}>
            <Spinner {...props}/>
        </div>
    </Overlay>
}

export default OverlaySpinner;