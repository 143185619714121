import React, { PureComponent } from "react";
import { InstitutionCategory } from "../../models/institution/institution-category";
import { InstitutionsValueProps } from '../../containers/Institutions/reducers';
import { InstitutionHeader } from './InstitutionHeader';
import { InstitutionInputWrap } from "./InsitutionInputWrap";

export interface InstitutionItemProps extends InstitutionCategory {
    onChangeCustomInput: (id: string, value: string) => void,
    onBlurCustomInput: (id: string, value: string) => void,
    onSwitchInstitutionInput: (id: string, value: boolean) => void,
    setCategory: (id: string, value: string, name: string) => void,
    searchInstitutionsByTerm: (id: string, value: string, categoryId: string, page: number, pageSize: number) => void,
    showInstitutionCustom: (id: string) => void,
    clearSearchResults: (id: string) => void,
    setLoadingSearchResults: (id: string, value: boolean) => void,
    setUpInstitutionSearchName: (id: string, value: string) => void,
    setNextSearchResultValue: (id: string, value: number) => void,
    value: InstitutionsValueProps
}

export class InstitutionItem extends PureComponent<InstitutionItemProps> {

    constructor(props: InstitutionItemProps) {
        super(props);
    }

    render() {
        const { name, onSwitchInstitutionInput, value, id } = this.props;
        const { showRadioInstitutionOptions, description, useCumtomInstitutionInput } = this.props.value;
        return (
            <div className="form-group">
                <InstitutionHeader id={id} onSwitchInstitutionInput={onSwitchInstitutionInput} isRequired={value.isRequired} institutionCaterotyName={name}
                    showRadioInstitutionOptions={showRadioInstitutionOptions} useCumtomInstitutionInput={useCumtomInstitutionInput} description={description} />
                <InstitutionInputWrap {...this.props} />
            </div>
        );
    }
}