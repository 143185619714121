import React from "react"
import { QuestionnaireStatus } from "../../constants/Constants";

export interface ResetLinkProps {
    onShowResetDialog: () => void;
    status: string
}

export const ResetLink = (props: ResetLinkProps) => {
    return <React.Fragment>
    { props.status === QuestionnaireStatus.Preview ? null : <a href="#!" className="nav-link mr-3" onClick={props.onShowResetDialog}><span>Reset</span></a> }
</React.Fragment>;
}