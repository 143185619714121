import React from 'react';
import 'react-toastify/dist/ReactToastify.css';
import { Unsubscribe } from './components/Unsubscribe/Unsubscribe';


const UnsubscribeApp: React.FC = () => {
    return (
        <React.Fragment>
            <Unsubscribe />
        </React.Fragment>
    );
}

export default UnsubscribeApp;
