import React from "react";
import { ShortStringField } from "./ShortStringField";
import { DateTimeOffsetField } from "./DateTimeOffsetField";

const fieldIds = {
    title: 'title',
    date: 'date'
};

export interface EventItemFieldProps {
    value: { title: string, date: Date | null };
    index: number;
    onRemove: (index: number) => void;
    valid: boolean;
    onBlur: (index: number, value: any) => void;
    onChange: (index: number, value: any) => void;
}

export class EventItemField extends React.PureComponent<EventItemFieldProps> {
    constructor(props: EventItemFieldProps) {
        super(props);
        this.onBlur = this.onBlur.bind(this);
        this.onChange = this.onChange.bind(this);
        this.onRemove = this.onRemove.bind(this);
    }

    onBlur(id: string, fieldValue: any) {
        const { onBlur, value, index } = this.props;
        let newValue = {...value, [id]: fieldValue};
        onBlur(index, newValue);
    }

    onChange(id: string, fieldValue: any) {
        const { onChange, value, index } = this.props;
        let newValue = {...value, [id]: fieldValue};
        onChange(index, newValue);
    }

    onRemove(event: React.MouseEvent<HTMLAnchorElement>) {
        const { index, onRemove } = this.props;
        onRemove(index);
    }

    render() {
        const { valid, value } = this.props;
        return <React.Fragment>
            <div className="row">
              <div className="inline-field col-md-12 col-lg-8">
                 <ShortStringField
                     id={fieldIds.title}
                     name="Title"
                     inline={true}
                     event={true}
                     required={false}
                     valid={valid}
                     value={value.title}
                     onBlur={this.onBlur}
                     onChange={this.onChange}>
                 </ShortStringField>
             </div>
             <div className="inline-field col-md-12 col-lg-4">
                             <DateTimeOffsetField
                                 id={fieldIds.date}
                                 name="Date"
                                 inline={true}
                                 required={false}
                                 valid={valid}
                                 value={value.date}
                                 onBlur={this.onBlur}
                                 onChange={this.onChange} />
              </div>
              <div className="tournament-remove">
                         <a href="#!" onClick={this.onRemove} className="tournament-remove"><span className="k-icon k-i-close"></span></a>
                     </div>
            </div>
        </React.Fragment>;
    }
}
