import React, { PureComponent, ChangeEvent } from 'react';
import { Input } from "@progress/kendo-react-inputs";
import { InstitutionItemProps } from './InstitutionItem';

export class OtherInstitutionInput extends PureComponent<InstitutionItemProps> {

    constructor(props: InstitutionItemProps) {
        super(props);
        this.onCustomInputChange = this.onCustomInputChange.bind(this);
        this.onCustomInputBlur = this.onCustomInputBlur.bind(this);
    }

    componentDidMount(): void {
        const { id, onChangeCustomInput, setUpInstitutionSearchName } = this.props
        const { otherInstitution, institutionSearchName } = this.props.value;
        if (!otherInstitution && !!institutionSearchName) {
            onChangeCustomInput(id, institutionSearchName);
            setUpInstitutionSearchName(id, '');
        }
    }

    onCustomInputChange(e: React.ChangeEvent<HTMLInputElement>) {
        const { onChangeCustomInput, id } = this.props;
        const target = e.target;
        onChangeCustomInput(id, target.value);
    }

    onCustomInputBlur(e: React.ChangeEvent<HTMLInputElement>) {
        const { onBlurCustomInput, id } = this.props;
        const target = e.target;
        onBlurCustomInput(id, target.value);
    }

    render() {
        const { otherInstitution, isRequired, valid } = this.props.value;
        return (
            <Input type="text" className={`k-textbox ${isRequired ? 'required' : ''} w-100`} value={otherInstitution || ''} valid={valid} onChange={this.onCustomInputChange} onBlur={this.onCustomInputBlur} />
        )
    }

}