import React from "react";
import '../../styles/components/TabLink.css'

export interface TabLinkProps {
    selected: boolean;
    color: string;
    tabIndex: number;
    onSelectTab: (tab: number) => void;
}

export class TabLink extends React.PureComponent<TabLinkProps> {

    constructor(props: TabLinkProps) {
        super(props);
        this.onSelectTab = this.onSelectTab.bind(this);
    }

    onSelectTab() {
        const { tabIndex, onSelectTab } = this.props;
        onSelectTab(tabIndex);
    }


    render() {
        const {
            children,
            selected,
            color
        } = this.props;
        const className = `btn tab-link col-md ` + (selected ? 'active' : '') ;
        const style: React.CSSProperties = selected ? { borderTopColor: color } : {};
        return <button className={className} style={style} onClick={this.onSelectTab}>{children}</button>
    }
}
