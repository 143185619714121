import React from "react";
import { EventItemField } from "./EventItemField";
import { FieldProps } from "../../models/fields/field-props";
import { FieldHeader } from "../Common/FieldHeader";

export class EventsField extends React.PureComponent<FieldProps<{ title: string, date: Date | null }[]>> {

    private newId = 0;

    constructor(props: FieldProps<{ title: string, date: Date | null, id: number }[]>) {
        super(props);
        this.onAdd = this.onAdd.bind(this);
        this.onRemove = this.onRemove.bind(this);
        this.onChange = this.onChange.bind(this);
        this.onBlur = this.onBlur.bind(this);
    }

    getNewId(): number {
        return ++this.newId;
    }

    onAdd(event: React.MouseEvent<HTMLAnchorElement>) {
        const { onBlur, id } = this.props;
        let value: any[] = this.props.value
            ? this.props.value
            : [{ title: '', date: null, id: this.getNewId() }];
        onBlur(id, value.concat({ title: '', date: null }));
    }

    onRemove(index: number) {
        const { onBlur, id } = this.props;
        let value: any[] = this.props.value
            ? this.props.value
            : [{ title: '', date: null, id: this.getNewId() }];
        value.splice(index, 1);
        onBlur(id, value.filter(x => true));
    }

    onChange(index: number, newValue: { title: string, date: Date | null }) {
        const { value, onChange, id } = this.props;
        let arrayValue = !!value ? [...value] : [];
        arrayValue[index] = newValue;
        onChange(id, arrayValue);
    }

    onBlur(index: number, newValue: { title: string, date: Date | null }) {
        const { value, onBlur, id } = this.props;
        let arrayValue = !!value ? [...value] : [];
        arrayValue[index] = newValue;
        onBlur(id, arrayValue);
    }

    render() {
        const { name, required, valid, description } = this.props;
        let value: any[] = this.props.value
            ? this.props.value
            : [{ title: '', date: null, id: this.getNewId() }];
        return <React.Fragment>
            <div className="row">
                <div className="col-md-12">
                    <FieldHeader required={required} complex={true} name={name} description={description} />
                </div>
            </div>
            <div className="row">
                <div className="col-md-12">
                    {value.map((x, i) => <EventItemField key={i}
                        index={i}
                        value={x}
                        onRemove={this.onRemove}
                        onChange={this.onChange}
                        onBlur={this.onBlur}
                        valid={valid} />)}
                    <a href="#!" className="btn filter-plus p-0" onClick={this.onAdd}>+ Add New Tournament</a>
                </div>
            </div>
        </React.Fragment>;
    }
}
