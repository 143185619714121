import React from "react";
import { ShortStringField } from "./ShortStringField";
import { FieldProps } from "../../models/fields/field-props";

export class TimeResultField extends React.PureComponent<FieldProps<string>> {

    render() {
        return <ShortStringField {...this.props} maxLength={10} />
    }
}
