import React from "react";
import { FileAttachment } from "../../models/files/file-attachment";

export interface AttachmentProps {
    attachment: FileAttachment;
    onRemove: (id: string) => void;
}

export class Attachment extends React.PureComponent<AttachmentProps> {

    constructor(props: AttachmentProps) {
        super(props);
        this.onRemove = this.onRemove.bind(this);
    }

    onRemove() {
        const { onRemove, attachment } = this.props;
        onRemove(attachment.uid);
    }

    render() {
        const { attachment } = this.props;
        return <div className="bg-white mb-2 pt-2 pb-2 pl-3 pr-3">
            <div className="ellipsis-attachment-title">
                <span>{attachment.title}</span>
            </div >
            <a href="#!" onClick={this.onRemove}><i className="far fa-trash-alt text-muted ml-2 float-right file-remove"></i></a>
            <a href="#!"><i className="fa fa-download text-muted ml-2 mt-2 float-right"></i></a>
            <span className="text-muted float-right file-weight" >{(attachment.size / 1024).toFixed(2)} KB</span>
        </div>
    }
}