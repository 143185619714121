import React from 'react';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import QuestionnaireContainer from './containers/Questionnaire';
import { Provider } from 'react-redux';
import { configureStore } from './store/configureStore';


export const store = configureStore();

const App: React.FC = () => {
    return (<React.Fragment>
        <Provider store={store}>
                <QuestionnaireContainer />
        </Provider>
        <ToastContainer autoClose={false} />
    </React.Fragment>
    );
}

export default App;
