import React from "react";
import { QuestionnaireStatus } from "../../constants/Constants";
import { usePromiseTracker } from 'react-promise-tracker';


export interface SubmitLinkProps {
    onSubmit: () => void;
    status: string;
}

export const SubmitLink = (props: SubmitLinkProps) => {

    const { status, onSubmit } = props
    const { promiseInProgress } = usePromiseTracker();
    return <React.Fragment>
        {status === QuestionnaireStatus.Preview
            ? null
            : !promiseInProgress ? <a href="#!" className="nav-link"  onClick={onSubmit}><span>Submit</span></a> : <span  className="nav-link disable-submit">Submit</span>}
        </React.Fragment>;
    
}