import React from "react";


export interface FieldHeaderProps {
    required?: boolean;
    inline?: boolean;
    complex?: boolean;
    name: string;
    description?: string;
    for?: string;
}


export class FieldHeader extends React.PureComponent<FieldHeaderProps> {

    render() {
        const { required, inline, complex, name, description } = this.props;
        const className = 'field-header' + (complex ? ' field-complex-header' : '') +
            (required ? ' require' : '') + (inline ? ' inline-label' : '');
        return (<React.Fragment>
            <label className={className} htmlFor={this.props.for}>{name}</label><br/>
            { description && description.length && <label className="field-description" htmlFor={this.props.for}>{description}</label> }
            </React.Fragment>);
    }
    
}
