import { ActionCreator, AnyAction, Dispatch } from "redux";
import { ThunkAction } from "redux-thunk";
import { StoreState } from "../store/configureStore";
import { HttpErrorCode, PartialUpdateTypes, QuestionnaireStatus, smsStatusFieldId } from "../constants/Constants";
import { QuestionnaireView } from "../models/api-models/questionnaire/questionnaire-view";
import { QuestionnaireService } from "../services/QuestionnaireService";
import { QuestionnaireActions } from "./Questionnaire/actions";
import {
    getContacts, getSocials,
    getFiles, getFields, getContactValidators, getFootage,
    getSocialValidators, getFileValidators,
    getFieldValidators, validateAll,  validateContacts, getSocialValues, getCheckedStats as getRequiredStats,
    getFileValues, getValidContactValue,  getValidSportStatsValues as getValidSportStatsValue, getInstitutionRelations,
    getValidBioValues as getValidBioValue, getFootageValues, getFootageValidator, getInstitutionFields, getInstitutionsValidators, 
    getAllBioValues,
    getAllValidBioValues,
    getAllValidSportStatsValues,
    getAllValidContactValues} from "./selectors";
import { ContactActions } from "./Contact/actions";
import { SocialActions } from "./Social/actions";
import { FileActions } from "./FileUpload/actions";
import { BioFieldActions } from "./BioField/actions";
import { SportStatsFieldActions } from "./SportStatsField/actions";
import { QuestionnaireRecruit } from "../models/questionnaire/questionnaire-recruit";
import { AvatarUploadActions } from "./AvatarUpload/actions";
import { ImageUtility } from "../utilities/ImageUtility";
import { from, Subject, EMPTY, of, interval } from "rxjs";
import { SmsSubscriptionStatus } from "../models/api-models/questionnaire/questionnaire-recruit-create";
import { flatMap, map, catchError, switchMap, takeUntil, debounce } from "rxjs/operators";
import { FootageActions } from "./FootageUpload/actions";
import { InstitutionsActions } from './Institutions/actions';
import { FieldValueType } from "../models/api-models/fields/field-value-type";
import { FieldValue } from "../models/api-models/fields/field-value";
import { Field } from "../models/fields/field";
import { QuestionnaireItemType } from "../models/api-models/questionnaire/questionnaire-item-type";
import { QuestionnaireUtility } from "../utilities/QuestionnaireUtility";
import { PartialUpdateRecruit } from "../models/api-models/questionnaire/partial-update/partal-update";
import { PartialUpdateInfo } from "../models/partial-update/partial-update-info";
import { FieldGroupType } from "../models/api-models/fields/field-group-type";
import { sleep } from "../utilities/CommonUtility";
import { store } from "../App";
import { getQuestionnaireInitialInfoByPath } from "../utilities/PathUtility";
import { InstitutionsValueProps } from "./Institutions/reducers";
import { QuestionnaireRecruitUtility } from "../utilities/QuestionnaireRecruitUtility";
import { trackPromise } from 'react-promise-tracker'
const getKey = (formUrl: string) => `QUESTIONNAIRE_RECRUIT_KEY_${formUrl}`;

const getRecruitIdKey = (formUrl: string) => `QUESTIONNAIRE_RECRUIT_ID_KEY_${formUrl}`;

const notifier = new Subject<void>();
const saveRecruitSubject = new Subject<{ questionnaireId?: string, questionnaireUrl?: string, recruit: PartialUpdateRecruit, type?: PartialUpdateTypes }>();

const defaultColor1 = '#E2E4EE';
const defaultColor2 = '#007BFF';
const defaultColor3 = '#F5F7FA';

function createSubscription() {
    saveRecruitSubject.asObservable().pipe(
        takeUntil(notifier),
        switchMap(x => of(x).pipe(
            map(value => {
                const {
                    questionnaireId,
                    questionnaireUrl,
                    recruit,
                } = value;
                if (!questionnaireId || !questionnaireUrl || !recruit) {
                    throw Object.assign(
                        new Error('Can\'t process without data'),
                        { code: 403 }
                    );
                }
                return value;
            }),
            flatMap(value => from(
                trackPromise(QuestionnaireService.updateQuestionnaireRecruitPartial(
                    value.questionnaireId!, value.recruit!, value.type!
                ))
            ).pipe(
                map(result => ({ questionnaireId: x.questionnaireId, questionnaireUrl: x.questionnaireUrl!, recruitId: result }))
            )),
            catchError(err => {
                if(err.response.status === HttpErrorCode.BadRequest) {
                    store.dispatch(QuestionnaireActions.onShowDataCorruptDialog());
                }
                return EMPTY;
            })
        ))
    ).subscribe(result => {
        if (!!result && !!result.recruitId && !!result.questionnaireId && !!result.questionnaireUrl) {
            localStorage.setItem(getRecruitIdKey(result.questionnaireUrl), result.recruitId)
        }
    });
}
createSubscription();

export const getQuestionnaireActionCreator: ActionCreator<ThunkAction<Promise<void>, StoreState, void, AnyAction>> = () => {
    return async (dispatch: Dispatch, getState) => {
        const initialInfo = getQuestionnaireInitialInfoByPath(window.location.href);
        let confirmSms = false; 
        let questionnaire: QuestionnaireView = undefined;

        if (initialInfo.status === QuestionnaireStatus.Form) {
            let isQuestionnaireAvailable = false;
            if(initialInfo.recruitId) {
                isQuestionnaireAvailable = await QuestionnaireService.getUpdateOnlyQuestionaireRecruitIsAvailable(initialInfo.teamMnemonic,initialInfo.recruitId, initialInfo.formId)
            } else {
                isQuestionnaireAvailable = await QuestionnaireService.getQuestionnaireIsAvailable(initialInfo.teamMnemonic, initialInfo.formId);
            }
            dispatch(QuestionnaireActions.setQuestionnaireIsAvailable(isQuestionnaireAvailable));
            if (!isQuestionnaireAvailable) {
                dispatch(QuestionnaireActions.setQuestionnaire(null));
                dispatch(QuestionnaireActions.onLoadEnd());
                return;
            }
            questionnaire = await QuestionnaireService.getQuestionnaireByTeamMnemonic(initialInfo.teamMnemonic, initialInfo.formId);

        } else if (initialInfo.status === QuestionnaireStatus.Preview) {
            dispatch(QuestionnaireActions.setQuestionnaireIsAvailable(true));
            questionnaire = await QuestionnaireService.getQuestionnairePreviewById(initialInfo.formId);
        }

        if (questionnaire && questionnaire.questionnaireItems.length > 0 && questionnaire.questionnaireItems.some(x => x.type !== QuestionnaireItemType.Tab)) {
            dispatch(QuestionnaireActions.setQuestionnaire({
                id: initialInfo.formId,
                status: initialInfo.status,
                url: initialInfo.url,
                questionnaireId: questionnaire.id,
                teamId: questionnaire.teamId,
                color1: questionnaire.color1 || defaultColor1,
                color2: questionnaire.color2 || defaultColor2,
                color3: questionnaire.color3 || defaultColor3,
                title: questionnaire.title,
                teamName: questionnaire.teamName,
                introduction: questionnaire.description,
                logoId: questionnaire.logoId,
                submissionMessage: questionnaire.submissionMessage,
                ConsentToReceiveSms: true,
                isUpdateOnlyForm: !!initialInfo.recruitId && initialInfo.recruitId.length > 0,
            }));
            let genders = await QuestionnaireService.getGenders(questionnaire.teamId);
            let positions = await QuestionnaireService.getPositions(questionnaire.teamId);
            let biofields = await QuestionnaireService.getBioFields(questionnaire.teamId);
            let statFields = await QuestionnaireService.getStatsFields(questionnaire.teamId);
            let socialFields = await QuestionnaireService.getSocialFields(questionnaire.teamId);
            let orgCategories = await QuestionnaireService.getOrgCategories(questionnaire.teamId);
            let contacts = getContacts(questionnaire.questionnaireItems.filter(x => x.type === QuestionnaireItemType.Contact), biofields.find(gr => gr.nameId === 'PersonalInfo')!); // not changed
            let socials = getSocials(questionnaire.questionnaireItems.find(x => x.type === QuestionnaireItemType.SocialProfile) || null, socialFields);
            let files = getFiles(questionnaire.questionnaireItems.filter(x => x.type === QuestionnaireItemType.File));
            let footage = getFootage(questionnaire.questionnaireItems.filter(x => x.type === QuestionnaireItemType.Video));
            let institutionValueFields = getInstitutionFields(questionnaire.questionnaireItems.filter(x => x.type === QuestionnaireItemType.Institution), orgCategories);
            let questionnaireBioFields = getFields(questionnaire.questionnaireItems.filter(x => x.type === QuestionnaireItemType.BioField), biofields, positions, genders);
            let personalInfo = questionnaireBioFields.find(fg => fg.nameId === 'PersonalInfo');
            if (personalInfo && personalInfo.fields.find(f => f.nameId === 'cellPhone' && f.show)) {
                const phoneField = personalInfo.fields.find(f => f.nameId === 'cellPhone' && f.show);
                const index = personalInfo.fields.indexOf(phoneField);
                confirmSms = true;
                const field: Field = {
                    groupId: "",
                    id: smsStatusFieldId,
                    name: 'I agree to be texted at this number',
                    description: '',
                    nameId: smsStatusFieldId,
                    valueType: FieldValueType.Bool,
                    isDefault: false,
                    show: true,
                    required: false,
                };
                personalInfo.fields.splice(index + 1, 0, field);
                dispatch(BioFieldActions.setBioFieldValues({ 'smsStatus': true }));
            }
            let statsFields = getFields(questionnaire.questionnaireItems.filter(x => x.type === QuestionnaireItemType.StatsField), statFields);
            const requiredStats = getRequiredStats(questionnaire.questionnaireItems.filter(x => x.type === QuestionnaireItemType.StatsField));
            const tabList = QuestionnaireUtility.getTabList(questionnaire.questionnaireItems, personalInfo);
            dispatch(QuestionnaireActions.setTabList(tabList));
            let contactValidators = getContactValidators(contacts);
            let socialValidators = getSocialValidators(socials);
            let fileValidators = getFileValidators(files);
            let institutionValidators = getInstitutionsValidators(institutionValueFields);
            let bioValidators = getFieldValidators(questionnaireBioFields);    
            let statsValidators = getFieldValidators(statsFields);
            let footageValidators = getFootageValidator(footage);
            dispatch(ContactActions.setContactSettings(contacts));
            dispatch(SocialActions.setSocialSettings(socials));
            dispatch(FileActions.setFileSettings(files));
            dispatch(FootageActions.setFootageSettings(footage));
            dispatch(BioFieldActions.setBioFieldSettings(questionnaireBioFields));
            dispatch(InstitutionsActions.setInstitutionFields(orgCategories || []))
            dispatch(SportStatsFieldActions.setSportStatsFieldSettings(statsFields));
            dispatch(SportStatsFieldActions.setMultipleEvent(questionnaire.multipleEventForm, requiredStats));
            dispatch(ContactActions.setContactValidators(contactValidators));
            dispatch(SocialActions.setSocialValidators(socialValidators));
            dispatch(FileActions.setFileValidators(fileValidators));
            dispatch(InstitutionsActions.setInstitutionsValidators(institutionValidators));
            dispatch(FootageActions.setFootageValidators(footageValidators));
            dispatch(BioFieldActions.setBioFieldValidators(bioValidators));
            dispatch(SportStatsFieldActions.setSportStatsFieldValidators(statsValidators));

            if (initialInfo.status === QuestionnaireStatus.Form) {
                let questionnaireRecruit: QuestionnaireRecruit = JSON.parse(localStorage.getItem(getKey(initialInfo.url!)));
                let recruitId = localStorage.getItem(getRecruitIdKey(initialInfo.url));
                if (questionnaireRecruit && recruitId) {
                    try {
                    await QuestionnaireService.initializeQuestionnaireRecruit(questionnaire.id, recruitId);
                    } catch(err) {
                        if(err.response.status === HttpErrorCode.BadRequest) {
                            store.dispatch(QuestionnaireActions.onShowDataCorruptDialog());
                        } else {
                            window.location.reload();
                        }
                    }
                    setQuestionnaireRecruit(questionnaireRecruit, institutionValueFields, questionnaire.multipleEventForm, dispatch);
                } else {
                    localStorage.removeItem(getKey(initialInfo.url));
                    localStorage.removeItem(getRecruitIdKey(initialInfo.url));
                    if(initialInfo.recruitId) {
                        const recruitInfo = await QuestionnaireService.getUpdateOnlyQuestionaireRecruit(questionnaire.id, initialInfo.recruitId);
                        questionnaireRecruit = QuestionnaireRecruitUtility.getQuestionnaireRecruitByInfo(recruitInfo,biofields,statFields,socialFields, confirmSms, institutionValueFields);
                        setQuestionnaireRecruit(questionnaireRecruit, institutionValueFields, questionnaire.multipleEventForm, dispatch)
                        localStorage.setItem(getRecruitIdKey(initialInfo.url), recruitInfo.questionnaireRecruitId);  
                        localStorage.setItem(getKey(initialInfo.url), JSON.stringify(questionnaireRecruit));
                    } else {
                        var questionnaireRecruitId = await QuestionnaireService.initializeQuestionnaireRecruit(questionnaire.id);
                        localStorage.setItem(getRecruitIdKey(initialInfo.url), questionnaireRecruitId);
                        if(confirmSms) {
                            saveIncomplete(getState(), PartialUpdateTypes.Field, {fieldId: smsStatusFieldId, groupType: FieldGroupType.Bio} as PartialUpdateInfo);
                        }
                        dispatch(InstitutionsActions.setInstitutionsValues(institutionValueFields));
                    }
                }
            } else {
                dispatch(InstitutionsActions.setInstitutionsValues(institutionValueFields));
            }
            dispatch(QuestionnaireActions.onLoadEnd());
        } else {
            dispatch(QuestionnaireActions.setQuestionnaire(null))
            dispatch(QuestionnaireActions.onLoadEnd())
            return;
        }
    }
};

const setQuestionnaireRecruit = (questionnaireRecruit: QuestionnaireRecruit,
                                 institutionValueFields: { [id: string]: InstitutionsValueProps },
                                 multipleEventFrom: boolean,
                                 dispatch: Dispatch) => {
    if(questionnaireRecruit.institutionsValues &&
        Object.values(questionnaireRecruit.institutionsValues).every(x => typeof x.institutionId === 'string')) {
            const keysId = Object.keys(questionnaireRecruit.institutionsValues);
            keysId.forEach(id => {
                questionnaireRecruit.institutionsValues[id].pageSize = 20;
            });
            dispatch(InstitutionsActions.setInstitutionsValues(questionnaireRecruit.institutionsValues))}
        else { 
            dispatch(InstitutionsActions.setInstitutionsValues(institutionValueFields));
        }
        dispatch(AvatarUploadActions.setAvatar(questionnaireRecruit.avatarId))
        dispatch(ContactActions.setContactValues(questionnaireRecruit.contactValues));
        dispatch(SocialActions.setSocialValues(questionnaireRecruit.socialValues));
        dispatch(FileActions.setFileValues(questionnaireRecruit.fileValues));
        dispatch(FootageActions.setFootageValues(questionnaireRecruit.footageValues))
        dispatch(BioFieldActions.setBioFieldValues(questionnaireRecruit.bioFieldValues));
        dispatch(SportStatsFieldActions.setSportStatsFieldValues(questionnaireRecruit.sportStatsFieldValues));
        dispatch(SportStatsFieldActions.setMultipleEvent(multipleEventFrom, questionnaireRecruit.multipleEventChecked));
}



export const uploadAvatarActionCreator: ActionCreator<ThunkAction<Promise<void>, StoreState, File, AnyAction>> = (file: File) => {
    return async (dispatch: Dispatch, getState) => {
        const info = await ImageUtility.uploadImage(file, (avatar) => QuestionnaireService.uploadRecruitAvatar({ file: avatar.file, name: avatar.name }));
        if (info && info.id) {
            dispatch(AvatarUploadActions.onAvatarUpload(info.id));
            const state = getState();
            updateStorage(state);
            saveIncomplete(state, PartialUpdateTypes.Avatar, {} as PartialUpdateInfo);
        }
    }
};

export const searchInstitutionsByTermActionCreator: ActionCreator<ThunkAction<Promise<void>, StoreState, string, AnyAction>> =
    (id: string, term: string, categoryId: string, page: number, pageSize: number) => {
    return async(dispatch: Dispatch, getState) => {
        const state = getState();
        const teamId = state.questionnaire.questionnaire.teamId;
        dispatch(InstitutionsActions.setUpSearchResultLoading(id, true));
        if(term && term.length) {
            const searchResult = await QuestionnaireService.searchInstitutions(term, id, teamId, page, pageSize);
            if(page === 1) {
               dispatch(InstitutionsActions.setInstitutionSearchResult(searchResult, id));
            } else {
               dispatch(InstitutionsActions.concatInstitutionSearchResult(searchResult, id))
            }
        }
        dispatch(InstitutionsActions.setUpSearchResultLoading(id, false));
    }
}

export const deleteAvatarActionCreator: ActionCreator<ThunkAction<Promise<void>, StoreState, void, AnyAction>> = () => {
    return async (dispatch: Dispatch, getState) => {
        dispatch(AvatarUploadActions.onAvatarDelete(''));
        const state = getState();
        updateStorage(state);
        saveIncomplete(state, PartialUpdateTypes.Avatar, {} as PartialUpdateInfo);
    }
}

export const blurActionCreator: ActionCreator<ThunkAction<Promise<void>, StoreState, void, AnyAction>> = (action: AnyAction, type: PartialUpdateTypes, updateInfo : PartialUpdateInfo) => {
    return async (dispatch: Dispatch, getState) => {
        dispatch(action);
        const state = getState();
        updateStorage(state);
        if (state.questionnaire.questionnaire.status === QuestionnaireStatus.Form) {
            saveIncomplete(state, type, updateInfo);
        }
    }
};


export const keepBlankActionCreator: ActionCreator<ThunkAction<Promise<void>, StoreState, void, AnyAction>> = (action: AnyAction, contactId: string, value: boolean) => {
    return async (dispatch: Dispatch, getState) => {
        dispatch(action);
        const state = getState();
        updateStorage(state);
        if (state.questionnaire.questionnaire.status === QuestionnaireStatus.Form && value)  {
             const contact = state.contacts.contacts.find(c => c.id === contactId);
             if(contact) {
                  for(var i = 0; i< contact.contactFields.length; i++) {
                      const x = contact.contactFields[i];
                      dispatch(ContactActions.onContactBlur(`${contactId};${x.fieldName}`,''));
                      saveIncomplete(state, PartialUpdateTypes.Contact, {fieldId: `${contactId};${x.fieldName}` } as PartialUpdateInfo);
                      await sleep(500);
                    }
                updateStorage(getState());
             }
        }
    }
};

export const submitActionCreator: ActionCreator<ThunkAction<Promise<void>, StoreState, void, AnyAction>> = () => {
    return async (dispatch: Dispatch, getState) => {
        let state = getState();

        if (state.questionnaire.submitInProgress) {
            return;
        } else {
            dispatch(QuestionnaireActions.onSubmitStarted());
        }
        
        const bioErrors = validateAll(state.bioFields.bioFieldValidators, state.bioFields.bioFieldValues);
        const statsErrors = validateAll(state.sportStatsFields.sportStatsFieldValidators, state.sportStatsFields.sportStatsFieldValues);
        const socialErrors = validateAll(state.socials.socialValidators, state.socials.socialValues);
        const fileErrors = validateAll(state.files.fileValidators, state.files.fileValues);
        const contactErrors = validateContacts(state.contacts.contactValidators, state.contacts.contactValues, state.contacts.contacts);
        const footageErrors = validateAll(state.footage.footageValidators, state.footage.footageValues);
        const institutionsErrors = validateAll(state.institutions.institutionsValidators, state.institutions.institutionsValue);

        dispatch(BioFieldActions.setBioFieldErrors(bioErrors));
        dispatch(SportStatsFieldActions.setSportStatsFieldErrors(statsErrors));
        dispatch(SocialActions.setSocialErrors(socialErrors));
        dispatch(FileActions.setFileErrors(fileErrors));
        dispatch(FootageActions.setFootageErrors(footageErrors));
        dispatch(ContactActions.setContactErrors(contactErrors));
        dispatch(InstitutionsActions.setInstitutionsErrors(institutionsErrors));

        const hasErrors = Object.keys(bioErrors).some(key => bioErrors[key]) || Object.keys(statsErrors).some(key => statsErrors[key]) ||
            Object.keys(socialErrors).some(key => socialErrors[key]) ||
            Object.keys(fileErrors).some(key => fileErrors[key]) || Object.keys(contactErrors).some(key => contactErrors[key]) ||
            Object.keys(footageErrors).some(key => footageErrors[key]) ||
            Object.keys(institutionsErrors).some(key => institutionsErrors[key]);

        if (hasErrors) {
            dispatch(QuestionnaireActions.onSubmitRejected());
            return;
        }
        
        state = getState();
        const isUpdateOnlyForm = state.questionnaire.questionnaire.isUpdateOnlyForm;

        const { bioValues, positions } = getAllValidBioValues(state.bioFields);
        let smsStatus = bioValues.find(x => x.fieldId === smsStatusFieldId);
        const bioValuesForSaving = bioValues.filter(x => x.fieldId !== smsStatusFieldId);
        let recruit = {} as PartialUpdateRecruit;
        
        if(!isUpdateOnlyForm) {
          recruit = {
            recruitId: localStorage.getItem(getRecruitIdKey(state.questionnaire.questionnaire.url)),
            avatarId: state.avatar.avatarId,
            bioFieldValues: bioValuesForSaving,
            sportStatsFieldValues: getAllValidSportStatsValues(state.sportStatsFields),
            positions: positions,
            socialProfiles: getSocialValues(state.socials),
            files: getFileValues(state.files),
            footages: getFootageValues(state.footage),
            contacts: getAllValidContactValues(state.contacts),
            consentToReceiveSms: getSmsSubsriptionStatus(smsStatus),
            institutionRelations: getInstitutionRelations(state.institutions.institutionsValue)
        } as PartialUpdateRecruit; 
    } else {
        recruit = {
            recruitId: localStorage.getItem(getRecruitIdKey(state.questionnaire.questionnaire.url)),
        } as PartialUpdateRecruit
    }
        
        const submittedInfo = getRecruitPartialUpdateChanges(state, recruit, PartialUpdateTypes.Submit, {} as PartialUpdateInfo);
        try {
            setTimeout(() => submitRecruit(submittedInfo!.recruit, isUpdateOnlyForm, getState, dispatch), 1000)
        } catch (e) {
            dispatch(QuestionnaireActions.onSubmitRejected());
            throw e;
        } finally {
            createSubscription();
        } 
    }
};


const submitRecruit =  async (recruit: PartialUpdateRecruit, isUpdateOnlyForm: boolean, getState: () => StoreState, dispatch: Dispatch) => {
            notifier.next();
            let state = getState();
            let countOfRetry = 100;
            while(state.questionnaire.isPartialUpdateInProgress && countOfRetry > 0) {
                await sleep(1000);
                state = getState();
                countOfRetry --;
            }


            if(!state.questionnaire.isPartialUpdateInProgress) {
                try {
                    const submitType = isUpdateOnlyForm ? PartialUpdateTypes.SubmitUpdateOnly : PartialUpdateTypes.Submit;
                    await QuestionnaireService.updateQuestionnaireRecruitPartial(state.questionnaire.questionnaire.questionnaireId, recruit, submitType);
                    localStorage.removeItem(getKey(state.questionnaire.questionnaire.url));
                    localStorage.removeItem(getRecruitIdKey(state.questionnaire.questionnaire.url));
                    dispatch(QuestionnaireActions.onSubmitEnd());
                } catch(e) {
                    if(e.response.status === HttpErrorCode.BadRequest) {
                        store.dispatch(QuestionnaireActions.onShowDataCorruptDialog());
                    }
                    throw e;
                }
            } else {
                
            }
}

export const resetFormActionCreator: ActionCreator<ThunkAction<void, StoreState, void, AnyAction>> = () => {
    return (dispatch: Dispatch, getState) => {
        const { questionnaire } = getState();
        localStorage.removeItem(getKey(questionnaire.questionnaire.url));
        localStorage.removeItem(getRecruitIdKey(questionnaire.questionnaire.url));
        dispatch(QuestionnaireActions.onCloseDialogs());
        window.location.reload();
    }
}

const showDataCorruptDialogActionCreator: ActionCreator<ThunkAction<void, StoreState, void, AnyAction>> = () => {
    return (dispatch: Dispatch, getState) => {
        dispatch(QuestionnaireActions.onShowDataCorruptDialog())
    }
}

export const postNewActionCreator: ActionCreator<ThunkAction<void, StoreState, void, AnyAction>> = () => {
    return (dispatch: Dispatch, getState) => {
        dispatch(BioFieldActions.setBioFieldErrors({}));
        dispatch(SportStatsFieldActions.setSportStatsFieldErrors({}));
        dispatch(SocialActions.setSocialErrors({}));
        dispatch(FileActions.setFileErrors({}));
        dispatch(FootageActions.setFootageErrors({}));
        dispatch(ContactActions.setContactErrors({}));
        dispatch(BioFieldActions.setBioFieldValues({}));
        dispatch(SportStatsFieldActions.setSportStatsFieldValues({}));
        dispatch(SocialActions.setSocialValues({}));
        dispatch(FileActions.setFileValues({}));
        dispatch(FootageActions.setFootageValues({}));
        dispatch(ContactActions.setContactValues({}));
        dispatch(AvatarUploadActions.setAvatar(''));
        dispatch(QuestionnaireActions.onTabSelect(0));
        dispatch(QuestionnaireActions.postNew());
    }
};

export const updateStorage = (state: StoreState) => {
    const questionnaireRecruit: QuestionnaireRecruit = {
        avatarId: state.avatar.avatarId,
        bioFieldValues: state.bioFields.bioFieldValues,
        sportStatsFieldValues: state.sportStatsFields.sportStatsFieldValues,
        socialValues: state.socials.socialValues,
        contactValues: state.contacts.contactValues,
        fileValues: state.files.fileValues,
        multipleEventChecked: state.sportStatsFields.multipleEventChecked,
        footageValues: state.footage.footageValues,
        institutionsValues: state.institutions.institutionsValue,
    };
    localStorage.setItem(getKey(state.questionnaire.questionnaire.url), JSON.stringify(questionnaireRecruit));
};

export const saveIncomplete = (state: StoreState, type: PartialUpdateTypes, updateInfo: PartialUpdateInfo) => {
    let recruit = {
        recruitId: localStorage.getItem(getRecruitIdKey(state.questionnaire.questionnaire.url))
    } as PartialUpdateRecruit;

    const recruitChangesInfo = getRecruitPartialUpdateChanges(state, recruit, type, updateInfo);
    if(recruitChangesInfo !== null) {
       saveRecruitSubject.next({ questionnaireId: state.questionnaire.questionnaire.questionnaireId, questionnaireUrl: state.questionnaire.questionnaire.url, recruit: recruitChangesInfo.recruit, type: recruitChangesInfo.type });
    }
};

const getRecruitPartialUpdateChanges = (state: StoreState, recruit: PartialUpdateRecruit, type: PartialUpdateTypes, updateInfo: PartialUpdateInfo) : {recruit: PartialUpdateRecruit, type: PartialUpdateTypes} | null => {
    switch(type) {
        case PartialUpdateTypes.Avatar:
            recruit = {
                ...recruit,
                avatarId: state.avatar.avatarId
            };
            return {recruit, type};
        case PartialUpdateTypes.Contact:
            const contact = getValidContactValue(state.contacts, updateInfo.fieldId!);
            if(contact === null) {
              return null;
            }
            recruit = {
                ...recruit,
                relationId: contact.relationId!,
                contactFieldValue: contact.contactFieldValue,  
            };
            return {recruit, type};
            case PartialUpdateTypes.Field: {
                let fieldValue : FieldValue | null = null;
                if(updateInfo.groupType === FieldGroupType.Bio) {
                    let { bioValue, positions } = getValidBioValue(state.bioFields, updateInfo.fieldId!);
                    if (positions.length > 0) {
                        type = PartialUpdateTypes.Positions;
                        recruit = {
                            ...recruit,
                            positions,
                        };
                        return {recruit, type};
                    }
                    if (bioValue && bioValue.fieldId === smsStatusFieldId) {
                        type = PartialUpdateTypes.SmsStatus;
                        recruit = {
                            ...recruit,
                            consentToReceiveSms: getSmsSubsriptionStatus(bioValue)
                        };
                        return {recruit, type};
                    }
                    if(bioValue) {
                        fieldValue = bioValue;
                    }             
                } else {
                    fieldValue = getValidSportStatsValue(state.sportStatsFields, updateInfo.fieldId!);
                }
                if (fieldValue === null) {
                    return null;
                }
                recruit = {
                    ...recruit,
                    fieldGroupType: updateInfo.groupType!,
                    fieldValue, 
                };
                return {recruit, type};
            }
        case PartialUpdateTypes.Files:
             recruit = {
                ...recruit,
                files: getFileValues(state.files)
             }
             return {recruit, type};
        case PartialUpdateTypes.Footages:
            recruit = {
                ...recruit,
                footages: getFootageValues(state.footage),
            }
            return {recruit, type};
        case PartialUpdateTypes.Institutions:
            recruit ={
                ...recruit,
                institutionRelations: getInstitutionRelations(state.institutions.institutionsValue) 
            };
            return {recruit, type};
        case PartialUpdateTypes.Social:
            recruit = {
                ...recruit,
                socialProfiles: getSocialValues(state.socials), 
            }
            return {recruit, type}; 
        case PartialUpdateTypes.Submit:
             var info = state.bioFields.bioFields.find(f => f.nameId === 'PersonalInfo')!.fields;
             var firstNameIndex = info.find(field => field.nameId === 'firstName')!.id;
             var lastNameIndex = info.find(field => field.nameId === 'lastName')!.id;
             var firstName = getValidBioValue(state.bioFields, firstNameIndex).bioValue!.shortValue;
             var lastName =  getValidBioValue(state.bioFields, lastNameIndex).bioValue!.shortValue;
             recruit = {
                ...recruit,
                fullName: `${firstName} ${lastName}`
             }
            return {recruit, type}
        default:
            return null;       
    }
}

const getSmsSubsriptionStatus = (value: FieldValue | undefined): SmsSubscriptionStatus => {
    if (value && value.boolValue) {
        return SmsSubscriptionStatus.Agreed;
    } 
    return SmsSubscriptionStatus.NotAgreed;
}
