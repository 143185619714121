import { connect } from 'react-redux';
import { AnyAction } from "redux";
import { StoreState } from "../../store/configureStore";
import { Questionnaire } from '../../components/Questionnaire';
import { QuestionnaireActions } from './actions';
import { ThunkDispatch } from 'redux-thunk';
import { getQuestionnaireActionCreator, submitActionCreator, postNewActionCreator, resetFormActionCreator } from '../actionCreators';
import { Upload } from '@progress/kendo-react-upload';

const mapStateToProps = (state: StoreState, ownProps: any) => {
    return {
        ...state.questionnaire
    };
}

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>) => {
    return {
        onTabSelect: (index: number) => dispatch(QuestionnaireActions.onTabSelect(index)),
        onInit: () => dispatch(getQuestionnaireActionCreator()),
        onSubmit: () => dispatch(submitActionCreator()),
        onPostNew: () => dispatch(postNewActionCreator()),
        onShowResetDialog: () => dispatch(QuestionnaireActions.onShowResetDialog()),
        setPartialUpdateStatus: (updateInProgress: boolean) => dispatch(QuestionnaireActions.setPartialUpdateStatus(updateInProgress)),
        onCloseDialogs: () => dispatch(QuestionnaireActions.onCloseDialogs()),
        onResetForm: () => dispatch(resetFormActionCreator()),
    }   
}

const QuestionnaireContainer = connect(mapStateToProps, mapDispatchToProps)(Questionnaire);

export default QuestionnaireContainer;