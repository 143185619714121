import React from "react";
import { FieldProps } from "../../models/fields/field-props";
import { FieldHeader } from "../Common/FieldHeader";

export class LongStringField extends React.PureComponent<FieldProps<string>> {

    constructor(props: FieldProps<string>) {
        super(props);
        this.onBlur = this.onBlur.bind(this);
        this.onChange = this.onChange.bind(this);
    }

    onBlur(event: React.FocusEvent<HTMLTextAreaElement>) {
        const { onBlur, id, value } = this.props;
        onBlur(id, value);
    }

    onChange(event: React.ChangeEvent<HTMLTextAreaElement>) {
        const { onChange, id } = this.props;
        onChange(id, event.target.value);
    }

    render() {
        const { name, required, value, valid, description } = this.props;
        const className = 'w-100 k-textarea' + (valid ? '' : ' k-state-invalid');
        return <React.Fragment>
            <FieldHeader required={required} name={name} description={description} />
            <textarea
                maxLength={4096}
                className={className}
                onBlur={this.onBlur}
                onChange={this.onChange}
                value={value}></textarea>
        </React.Fragment>;
    }
}