import { DropDownList, DropDownListChangeEvent } from "@progress/kendo-react-dropdowns";
import { Input } from "@progress/kendo-react-inputs";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import parsePhoneNumber, { getCountries, getCountryCallingCode } from 'libphonenumber-js';
import { FieldProps } from "../../../models/fields/field-props";
import { FieldHeader } from "../../Common/FieldHeader";

import "../../../styles/components/RecruitPhoneNumberField.css";

interface FormattedPhoneFieldProps extends FieldProps<string> {
    formatNumber: boolean;
}

const phoneRegexp = new RegExp(/((\+?\s*\d+\s)|1)?([\s\d\(\)-]+)/);
const getCountryCodeAndNumber = (value: string): [string, string] | undefined => {
    const result = phoneRegexp.exec(value);
    if (result === null) {
        return undefined;
    }

    const code = result[1] !== undefined ? result[1].replace(/\D/g, '') : '1';
    const number = result[3].replace(/\D/g, '');
    return [code, number];
}

export const RecruitPhoneNumberField = (props: FormattedPhoneFieldProps) => {
    const [value, setValue] = useState<string>();
    const [countryCode, setCountryCode] = useState("1");
    const [number, setNumber] = useState("");

    const countryCodeOptions = useMemo(() => {
        const countries = getCountries();
        const countryCodes = countries
            .map(c => getCountryCallingCode(c))
            .filter((value, index, self) => self.indexOf(value) === index)
            .sort((a, b) => a.localeCompare(b));

        return countryCodes.map(cc => ({ id: cc as string, text: `+${cc}` }));
    }, []);
    const selectedCountryCodeOption = useMemo(
        () => countryCodeOptions.find(c => c.id === countryCode),
        [countryCodeOptions, countryCode]);

    useEffect(() => {
        if (props.value === undefined || props.value.length === 0) {
            setValue(undefined);
            setNumber("");
            return;
        }

        const result = getCountryCodeAndNumber(props.value);
        if (result === undefined) {
            setValue(undefined);
            return;
        }
        setCountryCode(result[0]);
        setNumber(result[1]);
    }, [props.value]);

    useEffect(() => {
        const rawNumber = number.replace(/\D/g, "");

        if (props.formatNumber && rawNumber.length > 0) {
            const defaultFormat = `+${countryCode} ${rawNumber}`;
            const phoneNumber = parsePhoneNumber(defaultFormat);
            const formattedPhoneNumber = 
                phoneNumber !== undefined
                ? phoneNumber.formatInternational()
                : defaultFormat;

            setValue(formattedPhoneNumber);
        }
        else {
            if (rawNumber.length > 0) {
                setValue(`${countryCode} ${rawNumber}`);
            } else {
                setValue("");
            }
        }
    }, [countryCode, number]);

    const onBlur = useCallback(() => {
        const rawNumber = number.replace(/\D/g, "");
        const defaultFormat = `+${countryCode} ${rawNumber}`;
        const phoneNumber = parsePhoneNumber(defaultFormat);
        const formattedPhoneNumber = 
            phoneNumber !== undefined
            ? phoneNumber.formatInternational()
            : defaultFormat;

        const resultPhoneNumber = formattedPhoneNumber.replace(/^\+\d+\s/, '');
        setNumber(resultPhoneNumber);

        props.onBlur(props.id, value);
    }, [props.onBlur, props.id, value, countryCode, number]);


    const onCountryCodeChange = useCallback((e: DropDownListChangeEvent) => setCountryCode(e.target.value.id), []);
    const onNumberChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => setNumber(e.target.value), []);

    const onNumberKeyPress = useCallback((e: React.KeyboardEvent) => {
        const allowedInput = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '-', '(', ')', ' ', ''];
        if (allowedInput.indexOf(e.key) === -1) {
            e.preventDefault();
        }
    }, []);

    return (
        <React.Fragment>
            <FieldHeader required={props.required} name={props.name} description={props.description} />
            <div className="input-group">
                <div className="input-group-prepend phone-country-code-container">
                    <DropDownList
                        data={countryCodeOptions}
                        className="w-100"
                        onBlur={onBlur}
                        dataItemKey="id"
                        textField="text"
                        onChange={onCountryCodeChange}
                        value={selectedCountryCodeOption}
                        valid={props.valid}
                        disabled={props.disabled}
                    />
                </div>
                <Input
                    className="form-control box-shadow-none"
                    onBlur={onBlur}
                    onChange={onNumberChange}
                    onKeyPress={onNumberKeyPress}
                    value={number}
                    valid={props.valid}
                    disabled={props.disabled}
                    maxLength={20}
                />
            </div>
        </React.Fragment>
    )
}
