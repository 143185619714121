import { discord } from "../constants/Constants";


export class SocialNetworkUtility {
    public static isLoginParsed(value: string | undefined): boolean {
        return (typeof value === 'string' && (value ===
            'Twitter' || value === 'Instagram' || value === 'Facebook'));
    }

    public static getLoginFromUrl(url: string): string {
        const slicePos = url.startsWith('http') ? 2 : 1;
        const cutUrl = url.split('/').filter(elem => elem).splice(slicePos);
        return cutUrl[0];
    }

    public static createTwitterUrl(socialNetworkId: string, url: string): string {
        return url.startsWith('@') ? this.generateUrl(url.substring(1), socialNetworkId) : url;
    }

    public static generateUrl(login: string, network: string): string {
        if (network === 'Twitter') {
            return `https://${network.toLowerCase()}.com/${login}`;
        } else {
            return `https://www.${network.toLowerCase()}.com/${login}`;
        }
    }

    public static checkUrl(value: string | null | undefined): boolean {
        //eslint-disable-next-line
        const regExpUrl = new RegExp(/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/);
        return (typeof value === 'string' && value.match(regExpUrl) !== null);
    }

    public static isOnlyLoginRequired(socialNetworkId: string) {
        return discord === socialNetworkId;
    }
}