import React from "react";
import { DropdownField } from "../DropdownField";
import { FieldProps } from "../../../models/fields/field-props";

const jerseyNumbers = (['00']).concat(Array.from(Array(100).keys()).map(String)).map(x => { return { id: x, title: x } });

export class RecruitJerseyNumberField extends React.PureComponent<FieldProps<string>> {

    render() {
        return <DropdownField {...this.props} options={jerseyNumbers} />;
    }
}