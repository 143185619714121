import React from "react";
import { PagerLinkType } from "../../models/tab/pager-link-type";

export interface PagerLinkProps {
    selectedTab: number;
    onSelectTab: (tab: number) => void;
    type: PagerLinkType;
}

export class PagerLink extends React.PureComponent<PagerLinkProps> {

    constructor(props: PagerLinkProps) {
        super(props);
        this.onSelectTab = this.onSelectTab.bind(this);
    }

    onSelectTab() {
        const { selectedTab, onSelectTab, type } = this.props;
        onSelectTab(selectedTab + (type as number));
    }

    render() {
        return <a href="#!" className="nav-link" onClick={this.onSelectTab}>{this.props.children}</a>
    }
}