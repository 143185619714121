import { connect } from 'react-redux';
import { AnyAction } from "redux";
import { StoreState } from "../../store/configureStore";
import { FileActions } from './actions';
import { FileUpload } from '../../components/Info/FileUpload';
import { FileAttachment } from '../../models/files/file-attachment';
import { blurActionCreator } from '../actionCreators';
import { ThunkDispatch } from 'redux-thunk';
import { PartialUpdateTypes } from '../../constants/Constants';
import { PartialUpdateInfo } from '../../models/partial-update/partial-update-info';

const mapStateToProps = (state: StoreState, ownProps: any) => {
    const id: string = ownProps.id;
    return {
        ...ownProps,
        ...state.files.files.find(x => x.id === id) || {},
        valid: !state.files.fileErrors[id],
        value: state.files.fileValues[id]
    }
}

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>) => {
    return {
        onAddFile: (id: string, value: FileAttachment) => dispatch(blurActionCreator(FileActions.onFileAdd(id, value), PartialUpdateTypes.Files, {} as PartialUpdateInfo)),
        onRemoveFile: (id: string, index: number) => dispatch(blurActionCreator(FileActions.onFileRemove(id, index), PartialUpdateTypes.Files, {} as PartialUpdateInfo))
    }
}

const FileUploadContainer = connect(mapStateToProps, mapDispatchToProps)(FileUpload);

export default FileUploadContainer;