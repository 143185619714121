import React, { PureComponent } from "react";

export interface InstitutionSelectorProps {
    useCumtomInstitutionInput: boolean,
    onSwitchInstitutionInput: (id: string, value: boolean) => void,
    id: string,
}

export class InstitutionSelector extends PureComponent<InstitutionSelectorProps> {

    constructor(props: InstitutionSelectorProps) {
        super(props);
        this.onSwitchInput = this.onSwitchInput.bind(this);
    }

    onSwitchInput() {
        const { useCumtomInstitutionInput, onSwitchInstitutionInput, id } = this.props;
        onSwitchInstitutionInput(id, !useCumtomInstitutionInput);
    }

    render(): React.ReactNode {
        const { useCumtomInstitutionInput } = this.props;
        return (
            <React.Fragment>
                <div className="radio-selector-container radio-selector-container-indent" onClick={this.onSwitchInput}>
                    <input type="radio"
                        checked={useCumtomInstitutionInput === false}
                        className='radio-btn-indent'
                        onChange={this.onSwitchInput}
                    />
                    <span>Match from the list {useCumtomInstitutionInput}</span>
                </div>
                <div className="radio-selector-container" onClick={this.onSwitchInput}>
                    <input type="radio"
                        className='radio-btn-indent'
                        checked={useCumtomInstitutionInput === true}
                        onChange={this.onSwitchInput}
                    />
                    <span>Manual Input {useCumtomInstitutionInput}</span>
                </div>
            </React.Fragment>
        )
    }
}