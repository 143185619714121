import React from "react";
import { FieldGroup } from "../../models/fields/field-group";
import { SportStatsFieldGroup } from "./SportStatsFieldGroup";
import MultipleEventChoiceContainer from "../../containers/MultipleEventChoice";
import { excludeFromMultipleEvent } from "../../constants/Constants";

export interface QuestionnaireSportStatsProps {
    groups: FieldGroup[];
    groupId: string;
    checkedFields: { [id: string]: boolean }
    multipleEventForm: boolean;
}

export class QuestionnaireSportStats extends React.Component<QuestionnaireSportStatsProps> {

    render() {
        const { groups, multipleEventForm, checkedFields, groupId } = this.props;
        return <div  className="field-group-area">
            {multipleEventForm && <MultipleEventChoiceContainer groupId={groupId} />}
            {groups.filter(x => !multipleEventForm || (excludeFromMultipleEvent.some(n => n === x.nameId) || x.fields.some(f => checkedFields[f.id])))
                    .map(x => <SportStatsFieldGroup key={x.id} multipleEvenForm={multipleEventForm} {...x} />)}
        </div>
    }
}
