export enum QuestionnaireItemType {
    Tab = 0,
    SectionHeader = 1,
    BioField = 2,
    StatsField = 3,
    Contact = 4,
    File = 5,
    Video = 6,
    SocialProfile = 7,
    TextArea = 8,
    Institution = 9,
    Avatar = 10
}