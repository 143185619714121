import { ContactAction, onContactBlur } from "./actions";
import { ON_CONTACT_CHANGE, ON_CONTACT_BLUR, SET_CONTACT_VALIDATORS, SET_CONTACT_VALUES, SET_CONTACT_SETTINGS, SET_CONTACT_ERRORS } from "./constants";
import { Validator } from "../../utilities/ValidatorUtility";
import { Contact } from "../../models/contacts/contact";
import { keepBlank, contactFields } from "../../constants/Constants";
import { validate } from "../selectors";

export interface ContactState {
    contactValues: { [id: string]: any };
    contactErrors: { [id: string]: boolean };
    contactValidators: { [id: string]: Validator[] };
    contacts: Contact[]
}

const initialState: ContactState = {
    contactValues: {},
    contactErrors: {},
    contactValidators: {},
    contacts: []
}


export const contactReducer = (state: ContactState = initialState, action: ContactAction) => {
    switch (action.type) {
        case ON_CONTACT_CHANGE: {
            return {
                ...state,
                contactValues: {
                    ...state.contactValues,
                    [action.payload.id]: action.payload.value
                }
            }
        }

        case ON_CONTACT_BLUR: {
            return {
                ...state,
                contactValues: {
                    ...state.contactValues,
                    [action.payload.id]: action.payload.value
                },
                contactErrors: {
                    ...state.contactErrors,
                    [action.payload.id]: validate(state.contactValidators[action.payload.id], action.payload.value)
                }, 
            }
        }

        case SET_CONTACT_VALIDATORS: {
            return {
                ...state,
                contactValidators: action.payload.validators
            }
        }

        case SET_CONTACT_VALUES: {
            return {
                ...state,
                contactValues: action.payload.values
            }
        }

        case SET_CONTACT_SETTINGS: {
            return {
                ...state,
                contacts: action.payload.contacts
            }
        }

        case SET_CONTACT_ERRORS: {
            return {
                ...state,
                contactErrors: action.payload.errors
            }
        }

        default: {
            return state;
        }
    }
}