import React, { PureComponent } from "react";
import { InstitutionSelector } from './InstitutionSelector';

export interface InstitutionHeaderProps {
    onSwitchInstitutionInput: (id: string, value: boolean) => void,
    institutionCaterotyName: string,
    description: string;
    isRequired: boolean;
    showRadioInstitutionOptions: boolean,
    useCumtomInstitutionInput: boolean,
    id: string
}

export class InstitutionHeader extends PureComponent<InstitutionHeaderProps> {

    constructor(props: InstitutionHeaderProps) {
        super(props);
    }

    render() {
        const { onSwitchInstitutionInput, institutionCaterotyName, showRadioInstitutionOptions, useCumtomInstitutionInput, isRequired, description, id } = this.props;
        return (
            <React.Fragment>
              <div className='institution-header'>
                  <span className={`field-header ${isRequired ? 'require' : ''} `}>{institutionCaterotyName}</span>
                  {showRadioInstitutionOptions &&
                      <div className="d-inline-block">
                          <InstitutionSelector useCumtomInstitutionInput={useCumtomInstitutionInput}
                              onSwitchInstitutionInput={onSwitchInstitutionInput} id={id} />
                      </div>
                  }
              </div>
              {description && <label>{description}</label>}
            </React.Fragment>
        );
    }
}