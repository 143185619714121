import { ON_FOOTAGE_ADD, ON_FOOTAGE_REMOVE, SET_FOOTAGE_VALIDATORS, SET_FOOTAGE_VALUES, SET_FOOTAGE_SETTINGS, SET_FOOTAGE_ERRORS } from "./constants";
import { Validator } from "../../utilities/ValidatorUtility";
import { Footage } from "../../models/footage/footage";
import { FootageAttachment } from "../../models/footage/footage-attachment";

export interface onFootageAdd {
    type: typeof ON_FOOTAGE_ADD;
    payload: {
        id: string;
        footage: FootageAttachment;
    }
}

export interface onFootageRemove {
    type: typeof ON_FOOTAGE_REMOVE;
    payload: {
        id: string;
        index: number;
    }
}

export interface setFootageValidators {
    type: typeof SET_FOOTAGE_VALIDATORS;
    payload: {
        validators: { [id: string]: Validator[] }
    }
}

export interface setFootageValues {
    type: typeof SET_FOOTAGE_VALUES;
    payload: {
        values: { [id: string]: FootageAttachment[] }
    }
}

export interface setFootageSettings {
    type: typeof SET_FOOTAGE_SETTINGS;
    payload: {
        footage: Footage[]
    }
}

export interface setFootageErrors {
    type: typeof SET_FOOTAGE_ERRORS;
    payload: {
        errors: { [id: string]: boolean }
    }
}

export type FootageAction = onFootageAdd | onFootageRemove | setFootageValidators | setFootageValues | setFootageSettings | setFootageErrors;

export class FootageActions {

    public static onFootageAdd(id: string, footage: FootageAttachment) {
        return {
            type: ON_FOOTAGE_ADD,
            payload: {
                id,
                footage
            }
        }
    }

    public static onFootageRemove(id: string, index: number) {
        return {
            type: ON_FOOTAGE_REMOVE,
            payload: {
                id,
                index
            }
        }
    }

    public static setFootageValidators(validators: { [id: string]: Validator[] }) {
        return {
            type: SET_FOOTAGE_VALIDATORS,
            payload: {
                validators
            }
        }
    }

    public static setFootageValues(values: { [id: string]: FootageAttachment[] }) {
        return {
            type: SET_FOOTAGE_VALUES,
            payload: {
                values
            }
        }
    }

    public static setFootageSettings(footage: Footage[]) {
        return {
            type: SET_FOOTAGE_SETTINGS,
            payload: {
                footage
            }
        }
    }

    public static setFootageErrors(errors: { [id: string]: boolean }) {
        return {
            type: SET_FOOTAGE_ERRORS,
            payload: {
                errors
            }
        }
    }

}