import {  AvatarUploadAction } from "./actions";
import { ON_AVATAR_UPLOAD, SET_AVATAR, ON_AVATAR_DELETE } from "./constants";

export interface AvatarUploadState {
    avatarId?: string;
}

const initialState: AvatarUploadState = {
    avatarId: ''
}

export const avatarUploadReducer = (state: AvatarUploadState = initialState, action: AvatarUploadAction) => {
    switch (action.type) {

        case ON_AVATAR_UPLOAD: {
            return {
                ...state,
                avatarId: action.payload.avatarId
            }
        }   

        case SET_AVATAR: {
            return {
                ...state,
                avatarId: action.payload.avatarId
            }
        }

        case ON_AVATAR_DELETE: {
            return {
                ...state,
                avatarId: null
            }
        }

        default: {
            return state;
        }
    }
}