import React, { PureComponent } from "react";
import { InstitutionCategory } from "../../models/institution/institution-category";
import InstitutionItemContainer from '../../containers/Institutions/index';

export class InstitutionList extends PureComponent<{ institutions: InstitutionCategory[] }> {
    constructor(props: { institutions: InstitutionCategory[] }) {
        super(props);
    }

    render() {
        const { institutions } = this.props;
        return (
            <React.Fragment>
                {institutions.map(x => <InstitutionItemContainer key={x.id} {...x} />)}
            </React.Fragment>
        )

    }
}