import React from "react";
import { DropDownList, DropDownListChangeEvent, DropDownListFilterChangeEvent } from '@progress/kendo-react-dropdowns';
import { filterBy } from "@progress/kendo-data-query";
import { FieldProps } from "../../models/fields/field-props";
import { FieldHeader } from "../Common/FieldHeader";

const delay = 500;

export class DropdownField extends React.PureComponent<FieldProps<string>, { data: any[] }> {

    private timeout?: number;

    constructor(props: FieldProps<string>) {
        super(props);
        this.state = { data: this.props.options };
        this.onChange = this.onChange.bind(this);
        this.onFilterChange = this.onFilterChange.bind(this);
    }

    componentDidUpdate(prevProps) {
        if (prevProps.options !== this.props.options) {
            this.setState({ data: this.props.options });
        }
    }

    onChange(event: DropDownListChangeEvent) {
        const { onBlur, id } = this.props;
        onBlur(id, event.target.value.id);
    }

    onFilterChange(event: DropDownListFilterChangeEvent) {
        const { options } = this.props;
        clearTimeout(this.timeout);
        this.timeout = window.setTimeout(() => {
            this.setState({
                data: filterBy([...options], event.filter),
            });
        }, delay);
    }

    componentWillUnmount() {
        clearTimeout(this.timeout);
    }

    render() {
        const { name, required, value, valid, filter, options, description } = this.props;
        const { data } = this.state;
        const objectValue = value ? options.find(x => x.id === value) : null;
        return <React.Fragment>
            <FieldHeader required={required} name={name} description={description} />
            <DropDownList data={data}
                className="w-100"
                textField="title"
                dataItemKey="id"
                filterable={filter}
                onChange={this.onChange}
                onFilterChange={this.onFilterChange}
                value={objectValue}
                valid={valid}/>
        </React.Fragment>;
    }
}