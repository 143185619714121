import React, { PureComponent, CSSProperties } from "react";
import { InstitutionItemProps } from './InstitutionItem';
import { InstitutionSearchResultItem } from './InstitutionSearchResultItem';
import { InstitutionSearchResultEmpty } from './InstitutionSearchResultEmpty';

export interface InstitutionSearchResultProps extends InstitutionItemProps {
    active: boolean;
    inputValue: string;
    selectItem: () => void
}

export class InstitutionSearchResult extends PureComponent<InstitutionSearchResultProps, { position: number }> {

    private parentWrap;
    initialTopPosition: number = 0;

    styleParentWrap: CSSProperties = {};

    constructor(props) {
        super(props);
        this.parentWrap = React.createRef();
        this.onSearchResultScroll = this.onSearchResultScroll.bind(this);
    }

    componentDidMount() {
        const { current } = this.parentWrap;
        const { top } = current.getBoundingClientRect();
        this.initialTopPosition = top;
        this.renderSearchResultPosition();
    }

    componentDidUpdate() {
        this.renderSearchResultPosition();
    }

    onSearchResultScroll(e: any) {
        if (this.parentWrap) {
            const current = this.parentWrap.current;
            const scrolledToBottom = current.scrollTop === (current.scrollHeight - current.offsetHeight);
            if (scrolledToBottom) {
                const { searchInstitutionsByTerm, setNextSearchResultValue, id, inputValue } = this.props;
                const { page, pageSize } = this.props.value;
                setNextSearchResultValue(id, page + 1);
                searchInstitutionsByTerm(id, inputValue, id, page + 1, pageSize)
            }
        }
    }

    renderSearchResultPosition() {
        const { institutionsSearchResult } = this.props.value;
        const { current } = this.parentWrap;
        if (institutionsSearchResult.length) {
            const { innerHeight } = window;
            const rowHeight = 61;
            const lengthSearchArray = institutionsSearchResult.length >= 5 ? 5 : institutionsSearchResult.length
            const institutionsSearchResultHeight = lengthSearchArray * rowHeight;
            const checkAbilityDisplayBottom = institutionsSearchResultHeight + this.initialTopPosition < innerHeight;
            if (!checkAbilityDisplayBottom) {
                current.style.top = `-${institutionsSearchResultHeight + 48}px`;
            } else {
                current.style.top = 'unset';
            }
        } else {
            current.style.top = 'unset';
        }
    }

    render(): React.ReactNode {
        const { inputValue, id, type, showInstitutionCustom, setCategory, selectItem } = this.props;
        const { institutionsSearchResult, loadingSearchResult, institutionName } = this.props.value;
        let element;
        if ((institutionsSearchResult || []).length && inputValue.length && !institutionName) {
            element = institutionsSearchResult.map((item, index, array) => <InstitutionSearchResultItem key={item.id}
                institutionCaterotyType={type} isLast={array.length === index + 1} selectItem={selectItem}
                id={item.id} institutionCaterotyId={id} {...item} loading={loadingSearchResult} setCategory={setCategory} />)
        }
        if (inputValue.length === 0 || (inputValue.length && (institutionsSearchResult || []).length === 0) && !institutionName) {
            element = <InstitutionSearchResultEmpty searchResults={institutionsSearchResult} selectItem={selectItem} id={id} inputValue={inputValue}
                loading={loadingSearchResult} showInstitutionCustom={showInstitutionCustom} />
        }
        return (
            <div ref={this.parentWrap} style={this.styleParentWrap} className="parent-wrap" id='parentWrap' onScroll={this.onSearchResultScroll}>
                <div className="results-wrap">
                    {element}
                </div>
            </div>
        );
    }
} 