import React, { PureComponent } from "react";
import { InstitutionCategoryType } from "../../models/institution/institution-category";
import { InstitutionSearchItem } from '../../models/institution/institution-search-item';

export interface InstitutionSearchResultItemProps extends InstitutionSearchItem {
    institutionCaterotyId: string,
    institutionCaterotyType: InstitutionCategoryType,
    isLast: boolean;
    loading: boolean;
    setCategory: (id: string, value: string, name: string) => void,
    selectItem: () => void
}


export class InstitutionSearchResultItem extends PureComponent<InstitutionSearchResultItemProps> {


    constructor(props) {
        super(props);
        this.onSelectItem = this.onSelectItem.bind(this);
    }

    getAddress(state: string | undefined, city: string | undefined) {
        if (!!state && !!city) {
            return `${city}, ${state}`
        }
        if (!state && city) {
            return `${city}`;
        }
        if (state && !city) {
            return `${state}`;
        }
        return '';
    }

    onSelectItem() {
        const { setCategory, institutionCaterotyId, id, name } = this.props;
        setCategory(institutionCaterotyId, id, name);
        this.props.selectItem();
    }

    render() {
        const { state, city, categoryName, name, isLast } = this.props;
        const searchItemMediaStyleClasses = isLast ? 'search-item-media' : 'search-item-media search-item-divider'
        const getInitialChar = (name: string) => { return name.substring(0, 1) };
        return (
            <div className='search-item-wrap' onClick={this.onSelectItem}>
                <div className={searchItemMediaStyleClasses}>
                    <div className='search-item-media-container-wrap'>
                        <div className="search-item-media-avatar">{getInitialChar(name)}</div>
                        <div className="search-item-info">
                            <div className="search-item-info-name">{name}</div>
                            <div className="search-item-info-category">{categoryName}</div>
                        </div>
                    </div>
                    <div className="search-item-address-wrap">
                        <div className="search-item-address">{city}</div>
                        <div className="search-item-address">{state}</div>
                    </div>
                </div>
            </div>
        );
    }
}