import {
    ON_SPORT_STATS_FIELD_CHANGE, ON_SPORT_STATS_FIELD_BLUR, SET_SPORT_STATS_FIELD_VALIDATORS,
    SET_SPORT_STATS_FIELD_VALUES, SET_SPORT_STATS_FIELD_SETTINGS, CHECK_MULTIPLE_EVENT, SET_MULTIPLE_EVENT, SET_SPORT_STATS_FIELD_ERRORS
} from "./constants";
import { Validator } from "../../utilities/ValidatorUtility";
import { FieldGroup } from "../../models/fields/field-group";


export interface onSportStatsFieldChange {
    type: typeof ON_SPORT_STATS_FIELD_CHANGE;
    payload: {
        id: string;
        value: any;
    }
}

export interface onSportStatsFieldBlur {
    type: typeof ON_SPORT_STATS_FIELD_BLUR;
    payload: {
        id: string;
        value: any;
    }
}

export interface setSportStatsFieldValidators {
    type: typeof SET_SPORT_STATS_FIELD_VALIDATORS;
    payload: {
        validators: { [id: string]: Validator[] }
    }
}

export interface setSportStatsFieldValues {
    type: typeof SET_SPORT_STATS_FIELD_VALUES;
    payload: {
        values: { [id: string]: any }
    }
}

export interface setSportStatsFieldSettings {
    type: typeof SET_SPORT_STATS_FIELD_SETTINGS;
    payload: {
        groups: FieldGroup[]
    }
}

export interface checkMultipleEvent {
    type: typeof CHECK_MULTIPLE_EVENT;
    payload: {
        id: string
    }
}

export interface setMultipleEvent {
    type: typeof SET_MULTIPLE_EVENT;
    payload: {
        multipleEventChecked: { [id: string]: boolean };
        multipleEvent: boolean;
    }
}

export interface setSportStatsErrors {
    type: typeof SET_SPORT_STATS_FIELD_ERRORS;
    payload: {
        errors: { [id: string]: boolean }
    }
}

export type SportStatsFieldAction = onSportStatsFieldChange | onSportStatsFieldBlur | setSportStatsFieldValidators |
    setSportStatsFieldValues | setSportStatsFieldSettings | checkMultipleEvent | setMultipleEvent | setSportStatsErrors;

export class SportStatsFieldActions {

    public static onSportStatsFieldChange(id: string, value: any) {
        return {
            type: ON_SPORT_STATS_FIELD_CHANGE,
            payload: {
                id,
                value
            }
        }
    }

    public static onSportStatsFieldBlur(id: string, value: any) {
        return {
            type: ON_SPORT_STATS_FIELD_BLUR,
            payload: {
                id,
                value
            }
        }
    }

    public static setSportStatsFieldValidators(validators: { [id: string]: Validator[] }) {
        return {
            type: SET_SPORT_STATS_FIELD_VALIDATORS,
            payload: {
                validators
            }
        }
    }

    public static setSportStatsFieldValues(values: { [id: string]: any }) {
        return {
            type: SET_SPORT_STATS_FIELD_VALUES,
            payload: {
                values
            }
        }
    }

    public static setSportStatsFieldSettings(groups: FieldGroup[]) {
        return {
            type: SET_SPORT_STATS_FIELD_SETTINGS,
            payload: {
                groups
            }
        }
    }

    public static checkMultipleEvent(id: string) {
        return {
            type: CHECK_MULTIPLE_EVENT,
            payload: {
                id
            }
        }
    }

    public static setMultipleEvent(multipleEvent: boolean, multipleEventChecked: { [id: string]: boolean }) {
        return {
            type: SET_MULTIPLE_EVENT,
            payload: {
                multipleEvent,
                multipleEventChecked
            }
        }
    }

    public static setSportStatsFieldErrors(errors: { [id: string]: boolean }) {
        return {
            type: SET_SPORT_STATS_FIELD_ERRORS,
            payload: {
                errors
            }
        }
    }
}