import { connect } from 'react-redux';
import { AnyAction } from "redux";
import { StoreState } from "../../store/configureStore";
import { SocialActions } from './actions';
import { Social } from '../../components/Info/Social';
import { blurActionCreator } from '../actionCreators';
import { ThunkDispatch } from 'redux-thunk';
import { PartialUpdateTypes } from '../../constants/Constants';
import { PartialUpdateInfo } from '../../models/partial-update/partial-update-info';

const mapStateToProps = (state: StoreState, ownProps: any) => {
    const id: string = ownProps.id;
    return {
        ...ownProps,
        valid: !state.socials.socialErrors[id],
        value: state.socials.socialValues[id]
    }
}

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>) => {
    return {
        onBlur: (id: string, value: any) => dispatch(blurActionCreator(SocialActions.onSocialBlur(id, value), PartialUpdateTypes.Social, {} as PartialUpdateInfo)),
        onChange: (id: string, value: any) => dispatch(SocialActions.onSocialChange(id, value))
    }
}

const SocialContainer = connect(mapStateToProps, mapDispatchToProps)(Social);

export default SocialContainer;