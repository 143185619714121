import { FieldGroup } from '../models/fields/field-group';
import { FieldGroupSubType } from "../models/api-models/fields/field-group-sub-type";
import { FieldGroupType } from "../models/api-models/fields/field-group-type";

export const keepBlank = 'keepBlank';

export const contactFields = {
    firstName: 'First Name',
    lastName: 'Last Name',
    email: 'Email',
    phone: 'Cell phone',
    phoneId: 'cellPhone'
}

export const twitter = 'Twitter';
export const discord = 'Discord';

export const smsStatusFieldId = 'smsStatus';

export const excludeFromMultipleEvent = ['general'];

export enum QuestionnaireStatus {
    Form = 'form',
    Preview = 'preview'
}

export enum PartialUpdateTypes {
    Avatar = 0,
    SmsStatus,
    Contact,
    Field,
    Files,
    Footages,
    Institutions,
    Positions,
    Social,
    Submit,
    SubmitUpdateOnly   
}

export enum HttpErrorCode {
    BadRequest = 400,
    InternalServerError = 500,
}

export const institutionFieldGroup: FieldGroup = {
    id: 'organization',
    name: 'Organization',
    nameId: 'organization',
    description: '',
    show: true,
    groupType: FieldGroupType.Bio,
    groupSubType: FieldGroupSubType.Other,
    fields: [],
}

export const institutionFieldGroupOrder = 5;

export const AcademicInfoNameId = 'AcademicInfo';
export const OthersNameId = 'Others';