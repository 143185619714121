import React from "react";
import { Input } from '@progress/kendo-react-inputs';
import { FieldProps } from "../../models/fields/field-props";
import { FieldHeader } from "../Common/FieldHeader";


export class ShortStringField extends React.PureComponent<FieldProps<string>> {

    constructor(props: FieldProps<string>) {
        super(props);
        this.onBlur = this.onBlur.bind(this);
        this.onChange = this.onChange.bind(this);
    }

    onBlur(event: React.FocusEvent<HTMLInputElement>) {
        const { onBlur, id, value } = this.props;
        onBlur(id, value);
    }

    onChange(event: React.ChangeEvent<HTMLInputElement> | any) {
        const { onChange, id } = this.props;
        const caret = event.target.element.selectionStart
        const element = event.target.element
        window.requestAnimationFrame(() => {
          element.selectionStart = caret
          element.selectionEnd = caret
        })

        onChange(id, event.target.value);
    }

    render() {
        const { name, required, value, valid, disabled, inline,  description, isNicknameRequired: isNicknameRequired,  event } = this.props;
        const maxLength = this.props.maxLength ? this.props.maxLength : 256;
        const inputClassName = inline
            ? ' col-10 col-md-10' : !isNicknameRequired ? 'w-100' : "";
        
        return <React.Fragment>
            {!!name && <FieldHeader required={required} inline={inline} name={name} description={description} />}
            {!isNicknameRequired &&
                <Input maxLength={maxLength}
                    className={inputClassName}
                    onBlur={this.onBlur}
                    onChange={this.onChange}
                    value={value}
                    valid={valid}
                    disabled={disabled} />}
            {isNicknameRequired &&
                <div className="input-group">
                    <span className="input-group-prepend">
                        <span className="input-group-text" id="basic-addon1">@</span>
                    </span>
                    <Input maxLength={maxLength}
                        className={inputClassName + ' form-control box-shadow-none'}
                        onBlur={this.onBlur}
                        onChange={this.onChange}
                        value={value}
                        valid={valid}
                        disabled={disabled} />
                </div>
                }
            {this.props.children}
        </React.Fragment>;
    }
}
