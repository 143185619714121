import { connect } from 'react-redux';
import { AnyAction } from "redux";
import { StoreState } from "../../store/configureStore";
import { ContactActions } from './actions';
import { ContactField } from '../../components/Info/ContactField';
import { PartialUpdateTypes, keepBlank } from '../../constants/Constants';
import { blurActionCreator, keepBlankActionCreator } from '../actionCreators';
import { ThunkDispatch } from 'redux-thunk';
import { PartialUpdateInfo } from '../../models/partial-update/partial-update-info';

const mapStateToProps = (state: StoreState, ownProps: any) => {
    const fieldName: string = ownProps.fieldName;
    const contactId: string = ownProps.contactId;
    return {
        ...ownProps,
        valid: !state.contacts.contactErrors[`${contactId};${fieldName}`],
        value: state.contacts.contactValues[`${contactId};${fieldName}`],
        disabled: !!state.contacts.contactValues[`${contactId};${keepBlank}`]
    }
}

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>) => {
    return {
        onBlur: (id: string, value: any) => dispatch(blurActionCreator(ContactActions.onContactBlur(id, value), PartialUpdateTypes.Contact, {fieldId: id } as PartialUpdateInfo )),
        onChange: (id: string, value: any) => dispatch(ContactActions.onContactChange(id, value)),
        onKeepBlank: (id: string, value: any) => dispatch(keepBlankActionCreator(ContactActions.onContactBlur(id, value), id.split(";")[0], value)),
    };
}

const ContactFieldContainer = connect(mapStateToProps, mapDispatchToProps)(ContactField);

export default ContactFieldContainer;