import { InstitutionsValueProps } from "../containers/Institutions/reducers";
import { FieldGroupViewModel } from "../models/api-models/fields/field-group-view-model";
import { FieldValue } from "../models/api-models/fields/field-value";
import { FieldViewModel } from "../models/api-models/fields/field-view-model";
import { QuestionnaireRecruitContact } from "../models/api-models/questionaire-recruit/questionnaire-recruit-contact";
import { QuestionnaireRecruitInfo } from "../models/api-models/questionaire-recruit/questionnaire-recruit-info";
import { PositionListItem } from "../models/api-models/questionnaire/position-list-item";
import { SmsSubscriptionStatus } from "../models/api-models/questionnaire/questionnaire-recruit-create";
import { InstitutionRelationView } from "../models/institution/institution-relation-view";
import { QuestionnaireRecruit } from "../models/questionnaire/questionnaire-recruit";
import { FieldUtility } from "./FieldUtility";
import { discord, twitter } from "../constants/Constants";

export class QuestionnaireRecruitUtility {
    static getQuestionnaireRecruitByInfo(data: QuestionnaireRecruitInfo,
                                        bioSettings: FieldGroupViewModel[],
                                        statsSettings: FieldGroupViewModel[],
                                        socialSettings: FieldGroupViewModel[],
                                        confirmSms: boolean,
                                        institutionValueFields: { [id: string]: InstitutionsValueProps }) : QuestionnaireRecruit {
        return {
            avatarId: data.avatarId,
            bioFieldValues: QuestionnaireRecruitUtility.getBioFieldValuesView(data.bioFieldValues, data.positions, confirmSms, data.consentToReceiveSms, bioSettings),
            sportStatsFieldValues: QuestionnaireRecruitUtility.getFieldValuesView(data.sportStatsFieldValues, statsSettings),
            socialValues: QuestionnaireRecruitUtility.getSocialFieldValuesView(data.socialFieldValues, socialSettings),
            contactValues: QuestionnaireRecruitUtility.getContactsView(data.contacts, bioSettings.find(gr => gr.nameId === 'PersonalInfo')!.fields),
            institutionsValues: QuestionnaireRecruitUtility.getInstitutionsView(data.institutions, institutionValueFields),
            fileValues: {},
            footageValues: {},
            multipleEventChecked: {},
        } as QuestionnaireRecruit;
    }

    static getBioFieldValuesView(fields: FieldValue[], positions: PositionListItem[], confirmSms: boolean, smsStatus: SmsSubscriptionStatus, settings: FieldGroupViewModel[]) : { [id: string]: any } {
        const allFields = settings.reduce((prev, curr) => prev.concat(curr.fields),[] as FieldViewModel[]);
        const result = QuestionnaireRecruitUtility.getFieldValuesView(fields, settings);
        if(positions.length > 0) {
            const field = allFields.find(f => f.nameId === 'positions')!;
            result[field.id] = positions.map(position => position.id)
        }

        if(confirmSms && (smsStatus === SmsSubscriptionStatus.NotCaptured || smsStatus === SmsSubscriptionStatus.Agreed)) {
           result['smsStatus'] = true;
        }
        return result;
  }
    
    static getContactsView(contacts: QuestionnaireRecruitContact[], settings: FieldViewModel[] ) : { [id: string]: string } {
        const result: { [id: string]: string } = {};
        contacts.forEach(contact => {
           contact.fieldValues.forEach(fieldValue => {
              const field = settings.find(x => x.id === fieldValue.fieldId)!;
              result[`${contact.relationId};${field.name}`] = FieldUtility.toRawValue(fieldValue, field.valueType);
           });
        });
        return result;
    }

    static getSocialFieldValuesView(fields: FieldValue[], settings: FieldGroupViewModel[]) : { [id: string]: any } {
      const result: { [id: string]: any } = {};
      const allFields = settings.reduce((prev, curr) => prev.concat(curr.fields),[] as FieldViewModel[]);
      fields.forEach(item => {
         const field = allFields.find(x => x.id === item.fieldId);
         if(field) {
            if(field.nameId === twitter || field.nameId === discord) {
               result[field.id] = item.shortValue;
            } else {
               result[field.id] = item.stringValue;
            }
         }
      });
      return result;
}

    static getInstitutionsView(institutions: InstitutionRelationView[], institutionValueFields: { [id: string]: InstitutionsValueProps }) : { [id: string]: InstitutionsValueProps } {
       const result: { [id: string]: InstitutionsValueProps } =  institutionValueFields;
       institutions.forEach(institution => {
           if(result[institution.institutionCategoryId]) {
              result[institution.institutionCategoryId] = { ...result[institution.institutionCategoryId],
                                                            institutionId: institution.institutionId,
                                                            institutionName: institution.institutionName,
                                                            otherInstitution: institution.otherInstitution,
                                                            showRadioInstitutionOptions: !!institution.otherInstitution,
                                                            useCumtomInstitutionInput: !!institution.otherInstitution,
                                                            } as InstitutionsValueProps;
           }
       });
       return result; 
    }
    
    static getFieldValuesView(fields: FieldValue[], settings: FieldGroupViewModel[]) : { [id: string]: any } {
      const result: { [id: string]: any } = {};
      const allFields = settings.reduce((prev, curr) => prev.concat(curr.fields),[] as FieldViewModel[]);
      fields.forEach(item => {
         const field = allFields.find(x => x.id === item.fieldId);
         if(field) {
            result[field.id] = FieldUtility.toRawValue(item, field.valueType);
         }
      });
      return result;
}
    
}