import { connect } from 'react-redux';
import { AnyAction } from "redux";
import { StoreState } from "../../store/configureStore";
import { FootageActions } from './actions';
import { FootageUpload } from '../../components/Info/FootageUpload';
import { FootageAttachment } from '../../models/footage/footage-attachment';
import { blurActionCreator } from '../actionCreators';
import { ThunkDispatch } from 'redux-thunk';
import { PartialUpdateTypes } from '../../constants/Constants';
import { PartialUpdateInfo } from '../../models/partial-update/partial-update-info';

const mapStateToProps = (state: StoreState, ownProps: any) => {
    const id: string = ownProps.id;
    return {
        ...ownProps,
        ...state.footage.footage.find(x => x.id === id) || [],
        valid: !state.footage.footageErrors[id],
        value: state.footage.footageValues[id]
    }
}

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>) => {
    return {
        onAddFootage: (id: string, value: FootageAttachment) => dispatch(blurActionCreator(FootageActions.onFootageAdd(id, value), PartialUpdateTypes.Footages, {} as PartialUpdateInfo)),
        onRemoveFootage: (id: string, index: number) => dispatch(blurActionCreator(FootageActions.onFootageRemove(id, index), PartialUpdateTypes.Footages, {} as PartialUpdateInfo)),
        setFootageErrors: (errors: { [id: string]: boolean }) => dispatch(FootageActions.setFootageErrors(errors)),
    }
}

const FootgeUploadContainer = connect(mapStateToProps, mapDispatchToProps)(FootageUpload);

export default FootgeUploadContainer;