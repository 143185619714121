import React from "react";
import { CommonFieldSelector } from "../Fields/CommonFieldSelector";
import { RecruitCountryField } from "../Fields/RecruitFields/RecruitCountryField";
import { RecruitHeightField } from "../Fields/RecruitFields/RecruitHeightField";
import { RecruitJerseyNumberField } from "../Fields/RecruitFields/RecruitJerseyNumberField";
import { RecruitStateField } from "../Fields/RecruitFields/RecruitStateField";
import { RecruitWeightField } from "../Fields/RecruitFields/RecruitWeightField";
import { FieldWrapper } from "../Common/FieldWrapper";
import { FieldProps } from "../../models/fields/field-props";
import { ShortStringField } from "../Fields/ShortStringField";
import { RecruitPhoneNumberField } from "../Fields/RecruitFields/RecruitPhoneNumberField";

export class BioFieldSelector extends React.PureComponent<FieldProps<any>> {

    getField(): JSX.Element | undefined {
        if (this.props.isDefault) {
            if (this.props.nameId === 'country') {
                return <RecruitCountryField {...this.props} filter={true} />;
            }
            if (this.props.nameId === 'zipCode') {
                return <ShortStringField {...this.props} maxLength={12} />
            }
            if (this.props.nameId === 'height') {
                return <RecruitHeightField {...this.props} />;
            }
            if (this.props.nameId === 'jerseyNumber' || this.props.nameId === 'highSchoolJersey') {
                return <RecruitJerseyNumberField {...this.props} />
            }
            if (this.props.nameId === 'state') {
                if (this.props.value === 'International') {
                    return <ShortStringField {...this.props} disabled={true} />
                }
                return <RecruitStateField {...this.props} />
            }
            if (this.props.nameId === 'weight') {
                return <RecruitWeightField {...this.props} />
            }
            if (this.props.nameId === 'otherCollegeChoices') {
                return <ShortStringField {...this.props} maxLength={1024}/>
            }
        }
        if (this.props.nameId === 'stateClubTeamInfo') {
            return <RecruitStateField {...this.props} />;
        }
        if (this.props.nameId === 'cellPhone') {
            return <RecruitPhoneNumberField {...this.props} formatNumber={false} />;
        }
        
        if (this.props.nameId === 'contactPhone'
            || this.props.nameId === 'schoolPhone'
            || this.props.nameId === 'coachPhoneClubTeamInfo') {
                return <RecruitPhoneNumberField {...this.props} formatNumber={true} />;
        }
        if (this.props.nameId === 'smsStatus') {
                return <CommonFieldSelector {...this.props} required={false} />;
            }
    }

    render() {
        let field = this.getField();
        return <React.Fragment>
            {field
                ? <FieldWrapper>{field}</FieldWrapper>
                : <CommonFieldSelector {...this.props} />}
        </React.Fragment>;
    }
}
