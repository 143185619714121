import { InstitutionCategory, InstitutionCategoryType } from '../../models/institution/institution-category';
import { InstitutionSearchItem } from '../../models/institution/institution-search-item';
import { Validator } from "../../utilities/ValidatorUtility";
import { 
    SET_INSTITUTION_FIELDS, SET_INSTITUTION_SEARCH_RESULT, SET_INSTITUTION_ITEM,
    SHOW_INSTITUTION_CUSTOM, SWITCH_INSTITUTION_INPUT, CHANGE_CUSTOM_INSTITUTION_INPUT,
    SET_INSTITUTIONS_VALUES, SET_UP_SEARCH_RESULT_LOADING, SET_UP_INSTITUTION_SEARCH_NAME,
    SET_INSTITUTION_VALIDATORS, SET_INSTITUTIONS_ERRORS, SET_NEXT_SEARCH_RESULT_PAGE,
    CONCAT_INSTITUTION_SEARCH_RESULT,
} from './constants';
import { InstitutionsValueProps } from './reducers';

export interface setInstitutionFields {
    type: typeof SET_INSTITUTION_FIELDS,
    payload:  InstitutionCategory[],
}

export interface setUpInstitutionSearchName {
    type: typeof SET_UP_INSTITUTION_SEARCH_NAME,
    payload: { id: string, value: string }
}

export interface setUpSearchResultLoading {
    type: typeof SET_UP_SEARCH_RESULT_LOADING,
    payload: {value: boolean, id: string},
}

export interface switchInstitutionInput {
    type: typeof SWITCH_INSTITUTION_INPUT,
    payload: {value: boolean, id: string}
}

export interface setInstitutionSearchResult {
    type: typeof SET_INSTITUTION_SEARCH_RESULT,
    payload: {searchResult: InstitutionSearchItem[], id: string}
}

export interface setInstitutionValidators {
    type: typeof SET_INSTITUTION_VALIDATORS;
    payload: {
        validators: { [id: string]: Validator[] }
    }
}

export interface setInstitutionItem {
    type: typeof SET_INSTITUTION_ITEM,
    payload: { id: string, value: string, name: string }
}

export interface showInstitutionCustom {
        type: typeof SHOW_INSTITUTION_CUSTOM
        payload: string
}

export interface changeCustomInstitutionInput { 
    type: typeof CHANGE_CUSTOM_INSTITUTION_INPUT,
    payload: { id: string, value: string }
}

export interface setInstitutionsValues {
    type: typeof SET_INSTITUTIONS_VALUES,
    payload: { [id: string]: InstitutionsValueProps}
}

export interface setInstitutionsErrors {
    type: typeof SET_INSTITUTIONS_ERRORS;
    payload: {
        errors: { [id: string]: boolean }
    }
}

export interface concatInstitutionSearchResult {
    type: typeof CONCAT_INSTITUTION_SEARCH_RESULT,
    payload: {searchResult: InstitutionSearchItem[], id: string}
}

export interface setNextSearchResultValue {
    type: typeof SET_NEXT_SEARCH_RESULT_PAGE;
    payload: { id: string, value: number }
}

export type InstitutionsAction = setInstitutionFields | setInstitutionSearchResult | setInstitutionItem | setUpSearchResultLoading | setUpInstitutionSearchName | concatInstitutionSearchResult |
    showInstitutionCustom | switchInstitutionInput | changeCustomInstitutionInput | setInstitutionsValues | setInstitutionValidators | setInstitutionsErrors | setNextSearchResultValue;

export class InstitutionsActions {
    public static setInstitutionSearchResult(searchResult: InstitutionSearchItem[], id: string) {
        return {
            type: SET_INSTITUTION_SEARCH_RESULT,
            payload: {id, searchResult},
        }
    }

    public static setNextSearchResultValue(id: string, value: number) {
        return {
            type: SET_NEXT_SEARCH_RESULT_PAGE,
            payload: {id, value}
        }
    }

    public static concatInstitutionSearchResult(searchResult: InstitutionSearchItem[], id: string) {
        return {
            type: CONCAT_INSTITUTION_SEARCH_RESULT,
            payload: {searchResult, id}
        }
    }

    public static setUpSearchResultLoading(id: string, value: boolean) {
        return {
            type: SET_UP_SEARCH_RESULT_LOADING,
            payload: {id, value}
        }
    }

    public static setInstitutionFields(institutions: InstitutionCategory[]) {
        return {
            type: SET_INSTITUTION_FIELDS,
            payload: institutions
        }
    }

    public static changeCustomInput(id: string, value: string) {
        return {
            type: CHANGE_CUSTOM_INSTITUTION_INPUT,
            payload: {id, value}
        }
    }

    public static setInstitutionItem(id: string, value: string, name: string) {
        return {
            type: SET_INSTITUTION_ITEM,
            payload: {id, value, name}
        }
    }

    public static setInstitutionsValidators(validators: { [id: string]: Validator[] }) {
        return {
            type: SET_INSTITUTION_VALIDATORS,
            payload: {
                validators
            }
        }
    }

    public static showInstitutionCustom(id: string) {
        return {
            type: SHOW_INSTITUTION_CUSTOM,
            payload: id
        }
    }

    public static setUpInstitutionSearchName (id: string, value: string) {
        return {
            type: SET_UP_INSTITUTION_SEARCH_NAME,
            payload: {id, value}
        }
    }

    public static switchInstitutionInput(id: string, value: boolean) {
        return {
            type: SWITCH_INSTITUTION_INPUT,
            payload: {value, id},
        }
    }

    public static setInstitutionsValues(value: { [id: string]: InstitutionsValueProps}) {
        return {
            type: SET_INSTITUTIONS_VALUES,
            payload: value
        }
    }

    public static setInstitutionsErrors(errors: { [id: string]: boolean }) {
        return {
            type: SET_INSTITUTIONS_ERRORS,
            payload: {
                errors
            }
        }
    }
}