import {
    ON_TAB_SELECT,
    SET_QUESTIONNAIRE,
    ON_LOAD_END,
    ON_SUBMIT_END,
    POST_NEW,
    SET_TAB_LIST,
    ON_SUBMIT_STARTED,
    ON_SUBMIT_REJECTED,
    SET_QUESTIONNAIRE_IS_AVAILABLE,
    SET_QUESTIONNAIRE_ERRORS,
    ON_SMSSTATUS_BLUR,
    SHOW_CONFIRMATION_RESET_DIALOG,
    CLOSE_DIALOGS,
    SHOW_DATA_CORRUPT_DIALOG,
    SET_PRTIAL_UPDATE_STATUS,
} from './constants';
import { Questionnaire } from '../../models/questionnaire/questionnaire';
import { Tab } from '../../models/tab/tab';

export interface onTabSelect {
    type: typeof ON_TAB_SELECT,
    payload: {
        index: number;
    }
}

export interface onLoadEnd {
    type: typeof ON_LOAD_END
}

export interface setQuestionnaire {
    type: typeof SET_QUESTIONNAIRE,
    payload: {
        questionnaire: Questionnaire;
    }
}

export interface onSubmitEnd {
    type: typeof ON_SUBMIT_END
}

export interface onSubmitStarted {
    type: typeof ON_SUBMIT_STARTED
}

export interface onSubmitRejected {
    type: typeof ON_SUBMIT_REJECTED
}

export interface postNew {
    type: typeof POST_NEW
}

export interface setTabShowSettings {
    type: typeof SET_TAB_LIST,
    payload: Tab[]
}

export interface onShowResetDialog {
    type: typeof SHOW_CONFIRMATION_RESET_DIALOG;
}

export interface onShowDataCorruptDialog {
    type: typeof SHOW_DATA_CORRUPT_DIALOG;
}

export interface onCloseDialogs {
    type: typeof CLOSE_DIALOGS;
}

export interface setQuestionnaireIsAvailable {
    type: typeof SET_QUESTIONNAIRE_IS_AVAILABLE,
    payload: boolean
}

export interface onSmsStatusBlur {
    type: typeof ON_SMSSTATUS_BLUR;
    payload: {
        id: string;
        value: any;
    }
}

export interface setPartialUpdateStatus {
    type: typeof SET_PRTIAL_UPDATE_STATUS,
    payload: boolean
}

export type QuestionnaireAction = onTabSelect | onLoadEnd | setQuestionnaire | onSubmitEnd | onSubmitRejected | onSubmitStarted | postNew | setTabShowSettings | setQuestionnaireIsAvailable | onSmsStatusBlur | onShowResetDialog | onCloseDialogs | onShowDataCorruptDialog | setPartialUpdateStatus;

export class QuestionnaireActions {

    public static onTabSelect(index: number) {
        return {
            type: ON_TAB_SELECT,
            payload: {
                index: index
            }
        }
    }

    public static onShowResetDialog() {
        return {
            type: SHOW_CONFIRMATION_RESET_DIALOG
        }
    }

    public static onShowDataCorruptDialog() {
        return {
            type: SHOW_DATA_CORRUPT_DIALOG
        }
    }

    public static onCloseDialogs() {
        return {
            type: CLOSE_DIALOGS
        }
    }

    public static onLoadEnd() {
        return {
            type: ON_LOAD_END,
        }
    }

    public static setQuestionnaire(questionnaire: Questionnaire) {
        return {
            type: SET_QUESTIONNAIRE,
            payload: {
                questionnaire
            }
        }
    }

    public static onSubmitEnd() {
        return {
            type: ON_SUBMIT_END,
        }
    }

    public static onSubmitRejected() {
        return {
            type: ON_SUBMIT_REJECTED,
        }
    }

    public static onSubmitStarted() {
        return {
            type: ON_SUBMIT_STARTED,
        }
    }

    public static postNew() {
        return {
            type: POST_NEW,
        }
    }

    public static setTabList(tabList: Tab[]) {
        return {
            type: SET_TAB_LIST,
            payload: [...tabList]
        }
    }

    public static setQuestionnaireIsAvailable(isQuestionnaireAvailable: boolean) {
        return {
            type: SET_QUESTIONNAIRE_IS_AVAILABLE,
            payload: isQuestionnaireAvailable
        }
    }

    public static setQuestionnaireErrors(errors: { [id: string]: boolean }) {
        return {
            type: SET_QUESTIONNAIRE_ERRORS,
            payload: {
                errors
            }
        }
    }

    public static onSmsStatusBlur(id: string, value: any) {
        return {
            type: ON_SMSSTATUS_BLUR,
            payload: {
                id,
                value
            }
        }
    }

    public static setPartialUpdateStatus(partialUpdateInProgress: boolean) {
        return {
            type: SET_PRTIAL_UPDATE_STATUS,
            payload: partialUpdateInProgress,
        }
    }
}
