import * as React from 'react';

export type Props = Partial<{
    backgroundColor: string;
    borderColor: string;
    color: string;
}>;

const defaultProps: Props = {}

const Spinner: React.FC<Props> = (props: Props) => {
    const {
        backgroundColor,
        color
    } = {...props, ...defaultProps};
    const style: React.CSSProperties = {
        borderColor: backgroundColor,
        borderTopColor: color
    }
    return (
        <div className="initializing-spinner" style={style}></div>
    );
}

export default Spinner;