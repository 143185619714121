import React from "react";
import { FieldValueType } from "../../models/api-models/fields/field-value-type";
import { NumberField } from "./NumberField";
import { ShortStringField } from "./ShortStringField";
import { LongStringField } from "./LongStringField";
import { DateTimeOffsetField } from "./DateTimeOffsetField";
import { DropdownField } from "./DropdownField";
import { MultiselectField } from "./MultiselectField";
import { BoolField } from "./BoolField";
import { TimeResultField } from "./TimeResultField";
import { EventsField } from "./EventsField";
import { TimeDateField } from "./TimeDateField";
import { ResultDateField } from "./ResultDateField";
import { UrlField } from "./UrlField";
import { FieldWrapper } from "../Common/FieldWrapper";
import { FieldProps } from "../../models/fields/field-props";


export class CommonFieldSelector extends React.PureComponent<FieldProps<any>> {

    getField(): JSX.Element | undefined {
        switch (this.props.valueType) {
            case FieldValueType.Number:
                return <NumberField {...this.props} />
            case FieldValueType.ShortString:
                return <ShortStringField {...this.props} />
            case FieldValueType.LongString:
                return <LongStringField {...this.props} />
            case FieldValueType.DateTimeOffset:
                return <DateTimeOffsetField {...this.props} />
            case FieldValueType.Dropdown:
                return <DropdownField {...this.props} />
            case FieldValueType.Multiselect:
                return <MultiselectField {...this.props} />
            case FieldValueType.Bool:
                return <BoolField {...this.props} />
            case FieldValueType.TimeResult:
                return <TimeResultField {...this.props} />
            case FieldValueType.Events:
                return <EventsField {...this.props} />
            case FieldValueType.TimeDate:
                return <TimeDateField {...this.props} />
            case FieldValueType.ResultDate:
                return <ResultDateField {...this.props} />
            case FieldValueType.Url:
                return <UrlField {...this.props} />
        }
    }

    render() {
        return <FieldWrapper>{this.getField()}</FieldWrapper>;
    }
}