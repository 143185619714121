import React from "react";
import { Dialog as KendoDialog } from "@progress/kendo-react-dialogs";

export interface DialogProps {
    height: number | string;
    text: string;
    onAction?: () => unknown;
    onCancel?: () => unknown;
    actionBtnLabel?: string;
}

export const Dialog = (props: DialogProps) =>  {
    const { text, height, onAction, onCancel, actionBtnLabel } = props;
    const showActionBtn = typeof onAction !== 'undefined';
    const showOnCancelBtn = typeof onCancel !== 'undefined';
    const dialogActionsContainerCssClass = !showOnCancelBtn ? 'justify-end' : 'justify-between' 
    return (
        <React.Fragment>
            <KendoDialog height={height}>
                <p className="dialog-text">{text}</p>
                <div className={`dialog-actions-container ${dialogActionsContainerCssClass}`}>
                    { 
                        showActionBtn && 
                        <button className={"k-button k-button-md k-rounded-md k-button-solid k-button-solid-base dialog-action"} type={'button'} onClick={onAction}>
                            {actionBtnLabel ? actionBtnLabel : 'Yes'}
                        </button>
                    }
                    {
                        showOnCancelBtn && 
                        <button className={"k-button k-button-md k-rounded-md k-button-solid k-button-solid-base dialog-action"} type={'button'} onClick={onCancel}>
                            No
                        </button>
                    }
                </div>
            </KendoDialog>
        </React.Fragment>  
    )
}