import React, { ChangeEvent } from "react";
import { FieldHeader } from "../Common/FieldHeader";


export interface MultipleEventChoiceItemProps {
    name: string;
    id: string;
    onCheck: (id: string) => void;
    checked: boolean;
    disabled: boolean;
} 


export class MultipleEventChoiceItem extends React.Component<MultipleEventChoiceItemProps> {

    constructor(props: MultipleEventChoiceItemProps) {
        super(props);

        this.onClick = this.onClick.bind(this);
    }

    onClick(e: ChangeEvent<HTMLInputElement>) {
        const { onCheck, id } = this.props;
        onCheck(id);
    }

    render() {
        const { name, checked, disabled, id } = this.props;
        return <div className="row panel-item">
            <div className="col-12">
                <div className="bool-field">                  
                    <input className="k-checkbox" type="checkbox" checked={checked} disabled={disabled} onChange={this.onClick} id={id} />
                    <label className="k-checkbox-label" htmlFor={id}></label>
                    <FieldHeader for={id} name={name} />
                </div>
            </div>
        </div>;
    }
}
