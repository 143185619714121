import { contactFields } from "../constants/Constants";
import { QuestionnaireItem } from "../models/api-models/questionnaire/questionnaire-item";
import { QuestionnaireItemType } from "../models/api-models/questionnaire/questionnaire-item-type";
import { FieldGroup } from "../models/fields/field-group";
import { Tab } from "../models/tab/tab";

export class QuestionnaireUtility {
    static parseSettings(settings: string): { id: string }[] {
        return settings
            ? JSON.parse(settings) as { id: string }[]
            : [];
    }

    static getTabList(items: QuestionnaireItem[], personalInfo: FieldGroup | undefined): Tab[] {
        if (items.length === 0) {
            return [];
        }
        let sortedItems = items.sort((a, b) =>
            a.lexoOrder > b.lexoOrder ? 1
                : a.lexoOrder < b.lexoOrder ? -1 : 0
        );
        
        if(personalInfo) {
            const cellPhonefield = personalInfo.fields.find(x => x.nameId === contactFields.phoneId);
            if (cellPhonefield) {
                const phoneItem = items.find(x => x.type === QuestionnaireItemType.BioField && x.fieldInfo.id === cellPhonefield.id);
                if(phoneItem) {
                     const smsStatusItem = {
                        id: 'smsStatus',
                        type: QuestionnaireItemType.BioField,
                        fieldInfo: { id: 'smsStatus'},
                     } as QuestionnaireItem;
                    items.splice(items.indexOf(phoneItem) + 1, 0, smsStatusItem); 
                }
            }
        }
        const result: Tab[] = [];
        const tabCount = sortedItems.filter(x => x.type === QuestionnaireItemType.Tab).length;
       
        
        // for Bad questionnaire structure 
        if(sortedItems[0]!.type !== QuestionnaireItemType.Tab) {
           if(tabCount === 0) {
              result.push({ title: 'Bio', items: [...sortedItems]});
              return result;
           }
              const innerTab = sortedItems.find(x => x.type === QuestionnaireItemType.Tab)!;
              const innerTabIndex = sortedItems.indexOf(innerTab);
              result.push({ title: 'Bio', items: [ ...sortedItems.slice(0, innerTabIndex)] });
              sortedItems = sortedItems.slice(innerTabIndex);
        }

        for (let i = 0; i < tabCount; i++) {
            const tabStart = sortedItems.splice(0,1);
            const tabEnd = sortedItems.find(x => x.type === QuestionnaireItemType.Tab);
            if (!tabEnd) {
                result.push({ title: tabStart[0].label, items: [tabStart[0], ...sortedItems] });
                break;
            }
            const endIndex = sortedItems.indexOf(tabEnd);
            result.push({ title: tabStart[0].label, items: [tabStart[0], ...sortedItems.slice(0, endIndex)] });
            sortedItems = sortedItems.slice(endIndex);
        }
        return result;
    }
}