import { ApiConfigs } from "../configs/ApiConfigs";
import { HttpUtility } from "../utilities/HttpUtility";
import { Api } from "./Api";

export class OrganizationApi {
  private static get organizationApiUrl(): string {
    return HttpUtility.combine(ApiConfigs.organizationApiUrl, 'v1/uploads');
  }

  static async upload(mediaId: string, name: string, email: string, fileName: string): Promise<void> {
    let url = HttpUtility.combine(this.organizationApiUrl);
    url = HttpUtility.addParameter(url, 'mediaId', mediaId);
    url = HttpUtility.addParameter(url, 'name', name);
    url = HttpUtility.addParameter(url, 'email', email);
    url = HttpUtility.addParameter(url, 'fileName', fileName);
    await Api.post<string>(url, {});
  }

}