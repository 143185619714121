import {Contact} from "../models/contacts/contact";
import {Social} from "../models/socials/social";
import {File} from "../models/files/file";
import { Footage } from "../models/footage/footage";
import {FieldGroupViewModel} from "../models/api-models/fields/field-group-view-model";
import {PositionListItem} from "../models/api-models/questionnaire/position-list-item";
import {FieldGroup} from "../models/fields/field-group";
import {FieldGroupType} from "../models/api-models/fields/field-group-type";
import {Validator, ValidatorUtility} from "../utilities/ValidatorUtility";
import {FieldValueType} from "../models/api-models/fields/field-value-type";
import {contactFields, keepBlank, smsStatusFieldId, twitter} from "../constants/Constants";
import {ToastService} from "../services/ToastService";
import {SocialState} from "./Social/reducers";
import {QuestionnaireSocial} from "../models/api-models/questionnaire/questionnaire-social";
import {SocialNetworkUtility} from "../utilities/SocialNetworkUtility";
import {FileState} from "./FileUpload/reducers";
import {FileAttachment} from "../models/files/file-attachment";
import {ContactState} from "./Contact/reducers";
import {QuestionnaireRecruitContact} from "../models/api-models/questionnaire/questionnaire-recruit-contact";
import {QuestionnaireRecruitContact as QuestionnaireRecruitContactInfo } from "../models/api-models/questionaire-recruit/questionnaire-recruit-contact";
import {SportStatsFieldState} from "./SportStatsField/reducers";
import {FieldValue} from "../models/api-models/fields/field-value";
import {FieldUtility} from "../utilities/FieldUtility";
import {BioFieldState} from "./BioField/reducers";
import {GenderListItem} from "../models/api-models/questionnaire/gender-list-item";
import { FootageState } from "./FootageUpload/reducers";
import { FootageAttachment } from "../models/footage/footage-attachment";
import { InstitutionsValueProps } from "../containers/Institutions/reducers";
import { InstitutionCategory } from "../models/institution/institution-category";
import { InstitutionRelation } from "../models/institution/institution-relation"
import { ContactMetadataItem, QuestionMetadata, QuestionnaireItem, SocialProfileMetadataItem, SportStatMetadataItem } from "../models/api-models/questionnaire/questionnaire-item";
import { QuestionnaireItemType } from "../models/api-models/questionnaire/questionnaire-item-type";
import { Field } from "../models/fields/field";

export function getContacts(contactItems: QuestionnaireItem[], personalInfo: FieldGroupViewModel): Contact[] {

    if (!contactItems || contactItems.length === 0)
        return [];
    contactItems.forEach(item => item.contactInfo = JSON.parse(item.metadataJson) as ContactMetadataItem)
    let contacts: Contact[] = contactItems.map(c => {
        return {
            id: c.contactInfo.relationId,
            label: c.label,
            description: c.description,
            relationId: c.contactInfo.relationId,
            contactFields: c.contactInfo.fields!.map(cf => {
                return {
                    name: cf.title,
                    fieldName: personalInfo.fields.find(x => x.id === cf.fieldId)!.name || '',
                    fieldId: cf.fieldId,
                    required: cf.isRequired
                }
            })
        };
    });
    return contacts;
}

export function getSocials(socialItem: QuestionnaireItem,  fields: FieldGroupViewModel[]): Social[] {
    if(!socialItem) {
        return [];
    }
    socialItem.socialInfo = JSON.parse(socialItem.metadataJson) as SocialProfileMetadataItem[];
    const socialFields = fields[0].fields;
    let social: Social[] = socialItem.socialInfo.map(s => {
        return {
            id: s.fieldId,
            title: socialFields.find(x => x.id === s.fieldId)!.name,
            required: s.isRequired
        };
    });
    return social;
}
export function getFiles(filesItems: QuestionnaireItem[]): File[] {
    let files: File[] = filesItems.map(f => {
        return {
            id: f.id,
            title: f.label,
            description: f.description,
            required: f.isRequired
        }
    });
    return files;
}

export function getFootage(footageItems: QuestionnaireItem[]): Footage[] {
    let footage: Footage[] = footageItems.map(el => {
        return {
            id: el.id,
            title: el.label,
            description: el.description,
            required: el.isRequired
        }
    });

    return footage;
}

function mapToInstitutionsValueProps(x: QuestionnaireItem, orgCategories: InstitutionCategory[]): InstitutionsValueProps {
    return {
        showRadioInstitutionOptions: false,
        useCumtomInstitutionInput: false,
        institutionId: undefined,
        otherInstitution: undefined,
        institutionCategoryName: x.label,
        isRequired: x.isRequired,
        description: x.description,
        valid: true,
        institutionCategory: orgCategories.find(category => category.id === x.fieldInfo.id)!.type,
        institutionsSearchResult: [],
        loadingSearchResult: false,
        institutionName: undefined,
        institutionSearchName: '',
        page: 1,
        pageSize: 20,
    }
}

export function getInstitutionFields(orgItems: QuestionnaireItem[], orgCategories: InstitutionCategory[]) {
    let result: { [id: string]: InstitutionsValueProps } = {}
    orgItems.forEach(x => {
        x.fieldInfo = JSON.parse(x.metadataJson) as QuestionMetadata;
        result[x.fieldInfo.id!] = mapToInstitutionsValueProps(x, orgCategories);
    });
    return result;
}

function mapToInstitutionRelations(institutionCategoryId: string, value: InstitutionsValueProps): InstitutionRelation | undefined {

    if(value.useCumtomInstitutionInput) {
        return !!value.otherInstitution ? 
        {
            institutionCategoryId: institutionCategoryId,
            otherInstitution: value.otherInstitution
        }
        : undefined;
    } else {
        return !!value.institutionId ? 
        {
            institutionId: value.institutionId,
            institutionCategoryId: institutionCategoryId
        }
        : undefined;
    }
}


export function getInstitutionRelations(values: {[id: string]: InstitutionsValueProps}) {
    let relations: InstitutionRelation[] = [];
    Object.keys(values).forEach(x => {
        const result: InstitutionRelation  = mapToInstitutionRelations(x, values[x]);
        if(result)
            relations.push(result);
    });
    return relations;
}


interface FieldSettings {
    id: string;
    label: string;
    groupId: string;
    description: string;
}

export function getFields(questionnaireItems: QuestionnaireItem[], fieldGroups: FieldGroupViewModel[],
    positions?: PositionListItem[], genders?: GenderListItem[]): FieldGroup[] {
    if (!questionnaireItems || questionnaireItems.length === 0) {
        return [];
    }
    let settingsArray: FieldSettings[] = [];
    let requiredArray: { id: string }[] = [];
    if (questionnaireItems[0].type === QuestionnaireItemType.BioField) {
        questionnaireItems.forEach(x => x.fieldInfo = JSON.parse(x.metadataJson) as QuestionMetadata);
        settingsArray = questionnaireItems.map(x => ({ id: x.fieldInfo.id!, groupId: '', label: x.label, description: x.description }));
        requiredArray = questionnaireItems.filter(x => x.isRequired).map(x => ({ id: x.fieldInfo.id! }));
    } else if (questionnaireItems[0].type === QuestionnaireItemType.StatsField) {
        questionnaireItems.forEach(x => x.sportStatsInfo = JSON.parse(x.metadataJson) as SportStatMetadataItem);
        settingsArray = questionnaireItems.reduce((prev, current) => [...prev, ...current.sportStatsInfo.fields!.map(f => ({id: f.id, groupId: current.sportStatsInfo.groupId, label: current.label, description: current.description } as FieldSettings))], [] as FieldSettings[] );
        requiredArray = questionnaireItems.reduce((prev, current) => [...prev, ...current.sportStatsInfo.fields!.filter(x => x.isRequired).map(f => ({id: f.id!}))], [] as {id: string }[]); 
    }
    let groups: FieldGroup[] = fieldGroups.filter(g => g.fields.some(f => settingsArray.some(bs => bs.id === f.id)))
        .sort((a, b) => a.order - b.order).map(g => {
            return {
                id: g.id,
                name: g.groupType === FieldGroupType.SportStats ?  settingsArray.find(x => x.groupId === g.id)!.label : g.name,
                description: g.groupType === FieldGroupType.SportStats ?  settingsArray.find(x => x.groupId === g.id)!.description : '',
                nameId: g.nameId,
                groupType: g.groupType,
                groupSubType: g.groupSubType,
                show: true,
                fields: g.fields.filter(f => settingsArray.some(bs => bs.id === f.id) && !f.isReadonly && f.nameId != 'highSchool' /*ARI-5254 delete legacy field*/ && f.nameId != 'status' /*ARI-6431 'Stage' can be only set by Coach*/).sort((a, b) => a.order - b.order)
                    .map(f => {
                        let options: { id: any, title: string }[] = [];
                        if (f.options) {
                            options = (JSON.parse(f.options) || []).map(x => ({id: x, title: x}));
                        }
                        if (g.groupType === FieldGroupType.Bio && f.isDefault && f.nameId === 'positions' && positions) {
                            options = positions;
                        }
                        if (g.groupType === FieldGroupType.Bio && f.isDefault && f.nameId === 'genderId' && genders) {
                            options = genders
                        }
                        return {
                            id: f.id,
                            groupId: f.groupId,
                            name: g.groupType === FieldGroupType.Bio ?  settingsArray.find(x => x.id === f.id)!.label : f.name,
                            description: g.groupType === FieldGroupType.Bio ? settingsArray.find(x => x.id === f.id)!.description : '', 
                            nameId: f.nameId,
                            valueType: f.valueType,
                            isDefault: f.isDefault,
                            required: requiredArray.some(r => r.id === f.id),
                            options: options,
                            show: true
                        };
                    })
            };
        }).filter(g => !!g.fields && g.fields.length > 0);
    return groups;
}

export function getCheckedStats(questionnaireItems: QuestionnaireItem[]) : { [id: string]: boolean } {
    let checkedFields : { [id: string]: boolean } = {};
    const requiredFields = questionnaireItems.reduce((prev, current) => [...prev, ...current.sportStatsInfo.fields!.filter(x => x.isRequired).map(f => ({id: f.id!}))], [] as {id: string }[]);
    requiredFields.forEach(field => {
        checkedFields[field.id] = true;
    });
    return checkedFields;
}

export function getFieldValidators(fieldGroups: FieldGroup[]): { [id: string]: Validator[] } {
    let fieldValidators: { [id: string]: Validator[] } = {};
    fieldGroups.forEach(g => {
        g.fields.forEach(f => {
            fieldValidators[f.id] = ValidatorUtility.getFieldValidators(f, g);
        });
    });
    return fieldValidators;
}

export function getSocialValidators(socials: Social[]): { [id: string]: Validator[] } {
    let socialValidators: { [id: string]: Validator[] } = {};
    socials.forEach(s => {
        socialValidators[s.id] =
            s.title !== 'Twitter' && s.title !== 'Discord' ?
                (s.required ?
                [ValidatorUtility.getRequiredValidator(FieldValueType.Url, s.title), ValidatorUtility.getUrlValidator(s.title)]
                        : [ValidatorUtility.getUrlValidator(s.title)])
                : (s.required ?
                    [ValidatorUtility.getRequiredValidator(FieldValueType.Url, s.title)]
                    : [])
    });
    return socialValidators;
}

export function getFileValidators(files: File[]): { [id: string]: Validator[] } {
    let fileValidators: { [id: string]: Validator[] } = {};
    files.forEach(f => {
        fileValidators[f.id] = f.required ? [ValidatorUtility.getFileRequiredValidator(f.title)] : []
    });
    return fileValidators;
}

export function getInstitutionsValidators(categories: { [id: string] :InstitutionsValueProps }): { [id: string]: Validator[] } {
    let institutionsValidators: { [id: string]: Validator[] } = {};
    Object.keys(categories).forEach(f => {
        institutionsValidators[f] = categories[f].isRequired ? [ValidatorUtility.getInstitutionsRequiredValidator(categories[f].institutionCategoryName)] : []
    });
    return institutionsValidators;
}

export function getFootageValidator(footage: Footage[]): { [id: string]: Validator[] } {
    let footageValidators: { [id: string]: Validator[] } = {};
    footage.forEach(f => {
        footageValidators[f.id] = f.required ? [ValidatorUtility.getFileRequiredValidator(f.title)] : []
    });
    return footageValidators;
}

export function getContactValidators(contacts: Contact[]): { [id: string]: Validator[] } {
    let contactValidators: { [id: string]: Validator[] } = {};
    contacts.forEach(c => {
        c.contactFields.forEach(f => {
            if (f.required) {
                contactValidators[`${c.relationId};${f.fieldName}`] = [ValidatorUtility.getRequiredValidator(FieldValueType.ShortString, `${c.label}: ${f.name}`)]
            } else {
                contactValidators[`${c.relationId};${f.fieldName}`] = []
            }
            if (f.fieldName=== "Email") {
                contactValidators[`${c.relationId};${f.fieldName}`].push(ValidatorUtility.getEmailValidator(`${c.label}: ${f.name}`));
            }
        });
        contactValidators[`${c.relationId};${keepBlank}`] = [];
    });
    return contactValidators;
}

export function validate(validators: Validator[], value: any): boolean {
    for (let validator of validators) {
        if (!validator.isValid(value)) {
            ToastService.error(validator.errorMessage);
            return true;
        }
    }
    return false;
}

export function customValidationMessage(message: string) {
    ToastService.error(message);
}


export function validateAll(validators: { [id: string]: Validator[] }, values: { [id: string]: any }): { [id: string]: boolean } {
    let errors = {};
    Object.keys(validators).forEach(key => {
        errors[key] = validate(validators[key], values[key]);
    });
    return errors;
}

export function validateContacts(validators: { [id: string]: Validator[] }, values: { [id: string]: any }, contacts: Contact[]): { [id: string]: boolean } {
    let errors = {};
    Object.keys(validators).forEach(key => {
        const [relationId, fieldName] = key.split(';');
        const getConnectedValues = Object.keys(values).filter(x => x.includes(relationId) && (!!values[x]));
        if (getConnectedValues.some(x => x.includes('keepBlank'))) {
            return;
        }
        if (getConnectedValues.length > 0) {
            errors[key] = validate(validators[key], values[key]);
        } else {
            errors[key] = validate(validators[key], null);
        }
        if (fieldName === 'First Name' && validators[key].length === 0 &&
            ((values[key] && values[key].trim() === '') || values[key] === undefined)) {
            const hasValuesExcludingFirstName = Object.keys(values)
                .filter((key) => key.includes(relationId) && !key.includes('First Name') && !!values[key])
                .reduce((obj, key) => {
                    return Object.assign(obj, {
                        [key]: values[key]
                    });
                }, {});
            if (Object.keys(hasValuesExcludingFirstName).length !== 0) {
                errors[key] = true;
                customValidationMessage(`Field '${relationId}: ${fieldName}' is required`);
            }
        }
    });
    return errors;
}

export function getSocialValues(state: SocialState): QuestionnaireSocial[] {
    let socials: QuestionnaireSocial[] = [];
    state.socials.forEach(s => {
        let value = state.socialValues[s.id];
        if (s.title === twitter && !!value) {
            value = (value as string).startsWith('@') ? (value as string).slice(1) : value;
        }
        const isUrl = SocialNetworkUtility.checkUrl(value);
        const isOnlyLoginRequired = SocialNetworkUtility.isOnlyLoginRequired(s.title);
        if (!!value) {
            let socialProfile: QuestionnaireSocial = {
                socialNetworkId: s.id,
                url: isOnlyLoginRequired ? value : isUrl ? value : SocialNetworkUtility.generateUrl(value, s.title),
                login: isUrl ? SocialNetworkUtility.getLoginFromUrl(value) : value
            }
            socials.push(socialProfile);
        }
    });
    return socials;
}

export function getFileValues(state: FileState): FileAttachment[] {
    let files: FileAttachment[] = [];
    state.files.forEach(f => {
        let fileValues = (state.fileValues[f.id] || []).filter(x => !!x.name.trim());
        if (fileValues && fileValues.length !== 0) {
            files.push(...fileValues);
        }
    });
    return files;
}

export function getFootageValues(state: FootageState): FootageAttachment[] {
    let footage: FootageAttachment[] = [];
    state.footage.forEach(f => {
        let footageValues = (state.footageValues[f.id] || []).filter(x => !!x.name.trim());
        if (footageValues && footageValues.length !== 0) {
            footage.push(...footageValues);
        }
    });
    return footage;
} 

export function getContactValue(state: ContactState, fieldId : string, isValid: (id: string) => boolean = (id: string) => true): QuestionnaireRecruitContact | null {

    const relationId = fieldId.split(';')[0];
    const fieldName = fieldId.split(";")[1];
    const c = state.contacts.find(x => x.id === relationId)!
    if (Object.keys(state.contactValues).filter(k => k.startsWith(relationId)).length !== 0) {
            if (isValid(relationId)) {
                 let contact = {
                       relationId: c.relationId,
                       contactFieldValue: c.contactFields.filter(x => x.fieldName === fieldName).map(x => ({fieldId: x.fieldId, shortValue: state.contactValues[`${relationId};${x.fieldName}`] } as FieldValue))[0] 
                      } as QuestionnaireRecruitContact;
                if (contact && contact.contactFieldValue != null) {
                    return contact;
                }
            }
        }
    return null;
}

export function getValidContactValue(state: ContactState, fieldId: string): QuestionnaireRecruitContact  | null {
    return getContactValue(state, fieldId, (id: string) => !Object.keys(state.contactValidators).filter(k => k.startsWith(id))
        .some(k => state.contactValidators[k].some(v => !v.isValid(state.contactValues[k]))))
}

export function getAllContactValues(state: ContactState, isValid: (id: string) => boolean = (id: string) => true): QuestionnaireRecruitContactInfo[] {
    let contacts: QuestionnaireRecruitContactInfo[] = [];
    state.contacts.forEach(c => {
        if (Object.keys(state.contactValues).filter(k => k.startsWith(c.id)).length !== 0) {
            if (isValid(c.id)) {
                let contact = {
                    relationId: c.relationId,
                    fieldValues: c.contactFields.map(x => ({fieldId: x.fieldId, shortValue: state.contactValues[`${c.id};${x.fieldName}`] } as FieldValue)) 
                } as QuestionnaireRecruitContactInfo;
                contacts.push(contact);
            }
        }
    });
    return contacts;
}

export function getAllValidContactValues(state: ContactState): QuestionnaireRecruitContactInfo[] {
    return getAllContactValues(state, (id: string) => state.contactValues[`${id};${keepBlank}`] || !Object.keys(state.contactValidators).filter(k => k.startsWith(id))
        .some(k => state.contactValidators[k].some(v => !v.isValid(state.contactValues[k]))))
}

export function getSportStatsValues(state: SportStatsFieldState, fieldId: string, isValid: (id: string) => boolean = (id: string) => true): FieldValue | null {
    let sportStatsValue: FieldValue | null = null;
    let f = state.sportStatsFields.reduce((prev, cur) => [...prev, ...cur.fields] as Field[], [] as Field[]).find(f => f.id === fieldId)!
    let fieldValue = state.sportStatsFieldValues[f.id];
      if (fieldValue !== undefined && isValid(f.id)) {
            sportStatsValue = FieldUtility.setSpecifiedValue(f.id, f.valueType, fieldValue);
        }
    return sportStatsValue;
}

export function getValidSportStatsValues(state: SportStatsFieldState, fieldId: string): FieldValue | null {
    return getSportStatsValues(state, fieldId, (id: string) =>
        !state.sportStatsFieldValidators[id] || !state.sportStatsFieldValidators[id].some(x => !x.isValid(state.sportStatsFieldValues[id])));
}

export function getAllSportStatsValues(state: SportStatsFieldState, isValid: (id: string) => boolean = (id: string) => true): FieldValue[] {
    const sportStatsValues: FieldValue[]  = [];
    let fields = state.sportStatsFields.reduce((prev, cur) => [...prev, ...cur.fields] as Field[], [] as Field[])
    fields.forEach(f => {
        let fieldValue = state.sportStatsFieldValues[f.id];
        if (fieldValue !== undefined && isValid(f.id)) {
            sportStatsValues.push(FieldUtility.setSpecifiedValue(f.id, f.valueType, fieldValue));
        }
    });
    return sportStatsValues;
}

export function getAllValidSportStatsValues(state: SportStatsFieldState): FieldValue[] {
    return getAllSportStatsValues(state, (id: string) =>
        !state.sportStatsFieldValidators[id] || !state.sportStatsFieldValidators[id].some(x => !x.isValid(state.sportStatsFieldValues[id])));
}

export function getBioValues(state: BioFieldState, fieldId : string, isValid: (id: string) => boolean = (id: string) => true): { bioValue: FieldValue | null, positions: string[] } {
    let bioValue: FieldValue | null = null;
    let positions: string[] = []
    let f = state.bioFields.reduce((prev, cur) => [...prev, ...cur.fields] as Field[], [] as Field[]).find(f => f.id === fieldId)!
    let fieldValue = state.bioFieldValues[fieldId];
    if (fieldValue !== undefined && isValid(fieldId)) {
        if (f.isDefault && f.nameId === 'positions') {
                positions = fieldValue;
            } else {
                bioValue = FieldUtility.setSpecifiedValue(f.id, f.valueType, fieldValue);
            }
        }         
    return { bioValue, positions };
}

export function getValidBioValues(state: BioFieldState, fieldId: string): { bioValue: FieldValue | null, positions: string[] }{
    return getBioValues(state, fieldId, (id: string) =>
        !state.bioFieldValidators[id] || !state.bioFieldValidators[id].some(x => !x.isValid(state.bioFieldValues[id])));
}

export function getAllBioValues(state: BioFieldState, isValid: (id: string) => boolean = (id: string) => true): { bioValues: FieldValue[], positions: string[] } {
    let bioValues: FieldValue[] | null = [];
    let positions: string[] = []
    let fields = state.bioFields.reduce((prev, cur) => [...prev, ...cur.fields] as Field[], [] as Field[]);
    fields.forEach(f =>
    {
        let fieldValue = state.bioFieldValues[f.id];
        if (fieldValue !== undefined && isValid(f.id)) {
        if (f.isDefault && f.nameId === 'positions') {
                positions = fieldValue;
            } else {
                bioValues.push(FieldUtility.setSpecifiedValue(f.id, f.valueType, fieldValue));
            }
        }
    });
    return { bioValues, positions };
}

export function getAllValidBioValues(state: BioFieldState): { bioValues: FieldValue[], positions: string[] }{
    return getAllBioValues(state, (id: string) =>
        !state.bioFieldValidators[id] || !state.bioFieldValidators[id].some(x => !x.isValid(state.bioFieldValues[id])));
}

