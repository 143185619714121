import { connect } from 'react-redux';
import { AnyAction } from "redux";
import { StoreState } from "../../store/configureStore";
import { SportStatsFieldSelector } from '../../components/SportStats/SportStatsFieldSelector';
import { SportStatsFieldActions } from './actions';
import { PartialUpdateTypes, excludeFromMultipleEvent } from '../../constants/Constants';
import { ThunkDispatch } from 'redux-thunk';
import { blurActionCreator } from '../actionCreators';
import { PartialUpdateInfo } from '../../models/partial-update/partial-update-info';
import { FieldGroupType } from '../../models/api-models/fields/field-group-type';

const mapStateToProps = (state: StoreState, ownProps: any) => {
    const id: string = ownProps.id;
    return {
        ...ownProps,
        valid: !state.sportStatsFields.sportStatsFieldErrors[id],
        value: state.sportStatsFields.sportStatsFieldValues[id],
        show: getShowSettings(id, state),
    }
}

export const getShowSettings = (id: string, state: StoreState): boolean => {
    return !state.sportStatsFields.multipleEvent ||
        state.sportStatsFields.sportStatsFields.filter(g => g.fields.some(f => f.id === id))
            .some(g => excludeFromMultipleEvent.some(n => n === g.nameId)) ||
        state.sportStatsFields.multipleEventChecked[id];
}

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>) => {
    return {
        onBlur: (id: string, value: any) => dispatch(blurActionCreator(SportStatsFieldActions.onSportStatsFieldBlur(id, value), PartialUpdateTypes.Field, { fieldId: id, groupType: FieldGroupType.SportStats } as PartialUpdateInfo)),
        onChange: (id: string, value: any) => dispatch(SportStatsFieldActions.onSportStatsFieldChange(id, value))
    }
}

const SportStatsFieldContainer = connect(mapStateToProps, mapDispatchToProps)(SportStatsFieldSelector);

export default SportStatsFieldContainer;