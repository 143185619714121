import React, { useEffect, useState } from 'react';
import { FieldRenderProps } from '@progress/kendo-react-form';
import { Error } from '@progress/kendo-react-labels';
import '../../styles/components/UploadForm/Layout.css'
import { FieldWrapper } from '../Common/FieldWrapper';

export const FormFile: React.FC<FieldRenderProps> = (fieldRenderProps) => {
  const {
    btnText,
    name,
    className = '',
    disabled = false,
    valid,
    validationMessage,
    inputRef,
    multiple = false,
    onFileSelect,
    presetFiles,
    onChange,
    visited,
  } = fieldRenderProps

  const [selectedFiles, setSelectedFiles] = useState<File[]>([])
  const [fileNames, setFileNames] = useState<string[]>([])
  const errorClassName = validationMessage ? "input-error" : ""

  useEffect(()=>{
    const selectedFileNames = selectedFiles.map(file=> file.name)
    setFileNames(selectedFileNames)
  }, [selectedFiles])

  useEffect(() => {
    if(presetFiles && presetFiles.length)
      setFileNames([...presetFiles])
  }, [presetFiles])

  const onChangeInner = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = [...event.target.files||[]]
    setSelectedFiles(files)

    if(event.target)
      event.target.value = ''
    onChange({value: files})
  }
  const onFileRemove = (fileName: string) => {
    setSelectedFiles(prev => {
      const updatedPrev = [...prev].filter(item => item.name !== fileName)||[]
      onChange({value: updatedPrev})
      return  updatedPrev
    })
  }

  const fileSize = (size: number): string => {
    if (size < 1024) return `${size.toString().substring(0, 5)} B`
    if (size < 1024 * 1024) return `${(size/1024).toString().substring(0, 5)} KB`
    if (size < 1024 * 1024 * 1024) return `${(size/(1024*1024)).toString().substring(0, 5)} MB`
  }

  return (
    <FieldWrapper>
      <div className="upload-selector-input">
      <div className="wrapper-selector">
      <div style={{display: 'flex', flexDirection: 'column'}}>
        <p>Upload File</p>
        <p className="legend-text">The file must be in XLSX or CSV format. Size limit is 25MB.</p>
      </div>
      <div className={`k-d-flex k-justify-content-between k-mt-1`}>
        <div className={`k-d-flex k-mr-1 ${className}`}>
          <button className={"k-button k-button-md k-rounded-md k-button-solid k-button-solid-base"} type={'button'} onClick={onFileSelect} disabled={disabled}>
            {btnText}
          </button>
          <input
            name={name}
            type="file"
            ref={inputRef}
            multiple={multiple}
            onChange={onChangeInner}
            style={{display: "none"}}
          />
        </div>
      </div>
      </div>
      <div className="wrapper-filelist">
      {
        fileNames.length ? (
          <div>
            {validationMessage && <div className="color-error"> <Error>{validationMessage}</Error> </div>}
            <div className="chosen-files-container">
              {
                fileNames.map((name, index: number) => (
                  <div className="file-row" key={index + name}>
                    <img className="selected-file" alt="Remove file" src={require('../../images/icon_file.png')} />
                    <div className="file-name-wrapper">
                      <span className={errorClassName ? "file-name error-text" : "file-name"}>{name}</span>
                      <br />
                      <span className="selected-size">{selectedFiles.length && fileSize(selectedFiles[0].size)}</span>
                    </div>
                    <span className="close-button" title="remove file" onClick={()=>onFileRemove(name)}>
                      <img className="" alt="Remove file" src={require('../../images/close.png')} />
                    </span>
                  </div>
                ))}
            </div>  
          </div>
        ) : (
            !valid && visited && <div className="k-d-flex-row k-justify-content-stretch"> <Error>{validationMessage}</Error> </div>
          )
      }
      </div>
      </div>
    </FieldWrapper>
  )
}