import React from "react";
import { AvatarMenu } from './AvatarMenu';
import { QuestionnaireService } from "../../services/QuestionnaireService";

export interface AvatarUploadProps {
    avatarId?: string;
    onAvatarUpdate: (file: File) => Promise<void>;
    onAvatarDelete: () => Promise<void>;
}

export class AvatarUpload extends React.Component<AvatarUploadProps> {

    constructor(props: AvatarUploadProps) {
        super(props);
        this.onChange = this.onChange.bind(this);
        this.onDelete = this.onDelete.bind(this);
    }

    onChange(event: React.ChangeEvent<HTMLInputElement>) {
        const { onAvatarUpdate } = this.props;
        const [file] = event.target.files;
        if (event.target.files && event.target.files.length) {
            onAvatarUpdate(file);
        }
    }

    onDelete(event: React.MouseEvent<HTMLInputElement>) {
        event.preventDefault();
        const { onAvatarDelete } = this.props;
        onAvatarDelete();
    }

    render() {
        const { avatarId } = this.props;
        const avatarUrl = avatarId ? QuestionnaireService.getAvatarThumbnailUrl(avatarId) : '';
        const style: React.CSSProperties = { backgroundImage: 'url(\'' + avatarUrl + '\')' }; 
        return <div className="avatar-area">
                {avatarUrl 
                 ? <label htmlFor="file" className="img-circular overflow-hidden avatar-label parent-avatar-menu" style={style}>
                     <AvatarMenu avatarId={avatarId} onChange={this.onChange} onDelete={this.onDelete}/>
                 </label>
                 : <label htmlFor="file" className="btn-user overflow-hidden btn-athlete avatar-label parent-avatar-menu">
                     <i className="fas fa-camera"></i>
                     <AvatarMenu avatarId={avatarId} onChange={this.onChange} onDelete={this.onDelete} />
                     </label>}
             </div>
    }
}