import { connect } from 'react-redux';
import { StoreState } from "../../store/configureStore";
import { QuestionnaireSportStats } from '../../components/SportStats/QuestionnaireSportStats';

const mapStateToProps = (state: StoreState, ownProps: any) => {
    return {
        ...ownProps,
        groups: state.sportStatsFields.sportStatsFields.filter(g => g.id === ownProps.groupId),
        groupId: ownProps.groupId, 
        checkedFields: state.sportStatsFields.multipleEventChecked,
        multipleEventForm: state.sportStatsFields.multipleEvent,
    }
}

const QuestionnaireSportStatsContainer = connect(mapStateToProps)(QuestionnaireSportStats);

export default QuestionnaireSportStatsContainer;
