import { HttpUtility } from "../utilities/HttpUtility";
import { ApiConfigs } from "../configs/ApiConfigs";
import { UploadInfo } from "../models/api-models/media/upload-info";
import { Api } from "./Api";


export class MediaApi{


    private static get mediaApiUrl(): string {
        return HttpUtility.combine(ApiConfigs.mediaApiUrl, 'v1/media');
    }

    private static get downloadUrl(): string {
        return HttpUtility.combine(ApiConfigs.mediaApiUrl, 'v1/download');
    }

    static imageThumbnail(mediaId: string): string {
        return HttpUtility.combine(this.downloadUrl, mediaId, 'thumbnails/_t');
    }

    static upload(data: any, name?: string, hasGeneralAccess?: boolean): Promise<UploadInfo> {
        const url = this.getUploadUrl(name, hasGeneralAccess);
        return Api.post<UploadInfo>(url, data);
    }

    static download(mediaId: string): string {
        return HttpUtility.combine(this.downloadUrl, mediaId);
    }

    static getUploadUrl(name?: string, hasGeneralAccess?: boolean): string {
        let url = this.mediaApiUrl;
        if (name) {
            url = HttpUtility.addParameter(url, 'name', name);
        }
        if (hasGeneralAccess === true) {
            url = HttpUtility.addParameter(url, 'hasGeneralAccess', hasGeneralAccess.toString());
        }
        return url;
    }

    static getRemoveUrl(): string {
        const url = HttpUtility.combine(this.mediaApiUrl, 'delete');
        return url;
    }
}