import {FieldValueType} from "../models/api-models/fields/field-value-type";
import { FieldUtility } from "./FieldUtility";
import {FieldGroupType} from "../models/api-models/fields/field-group-type";
import {Field} from "../models/fields/field";
import {FileAttachment} from "../models/files/file-attachment";
import { InstitutionsValueProps } from "../containers/Institutions/reducers";
import { FieldGroup } from "../models/fields/field-group";

export interface Validator {
    type: ValidatorType,
    isValid: (value: any) => boolean;
    errorMessage: string;
}

export enum ValidatorType {
    Required = 0,
    Integer = 1,
    Decimal = 2,
    TimeResult = 3,
    Min = 4,
    Max = 5,
    Url = 6,
    Date = 7,
    Email = 8,
    GradYear = 9,
    PhoneNumber = 10,
    SmsStatus = 11,
}

export class ValidatorUtility {

    static readonly maxDate = new Date(2099, 11, 31);

    static readonly minDate = new Date(1900, 0, 1);

    static readonly culture = "en-us";

    static getMinValidator(min: number, name: string): Validator{
        return { type: ValidatorType.Min, isValid: (value) => !value || value >= min, errorMessage: `Valid value of field '${name}' is grater then ${min}` }
    }

    static getMaxValidator(max: number, name: string): Validator {
        return { type: ValidatorType.Max, isValid: (value) => !value || value <= max, errorMessage: `Valid value of field '${name}' is less then ${max}` }
    }

    private static getRequiredValidatorFunction(valueType: FieldValueType): ((value: any) => boolean) {
        switch (valueType) {
            case FieldValueType.Bool:
                return ((value: any) => true);
            case FieldValueType.Number:
            case FieldValueType.ShortString:
            case FieldValueType.LongString:
            case FieldValueType.DateTimeOffset:
            case FieldValueType.Dropdown:            
            case FieldValueType.TimeResult:
            case FieldValueType.Url:
                return ((value: any) => !!value);
            case FieldValueType.Multiselect:
                return ((value: string[]) => value && value.length !== 0);                     
            case FieldValueType.Events:
                return ((value: { title: string, date: Date | null }[]) => value && value.length !== 0 && value.some(x => !!x.date && !!x.title));
            case FieldValueType.TimeDate:
                return ((value: { time: string, date: Date | null }) => value && !!value.time && !!value.date);
            case FieldValueType.ResultDate:
                return ((value: { result: string, date: Date | null }) => value && !!value.result && !!value.date);
        }
    }

    static getRequiredValidator(valueType: FieldValueType, name: string): Validator {
        return { type: ValidatorType.Required, isValid: this.getRequiredValidatorFunction(valueType), errorMessage: `Field '${name}' is required` }
    }

    static getFileRequiredValidator(name: string): Validator {
        return {
            type: ValidatorType.Required,
            isValid: (value: FileAttachment[]) => value && value.length > 0,
            errorMessage: `Field '${name}' is required`
        }
    }

    static getInstitutionsRequiredValidator(categoryName: string) {
        return {
            type: ValidatorType.Required,
            isValid: (props: InstitutionsValueProps) => {
                return !props.useCumtomInstitutionInput ? props.institutionId && props.institutionId.length > 0 : props.otherInstitution && props.otherInstitution.length > 0;
            },
            errorMessage: `${categoryName} field is required`
        }
    }

    static getDecimalValidator(name: string): Validator {
        return { type: ValidatorType.Decimal, isValid: (value) => !value || FieldUtility.testNumber(value), errorMessage: `Valid value of field '${name}' is a number` }
    }

    static getIntegerValidator(name: string): Validator {
        return { type: ValidatorType.Integer, isValid: (value) => !value || FieldUtility.testInteger(value), errorMessage: `Valid value of field '${name}' is an integer number` }
    }

    static getResultDateValidator(name: string): Validator {
        return {
            type: ValidatorType.Decimal,
            isValid: (value: { result: string, date: Date | null }) => !value || !value.result || FieldUtility.testNumber(value.result),
            errorMessage: `Valid value of field '${name}' is a number`
        }
    }

    static getTimeResultValidator(name: string): Validator {
        return {
            type: ValidatorType.TimeResult,
            isValid: (value) => !value || FieldUtility.testTimeResult(value),
            errorMessage: `Valid time format of field '${name}' is mm:ss.xx`
        }
    }

    static getFootageUrlValidator(name: string): Validator {
        return {
            type: ValidatorType.Url,
            isValid: () => true,
            errorMessage: `Valid value of field '${name}' is a number`
        }
        
    }

    static getPhoneNumberValidator(): Validator {
        return {
            type: ValidatorType.PhoneNumber,
            isValid: (value) => !value || /^\d+\s\d+$/.test(value),
            errorMessage: "Invalid phone number format"
        }
    }

    static getGradYearValidator(fieldName: string): Validator {
        return {
            type: ValidatorType.GradYear,
            isValid: (value) => !value || value.trim() === "" || /^(20)[0-9]{2}$/.test(value),
            errorMessage: `${fieldName} should be in 20XX format`
        }
    }

   static validateFootageUrl(url: string) {
       if (url && url.length > 0) {
           //eslint-disable-next-line
           const youtubeRegExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=|\?v=)([^#\&\?]*).*/;
           //eslint-disable-next-line
            const urlRegExp = /(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/;
            if (typeof url === 'string' && (url.match(youtubeRegExp) || url.match(urlRegExp))) {
                return false;
            } else {
                return true;
            }
        } else {
            return false;
        }
    }

    static getTimeDateValidator(name: string): Validator {
        return {
            type: ValidatorType.TimeResult,
            isValid: (value: { time: string, date: Date | null }) => !value || !value.time || FieldUtility.testTimeResult(value.time),
            errorMessage: `Valid time format of field '${name}' is mm:ss.xx`
        }
    }

    static getDateErrorMessage(name: string) {
        return `Valid date in field '${name}' is in the range from ${this.minDate.toLocaleDateString(this.culture)} to ${this.maxDate.toLocaleDateString(this.culture)}`;
    }


    static getDateValidator(name: string): Validator {
        return {
            type: ValidatorType.Date,
            isValid: (value: Date | null) => !value || (this.minDate < new Date(value) && new Date(value) < this.maxDate),
            errorMessage: this.getDateErrorMessage(name)
        }
    }

    static getComplexDateValidator(name: string): Validator {
        return {
            type: ValidatorType.Date,
            isValid: (value: { date: Date | null }) => !value || !value.date || (this.minDate < new Date(value.date) && new Date(value.date) < this.maxDate),
            errorMessage: this.getDateErrorMessage(name)
        }
    }

    static getEventsDateValidator(name: string): Validator {
        return {
            type: ValidatorType.Date,
            isValid: (value: { date: Date | null }[]) => !value || value.length === 0 ||
                value.every(v => !v.date || (this.minDate < new Date(v.date) && new Date(v.date) < this.maxDate)),
            errorMessage: this.getDateErrorMessage(name)
        }
    }

    private static isUrlValid(url: string): boolean {
        const regExpUrl =
            new RegExp(/https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}/gi);
        return url.match(regExpUrl) !== null;
    }

    static getUrlValidator(name: string): Validator {
        return {
            type: ValidatorType.Url,
            isValid: (value: string) => !value || this.isUrlValid(value),
            errorMessage: `Valid value of field '${name}' is URL`
        }
    }

    static getFieldValidators(field: Field, group: FieldGroup): Validator[] {
        let validators: Validator[] = [];
        if (field.required) {
            validators.push(this.getRequiredValidator(field.valueType, field.name));
        }
        if (group.groupType === FieldGroupType.Bio && field.isDefault) {
            if (field.nameId === 'cellPhone') {
                validators.push(this.getPhoneNumberValidator());
                return validators;
            }
            if (field.nameId === 'graduationYear' || field.nameId === 'classOf') {
                validators.push(this.getIntegerValidator(field.name));
                if(field.nameId === 'graduationYear') {
                    validators.push(this.getGradYearValidator(field.name));
                }
                return validators;
            }
            if (field.nameId === 'satMath') {
                validators.push(this.getIntegerValidator(field.name), ValidatorUtility.getMinValidator(0, field.name), ValidatorUtility.getMaxValidator(800, field.name));
                return validators;
            }
            if (field.nameId === 'satVerbal') {
                validators.push(this.getIntegerValidator(field.name), ValidatorUtility.getMinValidator(0, field.name), ValidatorUtility.getMaxValidator(800, field.name));
                return validators;
            }
            if (field.nameId === 'satWriting') {
                validators.push(this.getIntegerValidator(field.name), ValidatorUtility.getMinValidator(0, field.name), ValidatorUtility.getMaxValidator(800, field.name));
                return validators;
            }
            if (field.nameId === 'satScore') {
                validators.push(this.getIntegerValidator(field.name), ValidatorUtility.getMinValidator(0, field.name), ValidatorUtility.getMaxValidator(2400, field.name));
                return validators;
            }
            if (field.nameId === 'actScore') {
                validators.push(this.getIntegerValidator(field.name), ValidatorUtility.getMinValidator(0, field.name), ValidatorUtility.getMaxValidator(36, field.name));
                return validators;
            }
            if (field.nameId === 'gpaWeighted') {
                validators.push(this.getDecimalValidator(field.name), ValidatorUtility.getMinValidator(0, field.name), ValidatorUtility.getMaxValidator(200, field.name));
                return validators;
            }
            if (field.nameId === 'gpaUnweighted') {
                validators.push(this.getDecimalValidator(field.name), ValidatorUtility.getMinValidator(0, field.name), ValidatorUtility.getMaxValidator(200, field.name));
                return validators;
            }
        }

        const isEmail = (nameId: string) => nameId === 'email' || nameId === 'schoolEmail' || nameId === 'coachEmailClubTeamInfo' || nameId === 'institutionalEmail';
        if (isEmail(field.nameId)) {
            validators.push(this.getEmailValidator(field.name), ValidatorUtility.getEmailValidator(field.name));
        }

        switch (field.valueType) {
            case FieldValueType.DateTimeOffset:
                validators.push(ValidatorUtility.getDateValidator(field.name));
                break;
            case FieldValueType.Number:
                validators.push(ValidatorUtility.getDecimalValidator(field.name));
                break;
            case FieldValueType.TimeResult:
                validators.push(ValidatorUtility.getTimeResultValidator(field.name));
                break;
            case FieldValueType.TimeDate:
                validators.push(ValidatorUtility.getTimeDateValidator(field.name));
                validators.push(ValidatorUtility.getComplexDateValidator(field.name));
                break;
            case FieldValueType.ResultDate:
                validators.push(ValidatorUtility.getResultDateValidator(field.name));
                validators.push(ValidatorUtility.getComplexDateValidator(field.name));
                break;
            case FieldValueType.Url:
                validators.push(ValidatorUtility.getUrlValidator(field.name));
                break;
            case FieldValueType.Events:
                validators.push(ValidatorUtility.getEventsDateValidator(field.name));
                break;
        }
        return validators;
    }

    static getEmailValidator(name: string) {
        return { type: ValidatorType.Email, isValid: (value) => FieldUtility.testEmail(value), errorMessage: `Field '${name}' is invalid. Please enter a valid email address` };
    }
}
