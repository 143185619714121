import React from "react";
import { MultiSelect, MultiSelectChangeEvent } from "@progress/kendo-react-dropdowns";
import { FieldProps } from "../../models/fields/field-props";
import { FieldHeader } from "../Common/FieldHeader";

export class MultiselectField extends React.PureComponent<FieldProps<string[]>> {

    constructor(props: FieldProps<string[]>) {
        super(props);
        this.onChange = this.onChange.bind(this);
    }

    onChange(event: MultiSelectChangeEvent) {
        const { onBlur, id } = this.props;
        onBlur(id, event.target.value.map(x => x.id));
    }

    render() {
        const { name, required, value, valid, options, description } = this.props;
        const objectValue = value ? options.filter(x => value.some(v => v === x.id)) : [];
        return <React.Fragment>
            <FieldHeader required={required} name={name} description={description} />
            <MultiSelect data={options}
                className="w-100"
                textField="title"
                dataItemKey="id"
                onChange={this.onChange}
                value={objectValue}
                valid={valid}/>
        </React.Fragment>;
    }
}