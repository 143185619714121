import { SportStatsFieldAction } from "./actions";
import {
    ON_SPORT_STATS_FIELD_CHANGE, ON_SPORT_STATS_FIELD_BLUR,
    SET_SPORT_STATS_FIELD_VALIDATORS, SET_SPORT_STATS_FIELD_VALUES,
    SET_SPORT_STATS_FIELD_SETTINGS,
    CHECK_MULTIPLE_EVENT,
    SET_MULTIPLE_EVENT,
    SET_SPORT_STATS_FIELD_ERRORS
} from "./constants";
import { Validator } from "../../utilities/ValidatorUtility";
import { FieldGroup } from "../../models/fields/field-group";
import { validate } from "../selectors";

export interface SportStatsFieldState {
    sportStatsFieldValues: { [id: string]: any };
    sportStatsFieldErrors: { [id: string]: boolean };
    sportStatsFieldValidators: { [id: string]: Validator[] };
    sportStatsFields: FieldGroup[];
    multipleEventChecked: { [id: string]: boolean };
    multipleEvent: boolean;
}

const initialState: SportStatsFieldState = {
    sportStatsFieldValues: {},
    sportStatsFieldErrors: {},
    sportStatsFieldValidators: {},
    sportStatsFields: [],
    multipleEventChecked: {},
    multipleEvent: false
}

export const sportStatsFieldReducer = (state: SportStatsFieldState = initialState, action: SportStatsFieldAction) => {
    switch (action.type) {
        case ON_SPORT_STATS_FIELD_CHANGE: {
            return {
                ...state,
                sportStatsFieldValues: {
                    ...state.sportStatsFieldValues,
                    [action.payload.id]: action.payload.value
                }
            }
        }

        case ON_SPORT_STATS_FIELD_BLUR: {
            return {
                ...state,
                sportStatsFieldValues: {
                    ...state.sportStatsFieldValues,
                    [action.payload.id]: action.payload.value
                },
                sportStatsFieldErrors: {
                    ...state.sportStatsFieldErrors,
                    [action.payload.id]: validate(state.sportStatsFieldValidators[action.payload.id], action.payload.value)
                }
            }
        }

        case SET_SPORT_STATS_FIELD_VALIDATORS: {
            return {
                ...state,
                sportStatsFieldValidators: action.payload.validators
            }
        }

        case SET_SPORT_STATS_FIELD_VALUES: {
            return {
                ...state,
                sportStatsFieldValues: action.payload.values
            }
        }

        case SET_SPORT_STATS_FIELD_SETTINGS: {
            return {
                ...state,
                sportStatsFields: action.payload.groups
            }
        }

        case CHECK_MULTIPLE_EVENT: {
            return {
                ...state,
                multipleEventChecked: {
                    ...state.multipleEventChecked,
                    [action.payload.id]: !state.multipleEventChecked[action.payload.id]
                }
            }
        }

        case SET_MULTIPLE_EVENT: {
            return {
                ...state,
                multipleEvent: action.payload.multipleEvent,
                multipleEventChecked: action.payload.multipleEventChecked
            }
        }

        case SET_SPORT_STATS_FIELD_ERRORS: {
            return {
                ...state,
                sportStatsFieldErrors: action.payload.errors
            }
        }

        default: {
            return state;
        }
    }
}