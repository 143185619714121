import { BioFieldAction } from "./actions";
import { ON_BIO_FIELD_CHANGE, ON_BIO_FIELD_BLUR, SET_BIO_FIELD_VALIDATORS, SET_BIO_FIELD_VALUES, SET_BIO_FIELD_SETTINGS, SET_BIO_FIELD_ERRORS, ON_COUNTRY_FIELD_CHANGE } from "./constants";
import { Validator } from "../../utilities/ValidatorUtility";
import { FieldGroup } from "../../models/fields/field-group";
import { validate } from "../selectors";
import { Field } from "../../models/fields/field";

export interface BioFieldState {
    bioFieldValues: { [id: string]: any };
    bioFieldErrors: { [id: string]: boolean };
    bioFieldValidators: { [id: string]: Validator[] };
    bioFields: FieldGroup[];
    recruitCountry: string;
    recruitSchoolCountry: string;
}

const initialState: BioFieldState = {
    bioFieldValues: {},
    bioFieldErrors: {},
    bioFieldValidators: {},
    bioFields: [],
    recruitCountry: '',
    recruitSchoolCountry: '',
}


export const bioFieldReducer = (state: BioFieldState = initialState, action: BioFieldAction) => {
    switch (action.type) {
        case ON_BIO_FIELD_CHANGE:
        {
            return {
                ...state,
                bioFieldValues: {
                    ...state.bioFieldValues,
                    [action.payload.id]: action.payload.value
                    }
            }
        }
        case ON_BIO_FIELD_BLUR:
            return {
                ...state,
                bioFieldValues: {
                    ...state.bioFieldValues,
                    [action.payload.id]: action.payload.value
                },
                bioFieldErrors: {
                    ...state.bioFieldErrors,
                    [action.payload.id]: validate(state.bioFieldValidators[action.payload.id], action.payload.value)
                }, 
            }
        case ON_COUNTRY_FIELD_CHANGE:
            const addressFields = state.bioFields.filter(gr => gr.nameId === 'Address')
                .reduce((res, item) => res.concat(item.fields.filter(f => f.id === action.payload.id)), [] as Field[]);
            let stateFieldId = null;
            let group = null;
            let recruitCountry = state.recruitCountry;
            let recruitSchoolCountry = state.recruitSchoolCountry;
            if (addressFields.length > 0 && addressFields[0].nameId === 'country') {
                group = state.bioFields.find(group => group.nameId === 'Address');
                recruitCountry = action.payload.value;
                stateFieldId = group && group.fields.some(field => field.nameId === 'state') ? group.fields.find(field => field.nameId === 'state').id : null;
            }
            let bioFieldValues = {
                ...state.bioFieldValues,
                [action.payload.id]: action.payload.value
            }
            if (stateFieldId) {
                let stateValue: string | null = null;

                if (addressFields[0].nameId === 'country') {
                    if (!(action.payload.value === 'United States' || action.payload.value === 'Canada')) {
                        stateValue = 'International';
                    }
                }

                bioFieldValues = {
                    ...bioFieldValues,
                    [stateFieldId]: stateValue
                };
            }
            return {
                ...state,
                bioFieldValues,
                bioFieldErrors: {
                    ...state.bioFieldErrors,
                    [action.payload.id]: validate(state.bioFieldValidators[action.payload.id], action.payload.value)
                },
                recruitCountry,
                recruitSchoolCountry,
            }
        case SET_BIO_FIELD_VALIDATORS: {
            return {
                ...state,
                bioFieldValidators: action.payload.validators
            }
        }

        case SET_BIO_FIELD_VALUES: {
            return {
                ...state,
                bioFieldValues: action.payload.values
            }
        }

        case SET_BIO_FIELD_SETTINGS: {
            return {
                ...state,
                bioFields: action.payload.groups
            }
        }

        case SET_BIO_FIELD_ERRORS: {
            return {
                ...state,
                bioFieldErrors: action.payload.errors
            }
        }

        default: {
            return state;
        }
    }
}