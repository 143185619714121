import { FieldGroupType } from "./field-group-type";

export enum FieldValueType {
    Number = 1,
    ShortString = 2,
    LongString = 3,
    DateTimeOffset = 4,
    Bool = 5,
    Dropdown = 6,
    Multiselect = 7,
    Url = 8,
    Events = 9,
    TimeResult = 10,
    TimeDate = 11,
    ResultDate = 12,

}

export const FieldValueTypeValues: Array<{ name: string, value: FieldValueType, groupTypes: FieldGroupType[] }> = [
    { name: 'Number', value: FieldValueType.Number, groupTypes: [FieldGroupType.Bio, FieldGroupType.SportStats] },
    { name: 'Text Field', value: FieldValueType.ShortString, groupTypes: [FieldGroupType.Bio, FieldGroupType.SportStats] },
    { name: 'Text Box', value: FieldValueType.LongString, groupTypes: [FieldGroupType.Bio, FieldGroupType.SportStats] },
    { name: 'Date', value: FieldValueType.DateTimeOffset, groupTypes: [FieldGroupType.Bio, FieldGroupType.SportStats] },
    { name: 'Check Box', value: FieldValueType.Bool, groupTypes: [FieldGroupType.Bio, FieldGroupType.SportStats] },
    { name: 'Dropdown', value: FieldValueType.Dropdown, groupTypes: [FieldGroupType.Bio, FieldGroupType.SportStats] },
    { name: 'Multiple Choice', value: FieldValueType.Multiselect, groupTypes: [FieldGroupType.Bio, FieldGroupType.SportStats] },
    { name: 'Events', value: FieldValueType.Events, groupTypes: [FieldGroupType.SportStats] },
    { name: 'Time Result', value: FieldValueType.TimeResult, groupTypes: [FieldGroupType.SportStats] },
    { name: 'Time Date', value: FieldValueType.TimeDate, groupTypes: [FieldGroupType.SportStats] },
    { name: 'Result Date', value: FieldValueType.ResultDate, groupTypes: [FieldGroupType.SportStats] },
    { name: 'URL', value: FieldValueType.Url, groupTypes: [FieldGroupType.Bio, FieldGroupType.SportStats] },
];

export function getFieldValueTypeValues(groupType: FieldGroupType): Array<{ name: string, value: FieldValueType }> {
    return FieldValueTypeValues.filter(x => x.groupTypes.some(g => g === groupType));
}
