import { connect } from 'react-redux';
import { AnyAction } from "redux";
import { StoreState } from "../../store/configureStore";
import { InstitutionsActions } from './actions';
import { blurActionCreator } from '../actionCreators';
import { InstitutionItem } from '../../components/Institutions/InstitutionItem';
import { searchInstitutionsByTermActionCreator } from '../actionCreators';
import { ThunkDispatch } from 'redux-thunk';
import { PartialUpdateTypes } from '../../constants/Constants';
import { PartialUpdateInfo } from '../../models/partial-update/partial-update-info';

const mapStateToProps = (state: StoreState, ownProps: any) => {
    const id: string = ownProps.id;
    return {
        ...ownProps,
        value: {
            ...state.institutions.institutionsValue[id],
            valid: !state.institutions.institutionsErrors[id],
        }
    }
}

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>) => {
    return {
        onChangeCustomInput: (id: string, value: string) => dispatch(InstitutionsActions.changeCustomInput(id, value)),
        onBlurCustomInput: (id: string, value: string) => dispatch(blurActionCreator(InstitutionsActions.changeCustomInput(id, value), PartialUpdateTypes.Institutions, {} as PartialUpdateInfo )),
        onSwitchInstitutionInput: (id: string, value: boolean) => dispatch(blurActionCreator(InstitutionsActions.switchInstitutionInput(id, value), PartialUpdateTypes.Institutions, {} as PartialUpdateInfo)),
        setCategory: (id: string, value: string, name: string) => dispatch(blurActionCreator(InstitutionsActions.setInstitutionItem(id, value, name), PartialUpdateTypes.Institutions, {} as PartialUpdateInfo)),
        searchInstitutionsByTerm: (id: string, value: string, categoryId: string, page: number, pageSize: number) => dispatch(searchInstitutionsByTermActionCreator(id, value, categoryId, page, pageSize)),
        showInstitutionCustom: (id: string) => dispatch(InstitutionsActions.showInstitutionCustom(id)),
        clearSearchResults: (id: string) => dispatch(InstitutionsActions.setInstitutionSearchResult([], id)),
        setLoadingSearchResults: (id: string, value: boolean) => dispatch(InstitutionsActions.setUpSearchResultLoading(id, value)),
        setUpInstitutionSearchName: (id: string, value: string) => dispatch(InstitutionsActions.setUpInstitutionSearchName(id, value)),
        setNextSearchResultValue: (id: string, value: number) => dispatch(InstitutionsActions.setNextSearchResultValue(id, value))
    }
}

const InstitutionItemContainer = connect(mapStateToProps, mapDispatchToProps)(InstitutionItem);

export default InstitutionItemContainer;