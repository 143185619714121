import { FileAction } from "./actions";
import { ON_FILE_ADD, ON_FILE_REMOVE, SET_FILE_VALIDATORS, SET_FILE_VALUES, SET_FILE_SETTINGS, SET_FILE_ERRORS } from "./constants";
import { Validator } from "../../utilities/ValidatorUtility";
import { File } from "../../models/files/file";
import { FileAttachment } from "../../models/files/file-attachment";
import { validate } from "../selectors";

export interface FileState {
    fileValues: { [id: string]: FileAttachment[] };
    fileErrors: { [id: string]: boolean };
    fileValidators: { [id: string]: Validator[] };
    files: File[]
}

const initialState: FileState = {
    fileValues: {},
    fileErrors: {},
    fileValidators: {},
    files: []
}

export const fileReducer = (state: FileState = initialState, action: FileAction) => {
    switch (action.type) {
        case ON_FILE_ADD: {
            const newValue = [
                ...(state.fileValues[action.payload.id] || []),
                action.payload.file
            ];
            return {
                ...state,
                fileValues: {
                    ...state.fileValues,
                    [action.payload.id]: newValue
                },
                fileErrors: {
                    ...state.fileErrors,
                    [action.payload.id]: validate(state.fileValidators[action.payload.id], newValue)
                }
            }
        }

        case ON_FILE_REMOVE: {
            const newValue = state.fileValues[action.payload.id].filter((x, i) => i !== action.payload.index);
            return {
                ...state,
                fileValues: {
                    ...state.fileValues,
                    [action.payload.id]: newValue
                },
                fileErrors: {
                    ...state.fileErrors,
                    [action.payload.id]: validate(state.fileValidators[action.payload.id], newValue)
                }
            }
        }

        case SET_FILE_VALIDATORS: {
            return {
                ...state,
                fileValidators: action.payload.validators
            }
        }

        case SET_FILE_VALUES: {
            return {
                ...state,
                fileValues: action.payload.values
            }
        }

        case SET_FILE_SETTINGS: {
            return {
                ...state,
                files: action.payload.files
            }
        }

        case SET_FILE_ERRORS: {
            return {
                ...state,
                fileErrors: action.payload.errors
            }
        }

        default: {
            return state;
        }
    }
}