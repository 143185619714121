import React from "react";

export class QuestionnaireContent extends React.PureComponent {

    render() {
        return <div className="container w-100">
            <div className="row">
                <div className="col-md-8 offset-md-2 form-section">
                    <div className="form-wrap">                        
                        {this.props.children}
                    </div>
                </div>
            </div>
        </div>
    }
}
