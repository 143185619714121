import { InstitutionCategory, InstitutionCategoryType } from "../../models/institution/institution-category";
import { InstitutionRelation } from "../../models/institution/institution-relation";
import { InstitutionSearchItem } from '../../models/institution/institution-search-item';
import { Validator } from "../../utilities/ValidatorUtility";
import { InstitutionsAction } from './actions';
import {
    SET_INSTITUTION_FIELDS, SET_INSTITUTION_SEARCH_RESULT, SET_INSTITUTION_ITEM,
    SHOW_INSTITUTION_CUSTOM, SWITCH_INSTITUTION_INPUT, CHANGE_CUSTOM_INSTITUTION_INPUT,
    SET_INSTITUTIONS_VALUES, SET_UP_SEARCH_RESULT_LOADING, SET_UP_INSTITUTION_SEARCH_NAME,
    SET_INSTITUTION_VALIDATORS, SET_INSTITUTIONS_ERRORS, SET_NEXT_SEARCH_RESULT_PAGE,
    CONCAT_INSTITUTION_SEARCH_RESULT,
} from './constants';
import { validate } from "../selectors";

export interface InstitutionsValueProps {
    institutionId: string | undefined, 
    institutionName: string | undefined,
    showRadioInstitutionOptions: boolean,
    useCumtomInstitutionInput: boolean,
    description: string;
    isRequired: boolean,
    valid: boolean,
    institutionCategoryName: string;
    otherInstitution: string | undefined;
    institutionCategory: InstitutionCategoryType,
    institutionsSearchResult: InstitutionSearchItem[];
    loadingSearchResult: boolean;
    institutionSearchName: string;
    page: number,
    pageSize: number,
}

export interface InstitutionsState {
    institutionsFields: InstitutionCategory[];
    institutionsValue: { [id: string]: InstitutionsValueProps };
    institutionsValidators: { [id: string]: Validator[] };
    institutionsErrors: { [id: string]: boolean };
}

const initialState: InstitutionsState = {
    institutionsFields: [],
    institutionsValue: {},
    institutionsValidators: {},
    institutionsErrors: {}
}

export const InstitutionsReducer = (state: InstitutionsState = initialState, action: InstitutionsAction) => {
    switch(action.type) {

        case SET_NEXT_SEARCH_RESULT_PAGE: 
         return {
            ...state,
            institutionsValue: {
                ...state.institutionsValue,
                [action.payload.id]: {
                    ...state.institutionsValue[action.payload.id],
                    page: action.payload.value
                }
            }
         }

        case CONCAT_INSTITUTION_SEARCH_RESULT:
            return {
                ...state,
                institutionsValue: {
                    ...state.institutionsValue,
                    [action.payload.id]: {
                        ...state.institutionsValue[action.payload.id],
                        institutionsSearchResult: state.institutionsValue[action.payload.id].institutionsSearchResult.concat(action.payload.searchResult),
                    }
                }
            }

        case SET_INSTITUTION_FIELDS:
            return {
                ...state,
                institutionsFields: action.payload
            }
            
        case SET_INSTITUTION_SEARCH_RESULT: 
            return {
                ...state,
                institutionsValue: {
                    ...state.institutionsValue,
                    [action.payload.id]: {
                        ...state.institutionsValue[action.payload.id],
                        institutionsSearchResult: action.payload.searchResult
                    }
                }
            }

        case SET_UP_INSTITUTION_SEARCH_NAME: 
            return {
                ...state,
                institutionsValue: {
                    ...state.institutionsValue,
                    [action.payload.id]: {
                        ...state.institutionsValue[action.payload.id],
                        institutionSearchName: action.payload.value
                    }
                }
            }

        case SET_INSTITUTION_ITEM: 
            return {
                ...state,
                institutionsValue: {
                    ...state.institutionsValue,
                    [action.payload.id]: {
                        ...state.institutionsValue[action.payload.id],
                        institutionId: action.payload.value,
                        institutionName: action.payload.name,
                    }
                },
                institutionsErrors: {
                    ...state.institutionsErrors,
                    [action.payload.id]: validate(state.institutionsValidators[action.payload.id],
                        {
                            ...state.institutionsValue[action.payload.id],
                            institutionId: action.payload.value,
                            institutionName: action.payload.name
                        }
                    )
                }
            }

        case SHOW_INSTITUTION_CUSTOM: 
            return {
                ...state,
                institutionsValue: {
                    ...state.institutionsValue,
                    [action.payload]:{
                        ...state.institutionsValue[action.payload],
                        showRadioInstitutionOptions: true,
                        useCumtomInstitutionInput: true,
                    }
                }
            }

        case SET_INSTITUTION_VALIDATORS: {
            return {
                ...state,
                institutionsValidators: action.payload.validators
            }
        }

        case SWITCH_INSTITUTION_INPUT: 
            return {
                ...state,
                institutionsValue: {
                    ...state.institutionsValue,
                    [action.payload.id]: {
                        ...state.institutionsValue[action.payload.id],
                        useCumtomInstitutionInput: action.payload.value
                    }
                }
            }
        
        case CHANGE_CUSTOM_INSTITUTION_INPUT: 
            return {
                ...state,
                institutionsValue: {
                    ...state.institutionsValue,
                    [action.payload.id]: {
                        ...state.institutionsValue[action.payload.id],
                        otherInstitution: action.payload.value
                    }
                },
                institutionsErrors: {
                    ...state.institutionsErrors,
                    [action.payload.id]: validate(state.institutionsValidators[action.payload.id],
                        {
                            ...state.institutionsValue[action.payload.id],
                            otherInstitution: action.payload.value
                        }
                    )
                }
            }

        case SET_INSTITUTIONS_VALUES: 
            return {
                ...state,
                institutionsValue: action.payload
            }

        case SET_UP_SEARCH_RESULT_LOADING: 
            return { 
                ...state,
                institutionsValue: {
                    ...state.institutionsValue,
                    [action.payload.id]: {
                        ...state.institutionsValue[action.payload.id],
                        loadingSearchResult: action.payload.value
                    }
                }
            }

        case SET_INSTITUTIONS_ERRORS: {
            return {
                ...state,
                institutionsErrors: action.payload.errors
            }
        }

        default: return state
    }
}
