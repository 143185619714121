import { connect } from 'react-redux';
import { AnyAction } from "redux";
import { StoreState } from "../../store/configureStore";
import { BioFieldSelector } from '../../components/Bio/BioFieldSelector';
import { BioFieldActions } from './actions';
import { blurActionCreator } from '../actionCreators';
import { ThunkDispatch } from 'redux-thunk';
import { PartialUpdateTypes } from '../../constants/Constants';
import { PartialUpdateInfo } from '../../models/partial-update/partial-update-info';
import { FieldGroupType } from '../../models/api-models/fields/field-group-type';

const mapStateToProps = (state: StoreState, ownProps: any) => {
    const id: string = ownProps.id;
    return {
        ...ownProps,
        valid: !state.bioFields.bioFieldErrors[id],
        value: state.bioFields.bioFieldValues[id],
    }
}

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>) => {
    return {
        onBlur: (id: string, value: any) => dispatch(blurActionCreator(BioFieldActions.onBioFieldBlur(id, value), PartialUpdateTypes.Field, {fieldId: id, groupType: FieldGroupType.Bio} as PartialUpdateInfo)),
        onChange: (id: string, value: any) => dispatch(BioFieldActions.onBioFieldChange(id, value))
    }
}

const BioFieldContainer = connect(mapStateToProps, mapDispatchToProps)(BioFieldSelector);

export default BioFieldContainer;