import { MediaApi } from "../api/MediaApi";
import { QuestionnaireView } from "../models/api-models/questionnaire/questionnaire-view";
import { QuestionnaireApi } from "../api/QuestionnaireApi";
import { AvatarImage } from "../models/api-models/media/avatar-image";
import { UploadInfo } from "../models/api-models/media/upload-info";
import { InstitutionSearchItem } from "../models/institution/institution-search-item";
import { QuestionnaireRecruitCreate } from "../models/api-models/questionnaire/questionnaire-recruit-create";
import { GenderListItem } from "../models/api-models/questionnaire/gender-list-item";
import { PositionListItem } from "../models/api-models/questionnaire/position-list-item";
import { FieldGroupViewModel } from "../models/api-models/fields/field-group-view-model";
import { InstitutionCategory } from "../models/institution/institution-category";
import { TeamInfo } from "../models/api-models/team/team-info";
import { PartialUpdateTypes } from "../constants/Constants"
import { QuestionnaireRecruitInfo } from "../models/api-models/questionaire-recruit/questionnaire-recruit-info";
export class QuestionnaireService {


    static async getQuestionnaireByTeamMnemonic(teamMnemonic: string, formUrl : string): Promise<QuestionnaireView> {
        var questionnaire = await QuestionnaireApi.getQuestionnaireByTeamMnemonic(teamMnemonic, formUrl);
        await this.setQuestionaireLogo(questionnaire);
        return questionnaire
    }

    static async getQuestionnairePreviewById(id: string): Promise<QuestionnaireView> {
        var questionnaire = await QuestionnaireApi.getQuestionnairePreviewById(id);
        await this.setQuestionaireLogo(questionnaire);
        return questionnaire
    }

    static async getQuestionnaireIsAvailable(teamMnemonic: string, formUrlId: string): Promise<boolean> {
        return await QuestionnaireApi.getQuestionnaireIsAvailable(teamMnemonic, formUrlId);
    }

    static async initializeQuestionnaireRecruit(questionnaireId: string, questionnaireRecruitId: string | null = null): Promise<string> {
        return QuestionnaireApi.initializeQuestionnaireRecruit(questionnaireId, questionnaireRecruitId);
    }

    static async getGenders(teamId: string): Promise<GenderListItem[]> {
        return await QuestionnaireApi.getGenders(teamId);
    }

    static async getPositions(teamId: string): Promise<PositionListItem[]> {
        return await QuestionnaireApi.getPositions(teamId);
    }

    static async getBioFields(teamId: string): Promise<FieldGroupViewModel[]> {
        return await QuestionnaireApi.getBioFields(teamId);
    }

    static async getStatsFields(teamId: string): Promise<FieldGroupViewModel[]> {
        return await QuestionnaireApi.getStatsFields(teamId);
    }

    static async getSocialFields(teamId: string): Promise<FieldGroupViewModel[]> {
        return await QuestionnaireApi.getSocialFields(teamId);
    }

    static async getOrgCategories(teamId: string): Promise<InstitutionCategory[]> {
        return await QuestionnaireApi.getOrgCategories(teamId);
    }

    static async searchInstitutions(term: string, categoryId: string, teamId: string, page: number, pageSize: number): Promise<InstitutionSearchItem[]> {
        return QuestionnaireApi.searchInstitutions(term, categoryId, teamId, page, pageSize);
    }

    static async createQuestionnaireRecruit(questionnaireId: string, questionnaireRecruit: QuestionnaireRecruitCreate): Promise<string> {
        return await QuestionnaireApi.createQuestionnaireRecruit(questionnaireId, questionnaireRecruit);
    }

    static async updateQuestionnaireRecruitPartial(questionnaireId: string, data: any, updateType: PartialUpdateTypes) {
        return await this.partialUpdates.get(updateType)!(questionnaireId, data);
    }

    static async getTeamInfo(teamId: string): Promise<TeamInfo> {
        return await QuestionnaireApi.getTeamInfo(teamId);
    }

    static async getUpdateOnlyQuestionaireRecruitIsAvailable(teamMnemonic: string, recruitId: string, formUrl: string) : Promise<boolean> {
        return await QuestionnaireApi.getUpdateOnlyQuestionnaireRecruitIsAvailable(teamMnemonic, recruitId, formUrl);
  }

    static async getUpdateOnlyQuestionaireRecruit(questionnaireId: string, recruitId: string) : Promise<QuestionnaireRecruitInfo> {
          return await QuestionnaireApi.getUpdateOnlyQuestionnaireRecruit(questionnaireId, recruitId);
    }

    static async uploadRecruitAvatar(image: AvatarImage): Promise<UploadInfo> {
        return await MediaApi.upload(image.file, image.name, true);
    }

    private static async setQuestionaireLogo(questionnaire: QuestionnaireView) {
        if (!questionnaire.logoId || questionnaire.logoId.length === 0) {
            questionnaire.logoId = (await this.getTeamInfo(questionnaire.teamId)).avatarId;
        }
    }
    static getJerseyNumbers(): string[] {
        return (['00']).concat(Array.from(Array(100).keys()).map(String));
    }

    static getUploadUrl() {
        return MediaApi.getUploadUrl();
    }

    static getRemoveUrl() {
        return MediaApi.getRemoveUrl();
    }

    static download(mediaId: string): string {
        return MediaApi.download(mediaId);
    }

    static getAvatarThumbnailUrl(mediaId: string): string {
        if (mediaId && mediaId !== 'undefined') {
            return MediaApi.imageThumbnail(mediaId);
        } else {
            return '';
        }
    }

    static partialUpdates =  new  Map<PartialUpdateTypes , (questionnaireId: string, data: any) => Promise<string>>([
        [PartialUpdateTypes.Avatar, QuestionnaireApi.updateAvatar.bind(QuestionnaireApi)],
        [PartialUpdateTypes.SmsStatus, QuestionnaireApi.updateSmsStatus.bind(QuestionnaireApi)],
        [PartialUpdateTypes.Contact, QuestionnaireApi.updateContactField.bind(QuestionnaireApi)],
        [PartialUpdateTypes.Field, QuestionnaireApi.updateField.bind(QuestionnaireApi)],
        [PartialUpdateTypes.Files, QuestionnaireApi.updateFiles.bind(QuestionnaireApi)],
        [PartialUpdateTypes.Footages, QuestionnaireApi.updateFootages.bind(QuestionnaireApi)],
        [PartialUpdateTypes.Institutions, QuestionnaireApi.updateInstitutions.bind(QuestionnaireApi)],
        [PartialUpdateTypes.Positions, QuestionnaireApi.updatePositions.bind(QuestionnaireApi)],
        [PartialUpdateTypes.Social, QuestionnaireApi.updateSocialProfiles.bind(QuestionnaireApi)],
        [PartialUpdateTypes.Submit, QuestionnaireApi.submitQuestionnaire.bind(QuestionnaireApi)],
        [PartialUpdateTypes.SubmitUpdateOnly, QuestionnaireApi.submitUpdateOnlyQuestionnaire.bind(QuestionnaireApi)], 
    ]);

}
