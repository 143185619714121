import * as React from 'react';

export interface Props extends React.Props<Props> {
    display: boolean
}

const Overlay: React.FC<Props> = (props: Props) => {
    const {
        display,
        children
    } = props;

    let style: React.CSSProperties = display ? {} : { display: 'none'};

    return ( 
        <div className="overlay" style={style}>
            {children}
        </div>
    )
}

export default Overlay;