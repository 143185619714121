import React from "react";
import { FieldWrapper } from "../Common/FieldWrapper";
import { RecruitPhoneNumberField } from "../Fields/RecruitFields/RecruitPhoneNumberField";
import { keepBlank } from "../../constants/Constants";
import { ShortStringField } from "../Fields/ShortStringField";
import { BoolField } from "../Fields/BoolField";

export interface ContactFieldProps {
    value: any;
    valid: boolean
    required: boolean;
    name: string;
    fieldName: string;
    contactId: string;
    disabled: boolean;
    onBlur: (id: string, value: any) => void,
    onChange: (id: string, value: any) => void,
    onKeepBlank: (id: string, value: any) => void,
}

export class ContactField extends React.Component<ContactFieldProps> {

    render() {
        const { name, contactId, disabled, fieldName, onKeepBlank } = this.props;
        if (fieldName === "Cell phone")
            return <FieldWrapper> <RecruitPhoneNumberField {...this.props} id={`${contactId};${fieldName}`} formatNumber={false} /> </FieldWrapper>;
        else
        {
            return <FieldWrapper>
                {fieldName === keepBlank
                    ? <BoolField {...this.props} onBlur={onKeepBlank} name="Keep blank" id={`${contactId};${name}`} />
                    : <ShortStringField {...this.props} id={`${contactId};${fieldName}`} disabled={disabled} />}
            </FieldWrapper>;
        }
    }
}
